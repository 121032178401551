import React, { useEffect } from 'react';
import { 
    Edit, 
    TextInput, 
    SimpleForm, 
    Toolbar,
    SaveButton,
    required, 
    useEditController,
    useTranslate,
    useDataProvider,
    NumberInput,
    DateInput,
    useRefresh,
    Loading,
    SelectInput,
    Query,
    Error,
    Link,
    ArrayInput,
    SimpleFormIterator,
    FormDataConsumer
} from 'react-admin';
import { useState  } from 'react';
import Button from '@material-ui/core/Button';
import DocumentPreview from '../Components/DocumentPreview';
import withWidth from '@material-ui/core/withWidth';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FolderIcon from '@material-ui/icons/Folder';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import { makeStyles } from '@material-ui/core/styles';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import MyFocusInput from '../Components/MyFocusInput';


const useStyles = makeStyles({
    nodo : {
        padding: '6px',
        border: '1px solid #319b4252',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '12px',
        width : 'max-content'
    },
    nodoSeccionado :  {
        padding: '6px',
        border: '1px solid black',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '12px',
        backgroundColor: "#319b4257",
        color: '#277233'
    },
    oculto : {
        visibility : "hidden"
    }
});

const EditToolbar = ({ onCancel, ...props }) => {
    
    const translate = useTranslate();

    return (
       <Toolbar {...props}>
           <div>
                <SaveButton label="ra.action.save" {...props} />
                <Button onClick={onCancel} style={{position: 'absolute', right: '26px'}}>{translate('ra.action.cancel')}</Button>
           </div>
       </Toolbar>
)};



function dibujaNodosHijos(nodo,classes,viewNode,translate,nodoActual,colapsar){
    if(nodo.hijos != undefined){
        return (
            <div>
                {nodo.hijos.map(
                (item) => (
                    <div  style={{ marginLeft: '40px', marginTop : '-5px'}}>
                        <div style={{ float: 'left' }}>
                            <SubdirectoryArrowRightIcon style={{ fontSize: '30px' }}/>
                        </div>
                        <div className={ nodoActual == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > <FolderIcon style={{ marginRight: '5px'}} />
                            { item.TextoNodo }
                            <Button className= { nodoActual == item.RutaNodo ? classes.oculto : "" }  title={ translate('traducciones.titulos.reasignar')} onClick={() => viewNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                <LocationSearchingIcon style={{ fontSize: '16px'}}  />
                            </Button>
                            { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                <ExpandLessIcon />
                            </Button>
                            }
                            { item.colapsado && item.hijos && item.hijos.length > 0 &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                <ExpandMoreIcon />
                            </Button>
                            }
                        </div>
                        { !item.colapsado &&
                        <div>
                            { dibujaNodosHijos(item,classes,viewNode,translate,nodoActual,colapsar) }
                        </div>
                        }
                    </div>
                ))
                }
            </div>
        )
    }
}


const DocumentIndexar = ({ width,onCancel, onSuccessOk, ...props }) =>
{

    const { record } = useEditController(props);
    const [dataIndices, setDataIndices] = useState(null);
    const [serviceCall, setServiceCall] = useState(false);
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const history = useHistory(); 
    const [nodoActual, setNodoActual] = useState("");
    const [randomUpdate, setRandomUpdate] = useState(null);
    const [openResult, setOpenResult] = useState(false);
    const classes = useStyles();  
    const [dataDocument, setDataDocument] = useState(null);
    const [fullTx,setFullTx] = useState(); 

    const [loading, setLoading] = useState(false);
    
    const translate = useTranslate();

    var ubicacion = ""
    const apiUrl = window.$parametros_generales.REACT_APP_BASE_URL;


    useEffect(()=>{
            fetch(apiUrl+'Nodes/listNode',{
                method: 'GET',
                headers: new Headers({ 'Content-Type': 'application/json','Authorization': 'Bearer ' + localStorage.getItem('token')}),
            }).then(( response ) => response.json())
            .then((data)=>{
                // if(data.Token =! null){
                //     // localStorage.setItem('token',data.Token)
                // }
                data.Information.map(n=>{
                    if(n.RutaNodo==record.IdNode){
                        setFullTx(n.FullText)
                    }
                })
            })
            .catch(error => {
            }); 
    },[])
    
    if(record != undefined && record.data != undefined){
        try {
            ubicacion = record.data.IdNodePath.replace("/", " / ").slice(0, -1);
        } catch (error) {
            console.log(error);
        }
    }else{
        return <Loading />
    }

    const selectNode = (record) => {
        setDataDocument(record);
        setNodoActual(record.IdNode);
        setOpenResult(true);
    }

    const colapsar = (nodo) => {
        setRandomUpdate(Math.random());
        nodo.colapsado = !nodo.colapsado;
    }

    const handleCloseClick = () => {
        setOpenResult(false);
    }    
    
    const viewNode = (nodoPadre) => {
        setLoading(true);
        dataProvider.update('documentoTemporal', { id: dataDocument ? dataDocument.data.IdDocumentTmp : 0, IdNode: nodoPadre.RutaNodo })
        .then(() => {
            setOpenResult(false);
            dataProvider.getList('indices', { IdNode: nodoPadre.RutaNodo ? nodoPadre.RutaNodo : "/", Prediction: true, IdDocumentTmp: dataDocument ? dataDocument.data.IdDocumentTmp : 0 })
                .then(({ data }) => {
                    setDataIndices(data); 
                    setLoading(false);                   
                    refresh();
                })
                .catch(error => {
                    setLoading(false);
            });  
        })
        .catch(error => {
            setLoading(false);
        });   
    }


    if(record && record.data && dataIndices ==  null && !serviceCall){        
        setServiceCall(true);
        dataProvider.getList('indices', { IdNode: record.data ? record.data.IdNode : "/", Prediction: true, IdDocumentTmp: record.id })
                .then(({ data }) => {
                    setDataIndices(data);
                })
                .catch(error => {
            });  
    }
    const back = () => {
        history.push({
            pathname: '/documentos',
            state: {},
        });
        refresh();
    } 

    const ChangeNodeButton = ({ record, ...props }) => (
        <Button
            style={{ backgroundColor: "#30b165", color : "white", padding: "3px 4px"}}
            component={Link}
            title = { props.title }
            onClick={ () => { selectNode(record) } }>
                <CallSplitIcon /> 
        </Button>
    );

    if(loading){
        return <Loading />;
    }

    
    return (
        <div>
        <Edit {...props} undoable={false} onSuccess={ onSuccessOk }>
            <SimpleForm  toolbar={<EditToolbar onCancel={back} />}>
                <div fullWidth>
                    <div style={{ textAlign: 'center', width: '100%'}}>
                        <div className="titleClass">{translate('traducciones.titulos.indexarDocumento')} | Descripción / RFC: {record.data.Description}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            { record ? (
                            <DocumentPreview document = { record.data } />
                            ) : null 
                            }
                            {record.ft=fullTx}
                        </div>
                        <div className="col-md-6">
                            <div style={{
                                textAlign: 'center',  padding: '5px',
                                backgroundColor: '#849e88',
                                color: 'white',
                                fontSize: '16px'
                            }
                            } >

                            <ChangeNodeButton record={ record } title= { translate("traducciones.titulos.reasignarDocumento") } />    

                            <b>{translate('traducciones.titulos.ubicacion')} : </b> {  ubicacion } </div>
                            { dataIndices ? (
                                <div className="row">
                                    {   dataIndices.map(
                                        (item) => {
                                            return (
                                            <div className="col-md-6">
                                                { item.Multiple ? (
                                                    <div>
                                                         <ArrayInput className="valor_iterator" label={ "Campos " + item.NombreIndice }  source={"multiple-" + item.IdIndice} style={{ width: "100%" }} defaultValue={['']}>
                                                            <SimpleFormIterator>
                                                            { item.Tipo == 1 ? (<TextInput label={ "Campo " + item.NombreIndice }  source={"valor-" + item.IdIndice} /> ) : null }
                                                            { item.Tipo == 7 && item.ValoresTabla ? (
                                                                <SelectInput label={ "Campo " + item.NombreIndice } resettable={ true } validate={ item.Requerido ? [required()] : null} source={"valor-" + item.IdIndice}  choices={ item.ValoresTabla.map(value => ({id: value, name: value})) } defaultValue='' />
                                                            ) : null }                                                            
                                                            { item.Tipo == 2 ? (<NumberInput  resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={"valor-" + item.IdIndice} />) : null }
                                                            { item.Tipo == 3 ? (<TextInput resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={"valor-" + item.IdIndice} />) : null }
                                                            { item.Tipo == 4 ? (<DateInput resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={"valor-" + item.IdIndice}  />) : null }
                                                            { item.Tipo == 5 ? (<TextInput type="time" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={"valor-" + item.IdIndice}  />) : null }
                                                            { item.Tipo == 6 ? (<TextInput type="email" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={"valor-" + item.IdIndice}  />) : null }
                                                            </SimpleFormIterator>
                                                         </ArrayInput>   
                                                    </div>        
                                                ) : item.Enmascarar ? 
                                                (
                                                    <MyFocusInput item={item}/>
                                                ):(
                                                 <div>       

                                                { item.Tipo == 1 ? (<TextInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } type="text" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice  }  />) : null }

                                                { item.Tipo == 7 && item.ValoresTabla ? (
                                                    <div>
                                                        <SelectInput style={{ width: "100%" }} name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  choices={ item.ValoresTabla.map(value => ({id: value, name: value})) } defaultValue=''/>
                                                    </div>
                                                ) : null }

                                                { item.Tipo == 2 ? (<NumberInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  />) : null }
                                                { item.Tipo == 3 ? (<TextInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } type="text" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  />) : null }
                                                { item.Tipo == 4 ? (<DateInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } name={ "campo-" + item.IdIndice + "]" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  />) : null }
                                                { item.Tipo == 5 ? (<TextInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } type="time" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  />) : null }
                                                { item.Tipo == 6 ? (<TextInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } type="email" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  />) : null }
                                                </div>
                                                )}
                                            </div>
                                        )})
                                    }    
                                </div>
                                ) : null 
                            }
                        </div>
                    </div>
                    
                </div>
            </SimpleForm>
        </Edit>
        <Dialog open={openResult}>
            <DialogTitle>{ translate('traducciones.titulos.reasignarDocumento')}</DialogTitle>
            <DialogContent>
                <div style={{ padding: '10px', minWidth: "400px"}}>       
                    <Query type="getList" resource="nodos">
                        {({ data, loading, error }) => {
                            if (loading) { return <Loading />; }
                            if (error) { return <Error />; }
                            return (
                                <div  className="row">
                                    <div  className="col-md-12">
                                    {   data.map(
                                        (item) => (
                                            <div>
                                                <div className= { nodoActual == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > 
                                                    <FolderIcon style={{ marginRight: '5px', color : '#5c9d66'}} /> 
                                                    { item.TextoNodo } 
                                                    <Button className= { nodoActual == item.RutaNodo ? classes.oculto : "" } title={ translate('traducciones.titulos.reasignar')}  onClick={() => viewNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                                        <LocationSearchingIcon style={{ fontSize: '16px'}}/>
                                                    </Button>
                                                    { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                                                    <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                                        <ExpandLessIcon />
                                                    </Button>
                                                    }
                                                    { item.colapsado && item.hijos && item.hijos.length > 0 &&
                                                    <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                                        <ExpandMoreIcon />
                                                    </Button>
                                                    }
                                                </div>
                                                { !item.colapsado &&
                                                <div style={{ marginLeft: '-20px'}}>
                                                    { dibujaNodosHijos(item,classes,viewNode,translate,nodoActual,colapsar) }
                                                </div>
                                                }
                                            </div>
                                        ))
                                    }
                                    </div>                                        
                                </div>
                            );
                        }}
                    </Query>
                </div> 
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseClick} >
                    { translate('ra.action.close')}
                </Button>
            </DialogActions>
        </Dialog> 
        </div>
    )
};

export default withWidth()(DocumentIndexar);