import React from 'react'
import { useState } from 'react';
import { SelectInput, TextInput, useTranslate } from 'react-admin'
import EditNoteIcon from '@mui/icons-material/EditNote';
import { Button } from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

function InputsPredefinidas({campo, indiceCampo, handleSaveComunes, choicesComun, setOpenComunes, handleDeleteField}) {
    
  const [tipoCampo, setTipoCampo] = useState('')
  const translate = useTranslate()

  const handleChangueType = (e) =>{
      setTipoCampo(e)
  }

  const choicesOperator = [
    {idOperador:'=',operador:'Igual a'},
    {idOperador:'>',operador:'Mayor a'},
    {idOperador:'>=',operador:'Mayor/Igual a'},
    {idOperador:'<',operador:'Menor a'},
    {idOperador:'<=',operador:'Menor/Igual a'},
  ]

  const choicesOperatorToString = [
    {idOperador:'=',operador:'Igual a'},
    {idOperador:'%LIKE%',operador:'Comparar'},
  ]

  const choicesTypeField = [
      {idOperador:'VARIABLE_VALUE',operador:'Libre ingreso'},
      {idOperador:'CONSTANT_VALUE',operador:'Con valor fijo'},
      {idOperador:'COMMON_VARIABLE_VALUE',operador:'Campo común'},
      {idOperador:'SYSTEM_DATE_VALUE',operador:'Fecha de creación'}
  ]

  return (
    <div className='row' key={indiceCampo}>
      <TextInput
          style={{ marginRight: '15px', maxWidth: '15%', float: 'left', display: 'flex' }}
          source={`fields[${indiceCampo}].NombreIndice`}
          id={`NombreIndice_${indiceCampo}`}
          label={'Nombre de campo:'}
          disabled
          defaultValue={campo.NombreIndice}
      />
      <SelectInput
          style={{ marginRight: '15px', maxWidth: '15%', float: 'left', display: 'flex' }}
          source={`fields[${indiceCampo}].TipoCampo`}
          id={`TipoCampo_${indiceCampo}`}
          choices={ campo.IdIndice == 0 ? choicesTypeField.filter((choice)=>choice.idOperador == 'SYSTEM_DATE_VALUE') : choicesTypeField.filter((choice)=>choice.idOperador != 'SYSTEM_DATE_VALUE')}
          optionText="operador"
          optionValue="idOperador"
          label='Tipo de campo'
          defaultValue={campo.TipoCampo ? campo.TipoCampo : (campo.IdIndice == 0 ? 'SYSTEM_DATE_VALUE' : 'VARIABLE_VALUE')}
          onChange={(e)=>handleChangueType(e.target.value)}
          disabled={campo.IdIndice == 0}
      />
      <SelectInput
          style={{ marginRight: '15px', maxWidth: '15%', float: 'left', display: 'flex' }}
          source={`fields[${indiceCampo}].Comparacion`}
          id={`Comparacion_${indiceCampo}`}
          choices={ campo.Tipo == 0 || campo.Tipo == 2 || campo.Tipo == 4 ? choicesOperator : choicesOperatorToString }
          optionText="operador"
          optionValue="idOperador"
          label='Comparador'
          defaultValue={ campo.Comparacion ? campo.Comparacion : '=' }
      />
      {( tipoCampo == 'CONSTANT_VALUE' || campo.TipoCampo == 'CONSTANT_VALUE') && (
        <TextInput
            source={`fields[${indiceCampo}].ValorFijo`}
            style={{ maxWidth: '15%', float: 'left', display: 'flex' }}
            label={'Asignar valor fijo'}
            defaultValue={ campo.ValorFijo ? campo.ValorFijo : '' }
        />
      )}
      {( tipoCampo == 'COMMON_VARIABLE_VALUE' || campo.TipoCampo == 'COMMON_VARIABLE_VALUE' ) && (
        <>
        <SelectInput
            style={{ marginRight: '15px', maxWidth: '15%', float: 'left', display: 'flex' }}
            source={`fields[${indiceCampo}].CampoComun`}
            id={`CampoComun_${indiceCampo}`}
            choices={choicesComun}
            optionText="operador"
            optionValue="idOperador"
            label='Campo común'
            defaultValue={ campo.CampoComun ? campo.CampoComun : '' }
        />
        <Button style={{display:'flex', width:'min-content', height:'min-content', marginTop:'15px'}} startIcon={<EditNoteIcon/>} onClick={(e)=>setOpenComunes(true)}>Comunes</Button>
        </>
      )} 
        {/* <Button style={{display:'flex', width:'min-content', height:'min-content', marginTop:'15px', marginLeft:'10px'}} onClick={(e)=>handleDeleteField(campo)}><RemoveCircleIcon/></Button> */}
  </div>
  )
}

export default InputsPredefinidas