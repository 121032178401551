import React from 'react'
import '../Libs/ExternalViewer/Resources/Styles/Loader2.css'

const Loader2 = () => {
  return (
    <>
    <div className="loader"></div>
    </>
  )
}

export default Loader2