import React from 'react'
import { useState } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@material-ui/core/Button';
import swal from 'sweetalert';
import { useTranslate,useDataProvider, useRefresh, ArrayInput, FormWithRedirect, SaveButton, SimpleFormIterator, TextInput } from 'react-admin';
import StepIcon from '@mui/material/StepIcon';
import SeleccionCampos from './SeleccionCampos';
import ConfigurationCampos from './ConfigurationCampos';
import DetallePredefinida from './DetallePredefinida';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

export const ComponentPredefinidas = ({dataUsers, dataGrupos, setOpenNombre}) => {
    const steps = ['Selección de campos', 'Configuración de campos' ,'Detalles de la consulta'];
    const defaultTheme = createTheme();
    const [nodoPadreElemento, setnodoPadreElemento] = useState(null); // Almacena el nodo actual seleccionado  
    const [campos,setCampos] = useState([]) // Almacena los campos seleccionados dentro de un nodo
    const [dataIndices, setDataIndices] = useState([]); // Almacena los campos del nodo al hacer petición
    const [dataNodos, setDataNodos] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const translate = useTranslate();
    const [nameQuery, setNameQuery] = useState('');
    const [AssignedUsers, setAssignedUser] = useState([]);
    const [AssignedGroups, setAssignedGroups] = useState([]);
    const [comparadorNodos, setComparadorNodos] = useState(false);
    const [openFields, setOpenFields] = useState(false);
    const dataProvider=useDataProvider();
    const refresh = useRefresh();
    const [choicesComun, setChoicesComun] = useState([
        {idOperador:'=',operador:''}
    ])
    const [openComunes, setOpenComunes] = useState(false)

    const handleSaveComunes = async(values) => {
        let newValues = []; 
        values.Choices.map((opcion)=>{
          const newChoice = {
            idOperador: opcion.operador,
            operador: opcion.operador
          }
          newValues.push(newChoice)
        })
        setChoicesComun(newValues)
        swal({
            icon:'success',
            text:'Cambios guardados',
            buttons: false,
            timer:700
        })
        setOpenComunes(false)
      }

    const handleNext = async(values) => {
        if(activeStep == 0 ){
            if(dataNodos.length == 0){
                swal({
                    icon: 'info',
                    text: 'Aun no tiene campos seleccionados, debe agregar al menos uno para continuar'
                })
            }else{
                setActiveStep(activeStep + 1);
            }
        }else if(activeStep == 1){
            setActiveStep(activeStep + 1);
        }else if(activeStep == 2){
            if(nameQuery.trim() == ''){
                swal({
                    icon: 'info',
                    text: 'Debe asignar un nombre a la consulta'
                })
            }else if(AssignedUsers.length == 0 && AssignedGroups.length == 0){
                swal({
                    icon: 'info',
                    text: 'Debe asignar la consulta al menos a un usuario o grupo'
                })
            }else{
                handleSubmit()
            }
        }else{
            setActiveStep(activeStep + 1);
        }  
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <SeleccionCampos addCampos={addCampos} setCampos={setCampos} setnodoPadreElemento={setnodoPadreElemento} nodoPadreElemento={nodoPadreElemento} setDataIndices={setDataIndices} dataIndices={dataIndices} handleChangueCampos={handleChangueCampos} fechaCreacion={fechaCreacion} openFields={openFields} setOpenFields={setOpenFields} dataNodos={dataNodos}/>
            case 1:
                return <ConfigurationCampos dataNodos={dataNodos} handleConfigFields={handleConfigFields} handleChangueComparadorNodos={handleChangueComparadorNodos} choicesComun={choicesComun} handleSaveComunes={handleSaveComunes} setOpenComunes={setOpenComunes} handleDeleteField={handleDeleteField}/>
            case 2:
                return <DetallePredefinida dataNodos={dataNodos} dataUsers={dataUsers} dataGrupos={dataGrupos} handleChangueNameQuery={handleChangueNameQuery} handleChangueUsers={handleChangueUsers} handleChangueGroups={handleChangueGroups} nameQuery={nameQuery} AssignedUsers={AssignedUsers} AssignedGroups={AssignedGroups}/>
            default:
            throw new Error('Unknown step');
        }
    }

    //Steps personalizados
    const StepIconCustom = ({ isActive, ...props }) => {
        return (
          <StepIcon {...props} style={{ color: isActive ? '#30b165' : 'inherit' }} />
        );
    };

    const addCampos = () =>{
        if(campos.length<=0){
            swal({
                icon:'info',
                text:'No hay campos seleccionados'
            })
        }else{
            swal({
                text:'Se agregaran '+campos.length+' campos, esta seguro?',
                buttons:{
                    cancel: {
                    text: translate('traducciones.campos.cancelar'),
                    value: false,
                    visible: true,
                    className: "bg-danger text-white",
                    closeModal: true,
                    },
                    confirm: {
                    text: 'Confirmar',
                    value: true,
                    visible: true,
                    className: "bg-success text-white",
                    closeModal: true
                    }
                }
            }).then((res)=>{
                if(res){
                    let fields = [];
                    let nodoAgregado;
                    
                    campos.map((c)=>{
                        let existe = false
                        nodoAgregado = dataNodos.find(nodo => nodo.RutaNodo == c.IdNodo)

                        if(nodoAgregado){
                            existe = nodoAgregado.fields.some(campo => campo.IdIndice == c.IdIndice)
                            if(!existe){
                                nodoAgregado.fields.push(c)
                                swal({
                                    text:'Se han agregado los campos',
                                    icon:'success'
                                })
                            }  
                        }else{
                            fields.push(c)
                        }
                    })
                    const camposToAdd = {
                        RutaNodo: nodoPadreElemento.RutaNodo,
                        TextoNodo: nodoPadreElemento.TextoNodo,
                        fields: fields
                    }
                    if(!nodoAgregado){
                        setDataNodos((prevState)=>[...prevState,camposToAdd])
                        swal({
                            text:'Se han agregado los campos',
                            icon:'success'
                        })
                    }
                setCampos([])
                setDataIndices([])
                setnodoPadreElemento(null)
                setOpenFields(false)
                }   
            })
        }
    }
    
    const fechaCreacion={
        IdIndice: 0,
        IdNodo: nodoPadreElemento ? nodoPadreElemento.RutaNodo : '/0/',
        NombreIndice: 'Fecha de creación',
        Tipo: 4,
        Formato: null,
        Mascara: null,
        Minimo: 0,
        Maximo: 0,
        IdTabla: 0,
        Longitud: 0,
        Requerido: false,
        Multiple: false,
        Unico: false,
        Verificar: false,
        Repetido: false,
        IdDiccionario: 0,
        Biometrico: 0,
        IdModel: 0,
        ValorLlave: null,
        ValoresTabla: null,
        Notificar: false,
        fechaCreacion:true,
        TipoCampo: 'SYSTEM_DATE_VALUE'
    }

    const handleChangueCampos = (e,campo) =>{
        if (e.target.checked) {
            if(campos.length>0){
                const existe = campos.some(item => item.IdIndice === campo.IdIndice)
                if (!existe) {
                setCampos((prevState) => [...prevState, campo]);
                }
            }else{
                setCampos((prevState) => [...prevState, campo]);
            }
        } else {
            const filtrada = campos.filter((n) => n.IdIndice !== campo.IdIndice);
            setCampos(filtrada);
        }
    }

    // Función que se encarga de guardar los cambios hechos a los campos de un nodo.
    const handleConfigFields = async (values,nodo) => {
        let nodoModificado = nodo;
        
        if(values.fields.length == 0){
            swal({
                text:'¿Seguro que desea quitar todos los campos agregados del nodo ' + nodoModificado.TextoNodo + '?',
                buttons:{
                    cancel: {
                      text: translate('traducciones.campos.cancelar'),
                      value: false,
                      visible: true,
                      className: "bg-danger text-white",
                      closeModal: true,
                    },
                    confirm: {
                      text: 'Aceptar',
                      value: true,
                      visible: true,
                      className: "bg-success text-white",
                      closeModal: true
                    }
                  }
            }).then(res=>{
                if(res){
                    const newDataNodos = dataNodos.filter((nodo)=> nodo.RutaNodo != nodoModificado.RutaNodo)
                    setDataNodos(newDataNodos)
                    swal({
                        icon:'success',
                        text:'Cambios guardados',
                        buttons: false,
                        timer:700
                    })
                }
            }) 
        }else{
            const nuevoObjeto = { ...nodo }; // Crear una copia del objeto principal para no modificarlo directamente
            // Recorrer los campos del objeto principal
            let newFields= []
            nuevoObjeto.fields.forEach(campo => {
                // Buscar el campo correspondiente en los datos adicionales
                const datos = values.fields.find(dato => dato.NombreIndice === campo.NombreIndice);
                // Si se encontraron datos adicionales para el campo, agregarlos al campo
                if (datos) {
                    const newCampo = { ...campo, ...datos }; // Fusionar los datos adicionales con el campo existente
                    newFields.push(newCampo)
                }
            });

            nodoModificado.fields = newFields;
            nodoModificado.ComparadorCampos = values.ComparadorCampos;
            swal({
                icon:'success',
                text:'Cambios guardados',
                buttons: false,
                timer:700
            })
        }
    }

    const handleChangueNameQuery = async(values) => {
        setNameQuery(values)
    }

    const handleChangueUsers = async(values) => {
        setAssignedUser(values)
    }

    const handleChangueGroups = async(values) => {
        setAssignedGroups(values)
    }

    const handleChangueComparadorNodos = async(values) => {
        setComparadorNodos(values)
    }

    const handleDeleteField = (campo) => {
        // Copiar el estado actual
        const newDataNodos = [...dataNodos];
        
        // Encontrar el nodo que se quiere modificar
        const nodoToEditIndex = newDataNodos.findIndex(nodo => nodo.RutaNodo == campo.IdNodo);
    
        if (nodoToEditIndex !== -1) {
            // Copiar el nodo para no mutar el estado directamente
            const nodoToEdit = { ...newDataNodos[nodoToEditIndex] };
            
            // Filtrar los campos para eliminar el campo deseado
            const camposFiltrados = nodoToEdit.fields.filter(field => field.IdIndice != campo.IdIndice);
            // console.log(camposFiltrados);
    
            if(camposFiltrados.length == 0){
                swal({
                    text: '¿Esta seguro que desea remover el campo '+ campo.NombreIndice + '?',
                    buttons:{
                        cancel: {
                          text: translate('traducciones.campos.cancelar'),
                          value: false,
                          visible: true,
                          className: "bg-danger text-white",
                          closeModal: true,
                        },
                        confirm: {
                          text: 'Confirmar',
                          value: true,
                          visible: true,
                          className: "bg-success text-white",
                          closeModal: true
                        }
                      }
                }).then((res)=>{
                    if(res){
                        const newData = dataNodos.filter((nodo) => nodo.RutaNodo != campo.IdNodo)
                        setDataNodos(newData)
                    }
                })
            }else{
                swal({
                    text: '¿Esta seguro que desea remover el campo '+ campo.NombreIndice + '?',
                    buttons:{
                        cancel: {
                          text: translate('traducciones.campos.cancelar'),
                          value: false,
                          visible: true,
                          className: "bg-danger text-white",
                          closeModal: true,
                        },
                        confirm: {
                          text: 'Confirmar',
                          value: true,
                          visible: true,
                          className: "bg-success text-white",
                          closeModal: true
                        }
                      }
                }).then((res)=>{
                    if(res){
                        // Asignar los campos filtrados al nodo a modificar
                        nodoToEdit.fields = camposFiltrados;
                
                        // Actualizar el array de nodos en la copia del estado
                        newDataNodos[nodoToEditIndex] = nodoToEdit;
                        // console.log(newDataNodos);
                
                        // Establecer el nuevo estado
                        setDataNodos(newDataNodos);
                    }
                })
            }
        }
    };

    const handleSubmit = () =>{
        let fields = [];
        let cadena = '';
        let comparativeNode = comparadorNodos ? 'AND' : 'OR'
        
        dataNodos.map((nodo,indice)=>{
            let comparativeField = nodo.ComparadorCampos ? 'AND' : 'OR'
            cadena += '['
            nodo.fields.map((d,index)=>{
                switch(d.TipoCampo){
                    case 'SYSTEM_DATE_VALUE':
                        cadena += '(' + '0' + (d.Comparacion ? d.Comparacion : '=') + d.TipoCampo + ':' + d.IdNodo + ')' + (nodo.fields.length-1 == index ? ']' + (dataNodos.length-1 == indice ? '' : comparativeNode) : comparativeField)
                    break;
                    case 'VARIABLE_VALUE':
                        cadena += '(' + d.IdIndice + d.Comparacion + d.TipoCampo + ')' + (nodo.fields.length-1 == index ? ']' + (dataNodos.length-1 == indice ? '' : comparativeNode) : comparativeField)
                    break;
                    case 'CONSTANT_VALUE':
                        cadena += '(' + d.IdIndice + d.Comparacion + d.TipoCampo + ':' + d.ValorFijo + ')' + (nodo.fields.length-1 == index ? ']' + (dataNodos.length-1 == indice ? '' : comparativeNode) : comparativeField)
                    break;
                    case 'COMMON_VARIABLE_VALUE':
                        cadena += '(' + d.IdIndice + d.Comparacion + d.TipoCampo + ':' + d.CampoComun + ')'+ (nodo.fields.length-1 == index ? ']' + (dataNodos.length-1 == indice ? '' : comparativeNode) : comparativeField)
                    break;

                    default: 
                        cadena += '(' + d.IdIndice + '=' + 'VARIABLE_VALUE' + ')' + (nodo.fields.length-1 == index ? ']' + (dataNodos.length-1 == indice ? '' : comparativeNode) : comparativeField)
                }
                fields.push(d)
            })
        })
        // cadena = cadena.slice(0,-1)

        let dataQuery={
            IdQuery: 1,
            NombreBusqueda: nameQuery.trim(),
            CadenaLogica: cadena,
            // Conector: values.CheckInterseccion,
            // CampoComun: values.CheckComun,
            // NombreCampoComun: values.CheckComun ? (values.NombreComun ? values.NombreComun : 'Campo común' ) : '' ,
            FieldList: fields,
            IdUsuarios: AssignedUsers,
            IdGrupos: AssignedGroups
        }
        // console.log(dataQuery)
        dataProvider.create('predefinida',{ data:dataQuery })
        .then(({ data }) => {
            setOpenNombre(false)
            refresh();
            swal({
                text:'Nueva consulta creada',
                icon: 'success'
            })
        }).catch((error)=>{
            swal({
                text: error.message,
                icon: 'error'
            })
        })
    }

    return (
        <>
             <ThemeProvider theme={defaultTheme}>
                <Container  maxWidth="xl">
                    <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    {/* <Typography component="h1" variant="h4" align="center">
                        Checkout
                    </Typography> */}
                    <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel
                                StepIconComponent={(props) => (
                                <StepIconCustom {...props} isActive={index === activeStep || activeStep > index} />
                                )}
                            >
                                    {label}
                            </StepLabel>
                        </Step>
                        ))}
                    </Stepper>
                        <React.Fragment>
                        {getStepContent(activeStep)}
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {activeStep != 0 && (
                            <Button onClick={handleBack} style={{marginTop : '10px'}}>
                                Back
                            </Button>
                            )}
                            {
                            activeStep === 0 || activeStep === 1 ?           
                                <Button
                                variant="contained"
                                onClick={handleNext}
                                disabled={false}
                                style={{marginTop : '10px'}}
                                >
                                {'Next'}
                                </Button> :
                                <Button
                                variant="contained"
                                onClick={handleNext}
                                disabled={false}
                                style={{marginTop : '10px'}}
                                >Crear</Button>
                            }
                        </Box>
                        </React.Fragment>
                    </Paper>
                </Container>
            </ThemeProvider>
            <Dialog id='dialogValorFijo' maxWidth='md' open={openComunes}>
                <DialogTitle>Administrar campos comunes</DialogTitle>
                <FormWithRedirect 
                resource="catalogos"
                save={handleSaveComunes}
                render={({
                    handleSubmitWithRedirect,
                    pristine,
                    saving
                }) => (
                    <>
                    <DialogContent>
                        <ArrayInput source='Choices' label='' defaultValue={choicesComun}>
                            <SimpleFormIterator disableReordering > 
                                    <TextInput source='operador' label={translate('traducciones.campos.valor')} fullWidth={true}/> 
                            </SimpleFormIterator>
                        </ArrayInput>               
                    </DialogContent>
                    <DialogActions>
                        <SaveButton
                            id='btnSaveCampo'
                            label="ra.action.save"
                            handleSubmitWithRedirect={
                                handleSubmitWithRedirect
                            }
                            pristine={pristine}
                            saving={saving}
                        />
                        <Button onClick={()=>{
                            setOpenComunes(false)
                            }} >
                            { translate('ra.action.close')}
                        </Button>
                    </DialogActions>
                    </>
                )}
            />
        </Dialog>
        </>
    )
}