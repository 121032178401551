import React, { useState } from 'react';
import { ArrayInput, DateInput, NumberInput, SelectInput, SimpleFormIterator, TextInput, required } from 'react-admin';

const MyFocusInput = ({ item }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [inputValue, setInputValue] = useState(item.ValorLlave || '');

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleChange = (e) => {
        setInputValue(e.target.value);
    };

    // return (
    //     <>
    //     {/* <span>{`Campo ${item.NombreIndice}`}</span> */}
    //     <input 
    //         className='form-control'
    //         style={{ width: '100%' }}
    //         value={isFocused ? inputValue : '****'}
    //         name={`campo-${item.IdIndice}`}
    //         label={`Campo ${item.NombreIndice}`}
    //         source={item.IdIndice}
    //         onFocus={handleFocus}
    //         onBlur={handleBlur}
    //         onChange={handleChange}
    //     />
    //     </>
    // );
    
    return (
        <>
        { item.Multiple ? (
            <div style={{marginTop:'-10px'}}>
                 <ArrayInput className="valor_iterator" label={ "Campos " + item.NombreIndice }  source={"multiple-" + item.IdIndice} style={{ width: "100%" }}>
                    <SimpleFormIterator>
                    { item.Tipo == 1 ? (<TextInput label={ "Campo " + item.NombreIndice }  source={"valor-" + item.IdIndice} defaultValue=''/> ) : null }
                    { item.Tipo == 7 && item.ValoresTabla ? (
                        <SelectInput label={ "Campo " + item.NombreIndice } resettable={ true } validate={ item.Requerido ? [required()] : null} source={"valor-" + item.IdIndice}  choices={ item.ValoresTabla.map(value => ({id: value, name: value})) } defaultValue='' />
                    ) : null }                                                            
                    { item.Tipo == 2 ? (<NumberInput  resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={"valor-" + item.IdIndice} defaultValue=''/>) : null }
                    { item.Tipo == 3 ? (<TextInput resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={"valor-" + item.IdIndice} defaultValue=''/>) : null }
                    { item.Tipo == 4 ? (<DateInput resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={"valor-" + item.IdIndice}  defaultValue=''/>) : null }
                    { item.Tipo == 5 ? (<TextInput type="time" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={"valor-" + item.IdIndice}  defaultValue=''/>) : null }
                    { item.Tipo == 6 ? (<TextInput type="email" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={"valor-" + item.IdIndice}  defaultValue=''/>) : null }
                    </SimpleFormIterator>
                 </ArrayInput>   
            </div>       
        ) : (
         <div>       

        {/* { item.Tipo == 1 ? (<TextInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } type="text" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice  }  />) : null } */}
        { item.Tipo == 1 ? (
        <>
            <span style={{fontWeight:'500', marginLeft:'5px'}}>{`Campo ${item.NombreIndice}`}</span>
            <TextInput style={ isFocused ? { width: '100%'} : { width: '100%', color: 'white', filter: 'blur(3px)'}} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } type="text" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice  } 
            onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange}  />
        </>
        ) : null }
        {/* { item.Tipo == 1 ? (<MyFocusInput item={item}/>) : null } */}

        { item.Tipo == 7 && item.ValoresTabla ? (
            <div>
                <SelectInput style={ isFocused ? { width: '100%'} : { width: '100%', color: 'white', filter: 'blur(3px)'}} name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  choices={ item.ValoresTabla.map(value => ({id: value, name: value})) } defaultValue=''
                onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange} />
            </div>
        ) : null }

        { item.Tipo == 2 ? (<NumberInput style={ isFocused ? { width: '100%'} : { width: '100%', color: 'white', filter: 'blur(3px)'}} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  
        onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange}/>) : null }
        { item.Tipo == 3 ? (<TextInput style={ isFocused ? { width: '100%'} : { width: '100%', color: 'white', filter: 'blur(3px)'}} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } type="text" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  
        onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange}/>) : null }
        { item.Tipo == 4 ? (<DateInput style={ isFocused ? { width: '100%'} : { width: '100%', color: 'white', filter: 'blur(3px)'}} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } name={ "campo-" + item.IdIndice + "" }  resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  
        onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange}/>) : null }
        { item.Tipo == 5 ? (<TextInput style={ isFocused ? { width: '100%'} : { width: '100%', color: 'white', filter: 'blur(3px)'}} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } type="time" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  
        onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange}/>) : null }
        { item.Tipo == 6 ? (<TextInput style={ isFocused ? { width: '100%'} : { width: '100%', color: 'white', filter: 'blur(3px)'}} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } type="email" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  
        onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange}/>) : null }
        </div>
        )}
    </>
)
};

export default MyFocusInput;
