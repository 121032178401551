import React from 'react'
import { Bar, Pie } from 'react-chartjs-2';

function TableroStorage({dataClients, storageGral}) {

    if(dataClients && storageGral){

        let storageDetails = [];
    
        const data = {
            labels: ['Espacio Utilizado ' + storageGral.totalUsedPercentage + '% de ' + storageGral.totalCustomersStorage + ' GB', 'Espacio Disponible ' + ((storageGral.totalCustomersStorage * (100 - storageGral.totalUsedPercentage)) / 100) + " GB"],
            datasets: [
              {
                label: 'Espacio en GB: ',
                data: [storageGral.totalUsedPercentage, 100 - storageGral.totalUsedPercentage],
                stacked: '0',
                backgroundColor: [
                    '#3a9b4a',
                    'rgb(223 223 223)',
                ],
                borderColor: [
                    '#3a9b4a',
                    'rgb(223 223 223)',
                ],
                borderWidth: 1,
              },
            ],
        };
    
        const detailsGral = {
            clientName: 'Resumen general',
            labels : [''],
            datasets: [
                {
                    label: 'Espacio Utilizado ' + storageGral.totalUsedPercentage + '% de ' + storageGral.totalCustomersStorage + ' GB',
                    data: [storageGral.totalUsedPercentage],
                    backgroundColor: '#3a9b4a',
                    stacked : "0"
                    
                },
                {
                    label: 'Espacio Disponible ' + ((storageGral.totalCustomersStorage * (100 - storageGral.totalUsedPercentage)) / 100) + " GB",
                    data: [100 - storageGral.totalUsedPercentage],
                    backgroundColor: 'rgb(223 223 223)',
                    stacked : "0"
                    
                }
            ],
        }
    
        dataClients.map((client)=>{
            // storageDetails.push({
            //     clientName: client.CustomerName,
            //     labels : [''],
            //     datasets: [
            //         {
            //             label: 'Espacio Utilizado ' + client.ProgressPercentage + '% de ' + client.Maximum + ' GB',
            //             data: [client.ProgressPercentage],
            //             backgroundColor: '#3a9b4a',
            //             stacked : "0"
                        
            //         },
            //         {
            //             label: 'Espacio Disponible ' + ((client.Maximum * (100 - client.ProgressPercentage)) / 100) + " GB",
            //             data: [100 - client.ProgressPercentage],
            //             backgroundColor: 'rgb(223 223 223)',
            //             stacked : "0"
                        
            //         }
            //     ],
            // })
            storageDetails.push({
                clientName: client.CustomerName,
                labels: ['Espacio Utilizado ' + client.ProgressPercentage + '% de ' + client.Maximum + ' GB', 'Espacio Disponible ' + ((client.Maximum * (100 - client.ProgressPercentage)) / 100) + " GB"],
                datasets: [
                    {
                    label: 'Espacio en GB: ',
                    data: [client.ProgressPercentage, 100 - client.ProgressPercentage],
                    stacked: '0',
                    backgroundColor: [
                        '#3a9b4a',
                        'rgb(223 223 223)',
                    ],
                    borderColor: [
                        '#3a9b4a',
                        'rgb(223 223 223)',
                    ],
                    borderWidth: 1,
                    },
                ],
            })
        })
    
      return (
        <div className='row' style={{marginTop:'1rem'}}>
            <div className='row'>
                <div className='col-md-3' style={{marginLeft:'1rem'}}>
                    <h4>Resumen General:</h4>
                    <span>Total de clientes: {storageGral.customersAiidara}</span>
                </div>
                <div className='col-md-1'></div>
                <div className='col-md-4'>
                    {/* <Bar data={detailsGral} options= { { 
                                indexAxis: 'y', 
                                interaction: {
                                    mode: 'index',
                                    intersect: false,
                                },
                                scales: {
                                    x: {
                                        stacked: true,
                                    },
                                    y: {
                                        stacked: true,
                                    },
                                },
                            }} /> */}
                            <div style={{padding:'20px'}}>
                                <Pie data={data}/>        
                            </div>
                </div>
            </div>
            <hr className='hr-text' data-content={'Clientes'}/>
            {storageDetails.map((cliente)=>{
                return(
                    <div className='col-md-2' style={{marginBottom:'20px'}}>
                        <center><span style={{fontWeight:'bold'}}>{cliente.clientName}</span></center>
                        {/* <Bar data={cliente} options= { { 
                            indexAxis: 'y', 
                            interaction: {
                                mode: 'index',
                                intersect: false,
                            },
                            scales: {
                                x: {
                                    stacked: true,
                                },
                                y: {
                                    stacked: true,
                                },
                            },
                        }} /> */}
                        <div style={{padding:'10px'}}>
                            <Pie data={cliente}/>
                        </div>
                    </div>
                )
            })}
        </div>
      )
    } else {
        return(
            <div style={{height:'50vh', alignContent:'center'}}>
            <center><h3>Sin datos</h3></center>
            </div>
        )
    }
}

export default TableroStorage