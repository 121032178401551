import React from 'react'
import imgItem from '../images/shopping-store.png'
import { useTranslate } from 'react-admin'

function CardItem({dataItems,handleOpenItem}) {
    const translate = useTranslate()

  return (
    <div>
        <section class="articles">
            {dataItems.map((item)=>{
                return(
                <article>
                    <div class="article-wrapper">
                        <figure>
                            <img src={item.src ? item.src : imgItem} alt="" />
                        </figure>
                        <div class="article-body">
                            <h4>
                                {item.name}
                            </h4>
                            {dataItems.length == 1 && localStorage.getItem('token') ? (
                                <div>
                                    <span>{translate('traducciones.tienda.precio')+item.Price} USD</span>
                                </div>
                            ):(
                                <a className="read-more" onClick={(e)=>handleOpenItem(e,item)}>
                                    {translate('traducciones.tienda.vermas')} <span class="sr-only">about this is some title</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 2 20 20" fill="#30b165">
                                        <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                                    </svg>
                                </a>
                            )}
                        </div>
                    </div>
                </article>
                )})
            }
        </section>
    </div>
  )
}

export default CardItem