import React from 'react'
import { useState, useEffect } from 'react';
import { useDataProvider, Loading } from 'react-admin'
import PlantillaCards from '../Components/PlantillaCards';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ComponentPAY } from '../Components/ComponentPay';
import '../Libs/ExternalViewer/Resources/Styles/Cards.css'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { getHistoryUser } from '../DataProviders/GlobalDataProvider';
import { clientData } from '../helpers/helpers';

const Plantillas = () => {

  const dataProvider = useDataProvider();
  const [plantillas, setPlantillas] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);
  const [details, setDetails] = useState(null);
  const [openPay, setOpenPay] = useState(false);

  useEffect(() => {
    dataProvider.getList('plantillaNodos',{})
    .then((res)=>{
      // setPlantillas(res.data)
      consultHistory(res.data);
    }).catch((err)=> console.log(err))
  }, [])

  const handleOpenDetails = (e,plan) =>{
    setOpenDetails(true);
    setDetails(plan);
  }

  const consultHistory = async(plantillas) =>{
    try {
      //Se consulta el historial
      const response = await getHistoryUser(clientData.CustomerID);
      //Se extraen las plantillas adquiridas
      const itemPlantilla = await response.Information.find(item => item.ItemId === 8);
  
      if (itemPlantilla) {
        // Actualizar el estado con el elemento de la plantilla correspondiente
        obtenerPlantillasAdquiridas(plantillas,itemPlantilla.ItemName)
      }else{
        setPlantillas(plantillas)
      }
    } catch (error) {
        console.log(error);
    }
  }

  function obtenerPlantillasAdquiridas(plantillasDisponibles, cadenaAdquirida) {
    // Dividir la cadena adquirida en un array usando ',' como delimitador
    const plantillasAdquiridas = cadenaAdquirida.split(': ')[1].split(',');

    const plantillasUsuario = plantillasDisponibles.map((industria) => {
      if (plantillasAdquiridas.includes(industria.IndustryName)) {
        return {
            ...industria,
            isNow: true
        };
      } else {
          return industria;
      }
    })
  
    setPlantillas(plantillasUsuario)
  }

  if( plantillas == null) return <Loading/>

  return (
    <div>
        <div style={{paddingInline:'25px'}}>
            <h3 className='homeTitle'>¡Descubre nuestras plantillas!</h3>
            <p className='homeSub'>¿No sabes cómo organizar la estructura de carpetas para tu empresa? En nuestro catálogo, encontrarás diversas plantillas específicas para tu industria o sector. Al adquirirlas, te proporcionaremos una plantilla completa con carpetas preconfiguradas y campos personalizados.</p>
        </div>
      <PlantillaCards dataTemplates={plantillas} handleOpenDetails={handleOpenDetails}/>
      
      {/* Dialog para mostrar detalles de la plantilla */}
      <Dialog open={openDetails}>
        <DialogContent>
            <div className='row' style={{maxWidth:'1000px', alignItems:'center'}}>
                <div className='col-md-5'>
                    <PlantillaCards dataTemplates={[details]} handleOpenDetails={handleOpenDetails}/>
                </div>
                <div className='col-md-4 list' style={{fontSize:'17px', marginLeft:'50px'}}>
                    <h2>Estructura de la plantilla: </h2>
                    <ul>
                        {details != null && details.descriptions.map((d)=>{
                            return(
                                <li><span>{d.desc}</span></li>
                            )
                        })
                        }
                    </ul>
                </div>
            </div>
            </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpenDetails(false)} className='bg-danger'>Cerrar</Button>
            <Button onClick={() => {setOpenPay(true)}} style={{backgroundColor:'rgb(92, 157, 102) !important'}}>Comprar <AddShoppingCartIcon/></Button>
        </DialogActions>
      </Dialog>

      {/* Dialog para procesar pago */}
      <Dialog open={openPay}>
        <DialogTitle>Proceso de pago</DialogTitle>
        <DialogContent>
            <ComponentPAY item={details} type={1}/>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpenPay(false)} className='bg-danger'>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Plantillas