import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { BooleanInput, SimpleForm } from 'react-admin';
import { useState } from 'react';
import { Stack } from '@mui/material';
import InputsPredefinidas from './InputsPredefinidas';

function ConfigurationCampos({dataNodos, handleConfigFields, handleChangueComparadorNodos, choicesComun, handleSaveComunes, setOpenComunes, handleDeleteField}) {

    const [statusNodos,setStatusNodos] = useState(null);

    const getValues = async values =>{
        setStatusNodos(values)
    }

    const stiloOff = {
        color:'#30b165',
        marginTop: '-22px',
        fontSize: '14px'
    }

    const stiloempty = {
        marginTop: '-22px',
        fontSize: '14px'
    }

    const stiloOn = {
        color:'#30b165',
    }

  return (
    <div style={{paddingLeft:'15px'}}>
        <div style={{marginLeft:'10px', marginTop:'-25px', marginBottom:'10px'}}>
            <span style={{fontWeight:'bold', color:'#30b165'}}>Configuración de campos</span><br/>
            <span>Es hora de configurar tus campos seleccionados. Aquí puedes definir qué tipo de campo será, cómo se comparará y asignarle un valor fijo si es necesario.</span>
        </div>
        <div>
        <span style={{fontWeight:'bold', marginLeft:'10px', color: '#30b165'}}>Nodos seleccionados:</span><br/>
            {/* <div>
                <span style={{marginLeft:'10px', fontWeight:'bold'}}>¿Como desea comparar los diferentes nodos?</span>
                <SimpleForm toolbar={null}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <span style={!statusNodos ? stiloOff: stiloempty}>Obtener resultados si al menos en un nodo hay coincidencias</span>
                        <BooleanInput style={statusNodos ? stiloOn : null} labelPlacement="bottom" size="medium" label='Obtener resultados si en todos los nodos debe haber coincidencia' source="ComparadorNodos" validate={getValues} onChange={(e)=>handleChangueComparadorNodos(e)}/>
                    </Stack>
                    <BooleanInput source='ComparadorNodos' label='Que todos los resultados de los nodos coincida' defaultValue={false} onChange={(e)=>handleChangueComparadorNodos(e)}/>
                </SimpleForm>
            </div> */}
            {dataNodos.map((nodo)=>{
                return(
                    <div style={{marginBottom:'5px'}}>
                        <Accordion id='acordion'>
                            <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                            >
                            <Typography><span>{'Nodo: ' + nodo.TextoNodo}</span></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <>
                                <SimpleForm save={(values)=>handleConfigFields(values,nodo)}>
                                    {nodo.fields.map((campo,indiceCampo)=>{
                                        return(
                                            <InputsPredefinidas campo={campo} indiceCampo={indiceCampo} choicesComun={choicesComun} handleSaveComunes={handleSaveComunes} setOpenComunes={setOpenComunes} handleDeleteField={handleDeleteField}/>
                                        )
                                    })}
                                    {/* <div style={{marginTop:'10px',marginLeft:'15px',marginRight:'15px', marginBottom:'10px'}}>
                                        <TextInput type={'hidden'} optionText="GrupoNombre" defaultValue={ nodo.fields } optionValue="Nombre" source={"fields"} disabled label={'Campos'}/>
                                        <ArrayInput source='fields' label='' id='fieldsSelect'>
                                            <SimpleFormIterator disableReordering disableAdd>
                                                <TextInput style={{marginRight:'15px' , maxWidth:'18%',float:'left', display: 'flex'}} source='NombreIndice' id='NombreIndice' label={'Nombre de campo:'} disabled/>
                                                <SelectInput style={{marginRight:'15px' , maxWidth:'20%',float:'left', display: 'flex'}} source="TipoCampo" id='TipoCampo' choices={choicesTypeField} optionText="operador" optionValue="idOperador" label='Tipo de campo' defaultValue={'VARIABLE_VALUE'}/>
                                                <SelectInput style={{marginRight:'15px' , maxWidth:'20%',float:'left', display: 'flex'}} source="Comparacion" id='Comparacion' choices={choicesOperator} optionText="operador" optionValue="idOperador" label='Comparador' defaultValue={'='}/>
                                                <TextInput source='ValorFijo' id='ValorFijo' defaultValue={''} style={{maxWidth:'25%',float:'left', display: 'flex'}} label={'Asignar valor fijo'}/>
                                                <SelectInput style={{marginRight:'15px' , maxWidth:'20%',float:'left', display: 'flex'}} source="CampoComun" id='CampoComun' choices={choicesComun} optionText="operador" optionValue="idOperador" label='Campo común'/>
                                            </SimpleFormIterator>
                                        </ArrayInput>
                                        <BooleanInput source='ComparadorCampos' label='Que todos los campos coincidan' defaultValue={false}/>
                                    </div> */}
                                        <Stack direction="row" spacing={1} alignItems="center" style={{width:'100%', fontSize:'12px'}}>
                                            <span style={{marginTop:'-22px'}}>Localizar documentos que cumplan con al menos una condición</span>
                                            <BooleanInput labelPlacement="bottom" size="medium" label='Localizar documentos que cumplan con todas las condiciones' source="ComparadorCampos" defaultValue={ nodo.ComparadorCampos ? nodo.ComparadorCampos : false }/>
                                        </Stack>
                                </SimpleForm>
                                </>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                )
            })}
        </div>
    </div>
  )
}

export default ConfigurationCampos