import React from 'react'
import { AutocompleteArrayInput, SimpleForm, TextInput } from 'react-admin';

function DetallesPredefinida({dataNodos, dataUsers, dataGrupos, handleChangueNameQuery, handleChangueUsers, handleChangueGroups, nameQuery, AssignedUsers, AssignedGroups}) {

  return (
    <div style={{marginTop:'-40px'}}>
    <SimpleForm toolbar={null}>
        <div className='row' style={{paddingLeft:'10px', marginBottom:'10px', width:'100%'}}>
            <div className='col-md-12' style={{fontSize:'14px'}}>
                <span style={{fontWeight:'bold'}}>Detalles de la consulta</span><br/>
                <span>Como último paso, debemos asignar un nombre que nos ayudará a identificar la consulta para su posterior uso. También debemos asignar la consulta a los usuarios y/o grupos que podrán acceder a ella.</span>
            </div>
        </div>
        <div className='row' style={{paddingLeft:'15px', width:'100%'}}>
            <TextInput style={{ width: '47%',marginRight:'15px'}} name='NombreQuery' id='NombreQuery' label="Nombre de consulta" onChange={(e)=>handleChangueNameQuery(e.target.value)} defaultValue={nameQuery}/>
        </div>
        <div className='row' id='permisosQuery' style={{justifyContent:'space-between', width:'100%'}}> 
        <hr className='hr-text' data-content={'Asignar permisos'}/>
            <div className='col-md-6'>  
            {dataUsers ? (
                <AutocompleteArrayInput options= { {fullWidth: true } } label="traducciones.campos.usuario"  optionText="Nombre" optionValue="IdUsuario" source="usuarios" choices= { dataUsers ? dataUsers : null } style={{width:'100%'}} onChange={(e)=>handleChangueUsers(e)} defaultValue={ AssignedUsers }/>
                ) : null }
            </div>
            <div className='col-md-6'>   
                {dataGrupos ? (
                <AutocompleteArrayInput options= { {fullWidth: true } } label="traducciones.campos.grupo"  optionText="GrupoNombre" optionValue="IdGrupo" source="grupos" choices= { dataGrupos ? dataGrupos : null } style={{width:'100%'}} onChange={(e)=>handleChangueGroups(e)} defaultValue={ AssignedGroups }/>
                ) : null }
            </div>
        </div>
    </SimpleForm>
    </div>
  )
}

export default DetallesPredefinida