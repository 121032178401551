import * as React from 'react';
import { useState , useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { clientData, formatCurrency, formatText } from '../helpers/helpers'
import { Loading, useTranslate } from 'react-admin';
import swal from 'sweetalert';
import { getHistoryUser } from '../DataProviders/GlobalDataProvider';


export function Prices({ onClick , setFlag, type, item, extra}) {
  const defaultTheme = createTheme();
  const cardStyle = { cursor: 'pointer'};
  
  const [selectedCard, setSelectedCard] = useState(null);
  const [plans, setPlans] = useState(null);
  const translate = useTranslate();

  useEffect(async () => {
    if(type == 1){
        setPlans({
            DollarPriceMXN: 17.43,
            src: item.src,
            MaximagePlans: [
                {
                    IdPlan: item.IndustryId,
                    PlanName: 'Plantilla - ' + item.IndustryName,
                    Price: 14.99,
                    Validity: "Unico",
                    Description: 'Creación de plantilla con nodos, subnodos y campos personalizados.',
                    PlanItems: [
                        {
                            ItemId: 1,
                            ItemName: 'Plantilla - '+ item.IndustryName,
                            ItemInformation: item
                        }
                    ]
                },
            ]
        })
      }else{
        setPlans({
          DollarPriceMXN: 17.43,
          src: item.src,
          MaximagePlans: [
              {
                  IdPlan: item.ItemId,
                  PlanName: extra ? item.ItemName + ' + Plantilla de prueba' : item.ItemName,
                  Price: extra ? item.Price + 14.99 : item.Price,
                  Validity: "Unico",
                  Description: extra ? item.Desc + ' ➡️ $' + (item.Price * 17.43).toFixed(2) + '\r\nPlantilla de prueba ➡️ $ '+ (14.99 * 17.43).toFixed(2) : item.Desc,
                  PlanItems: [
                      {
                          ItemId: item.ItemId,
                          ItemName: item.ItemName,
                          ItemInformation: item
                      }
                  ]
              },
          ]
      })
    }
    //   try {
    //     const response = await getPlans();
    //       setPlans(response.Information)
    //   } catch (error) {
    //       console.log(error);
    //   }
  }, []);

  async function dataExtract(data) {
    if(clientData.PlatformCustomerId == 'TrialMonth'){
      validTrial(data)
    }else{
      onClick({ data : data, dollar : plans.DollarPriceMXN})
      setSelectedCard(data.Price)
    }
  }

  const getMesageRenovacion = () =>{
    swal({
      icon: 'info',
      text: translate('traducciones.tienda.renovacion')
    })
  }
  
  const validTrial = async(data) => {
    if(type == 1){
      const historial = await getHistoryUser(clientData.CustomerID)
      // console.log(historial);
      if(historial.Success){
        const plantillas = historial.Information.filter((item)=> item.ItemId == 8)
        if(plantillas[0].ItemName.length > 12){
          console.log('Ya ha adquirido su plantilla de prueba');
          getMesageRenovacion()
        }else{
          console.log('Aun no ha adquirido su plantilla de prueba');
          swal({
          icon: 'info',
          text: translate('traducciones.tienda.planGratis'),
          buttons:{
            cancel: {
              text: translate('traducciones.campos.cancelar'),
              value: false,
              visible: true,
              className: "bg-danger text-white",
              closeModal: true,
            },
            confirm: {
              text: translate('traducciones.campos.aceptar'),
              value: true,
              visible: true,
              className: "bg-success text-white",
              closeModal: true
            }
          }
        }).then((res)=>{
          if(res){
            onClick({ data : data, dollar : plans.DollarPriceMXN})
            setSelectedCard(data.Price)
          }
        })
        }
      }
    }else{
      getMesageRenovacion()
    }
  }

  useEffect(() => {
    if (plans) {
      setFlag(true)
    }
  }, [plans])

  if (!plans) {
    return <Loading />
  }

  return (
    <ThemeProvider theme={defaultTheme}>
        <Grid container spacing={5} >
          {plans.MaximagePlans.map((plan) => (
            <Grid item key={plan.PlanName} xs={12} >
              <Card style={cardStyle} onClick={ () => dataExtract(plan)} >
                <CardHeader
                  title={plan.PlanName}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  sx={
                    {
                      backgroundColor:
                      selectedCard === plan.Price ? '#30b165' : (theme) =>
                      theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
                      color: selectedCard === plan.Price ? 'white' : 'black'
                    }
                  }
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                    >
                    <Typography component="h2" variant="h3" color="text.primary">
                      {formatCurrency(plan.Price * plans.DollarPriceMXN)}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      {/* /mes */}
                    </Typography>
                  </Box>
                  <ul style={{paddingRight:'1em'}}>
                    {formatText(plan.Description).slice(0,6)}
                  </ul>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </ThemeProvider>
  )
}