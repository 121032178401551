import React from 'react';
import { useState } from 'react';
import {
    Table,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    Card,
    Checkbox
  } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import { MenuItem } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LockIcon from '@material-ui/icons/Lock';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useHistory } from 'react-router-dom';
import jsonExport from 'jsonexport/dist';
import { triggerBase64Download } from 'react-base64-downloader';
import {
    Pagination,
    useTranslate,
    useDataProvider,
    AutocompleteArrayInput,
    FormWithRedirect,
    SaveButton,
    Link,
    TextInput,
    required,
    NumberInput,
    DateInput,
    SelectInput,
    useRefresh, 
    useNotify,
    Loading,
    ArrayInput,
    SimpleFormIterator,
    Query,
    Error
  } from 'react-admin';
  import '../index.css'
import swal from 'sweetalert';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import FolderIcon from '@material-ui/icons/Folder';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import 'intro.js/introjs.css';
import { useEffect } from 'react';
import introJs from 'intro.js';
import Loader from '../Components/Loader'

const useStyles = makeStyles({
    nodo : {
        padding: '6px',
        border: '1px solid #319b4252',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '14px',
        width : 'max-content'
    },
    nodoSeccionado :  {
        padding: '6px',
        border: '1px solid black',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '14px',
        backgroundColor: "#319b4257",
        color: '#277233'
    },
    oculto : {
        visibility : "hidden"
    }
});

/*
Este archivo contiene el componente generico para la visualizacion de los resultado de las busquedas de los documentos.
Anteriormente este codigo se repetia en cada una de las busquedas pero ahora desde este componente se puede utilizar en todas
las busquedas y modificar el listado desde un solo lugar.
Este componente solo se utilizara si los resultados son en un dispositivo con pantalla grande, y no un movil.
*/

const TableResultado = (props) => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const hidePorcentage = props.hidePorcentage ? props.hidePorcentage : false;
    var dataSearch = props.dataSearch;
    var allDocuments= props.allDocuments;
    const resetAllReady = props.resetAllReady;
    const getAllDocuments = props.getAllDocuments;
    var allReady = props.allReady;
    const cambiaOrden = props.cambiaOrden;
    const consultaDocumento = props.consultaDocumento;
    const deleteDocument = props.deleteDocument;
    const order = props.order;
    const sort = props.sort;
    const checkPermiso = props.checkPermiso;
    const perPage= props.perPage;
    const setPage= props.setPage;
    const setPerPage = props.setPerPage;
    const page= props.page;
    const esBastanteo = props.esBastanteo;
    const permisos = JSON.parse(localStorage.getItem("permisos"));
    const history = useHistory(); 
    const refresh = useRefresh();
    const notify = useNotify();

    const [dataUsers, setDataUsers] = useState(null);
    const [dataGrupos, setDataGrupos] = useState(null);
    const [openEditUser, setOpenEditUser] = useState(false); 
    const [dataUsersSeleccionados, setDataUsersSeleccionados] = useState(null);
    const [dataGruposSeleccionados, setDataGruposSeleccionados] = useState(null);
    const [documentt, setDocument] = useState(null);
    const [loadinga, setLoadinga] = useState(false);
    const [openEditDocument, setOpenEditDocument] = useState(false); 
    const [documentoEditar, setDocumentoEditar] = useState(null);
    const [todosSeleccionados, setTodosSeleccionados] = useState(false);
    //Variables utilizadas para reasignar un documento
    const [openSelectNode, setOpenSelectNode] = useState(false);
    const [nodoActual, setNodoActual] = useState("");
    const [randomUpdate, setRandomUpdate] = useState(null);
    const [dataDocument, setDataDocument] = useState(null);
    const classes = useStyles();  
    const inTour = JSON.parse(localStorage.getItem('activeTour'))
    const [openDownCSV, setOpenDownCSV] = useState(false);
    const [numResults, setNumResults] = useState(0);
    const [openEspera, setOpenEspera] = useState(false);
    const [nodesKeys,setNodeKeys] = useState([])

    const viewNode = (nodoPadre) => {
        swal({
            text:'¿Esta seguro de re-asignar el documento a ' + nodoPadre.TextoNodo + '?',
            buttons:{
                cancel: {
                  text: translate('traducciones.campos.cancelar'),
                  value: null,
                  visible: true,
                  className: "bg-danger text-white",
                  closeModal: true,
                },
                confirm: {
                  text: 'Confirmar',
                  value: true,
                  visible: true,
                  className: "btnVerde text-white",
                  closeModal: true
                }
              }
        }).then((res)=>{
            if(res){
                dataProvider.update('assignNode', { id: dataDocument ? dataDocument.IdDocument : 0, IdNode: nodoPadre.RutaNodo })
                .then(({data}) => {
                    swal({
                        icon:'success',
                        text: data.json ? data.json.Message : "Su documento ha sido reasignado y ahora se encuentra en la sección 'Clasificar'. Puede encontrarlo allí para volver a indexarlo según sus necesidades.",
                        title: 'Documento re-asignado'
                    })
                    const dataAux = dataSearch.data.filter(e=> e.IdDocument != dataDocument.IdDocument)
                    dataSearch.data= dataAux
                    setOpenSelectNode(false);
                })
                .catch(error => {
                    swal({
                        icon:'error',
                        text: 'Lo sentimos, ocurrió un problema al re-asignar el documento '
                    })
                });   
            }
        })

    }

    const colapsar = (nodo) => {
        setRandomUpdate(Math.random());
        nodo.colapsado = !nodo.colapsado;
    }

    const selectNode = (record) => {
        setDataDocument(record);
        setNodoActual(record.IdNode);
        setOpenSelectNode(true);
    }

    function dibujaNodosHijos(nodo,classes,viewNode,translate,nodoActual,colapsar){
        if(nodo.hijos != undefined){
            return (
                <div>
                    {nodo.hijos.map(
                    (item) => (
                        <div  style={{ marginLeft: '40px', marginTop : '-5px'}}>
                            <div style={{ float: 'left' }}>
                                <SubdirectoryArrowRightIcon style={{ fontSize: '30px' }}/>
                            </div>
                            <div className={ classes.nodo } > <FolderIcon style={{ marginRight: '5px'}} />
                                { item.TextoNodo }
                                <Button className= { nodoActual == item.RutaNodo ? classes.oculto : "" }  title={ translate('traducciones.titulos.reasignar')} onClick={() => viewNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                    <LocationSearchingIcon style={{ fontSize: '16px'}}  />
                                </Button>
                                { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                                <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                    <ExpandLessIcon />
                                </Button>
                                }
                                { item.colapsado && item.hijos && item.hijos.length > 0 &&
                                <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                    <ExpandMoreIcon />
                                </Button>
                                }
                            </div>
                            { !item.colapsado &&
                            <div>
                                { dibujaNodosHijos(item,classes,viewNode,translate,nodoActual,colapsar) }
                            </div>
                            }
                        </div>
                    ))
                    }
                </div>
            )
        }
    }

    const [anchorEl, setAnchorEl] = useState(false);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [isCheck, setIsCheck] = useState([]);

    let introNodos;
    const stepsTo = {
        welcome: [
            {
                intro: translate('traducciones.introTabla.intro1'),
                title: translate('traducciones.introTabla.title1'),
                position: 'bottom',
                scrollTo: 'off',
                element: '#contentR'
            },
            {
                intro: translate('traducciones.introTabla.intro2'),
                title: translate('traducciones.introTabla.title2'),
                element: '.MuiTableRow-root',
                position: 'bottom'
            },
            {
                intro: translate('traducciones.introTabla.intro3'),
                title: translate('traducciones.introTabla.title3'),
                element: '.checkDocumento',
                position: 'bottom'
            },
            {
                intro: translate('traducciones.introTabla.intro4'),
                title: translate('traducciones.introTabla.title4'),
                element: '#selecTodos',
                position: 'bottom'
            },
            {
                intro: translate('traducciones.introTabla.intro5'),
                title: translate('traducciones.introTabla.title5'),
                element: '#basic-button',
                position: 'bottom'
            },
            {
                intro: translate('traducciones.introTabla.intro6'),
                title: translate('traducciones.introTabla.title6'),
                element: '#editDocumento',
                position: 'bottom'
            },
            {
                intro: translate('traducciones.introTabla.intro7'),
                title: translate('traducciones.introTabla.title7'),
                element: '#reasigDocumento',
                position: 'bottom'
            },
            {
                intro: translate('traducciones.introTabla.intro8'),
                title: translate('traducciones.introTabla.title8'),
                element: '#deleteDocumento',
                position: 'bottom'
            },
            {
                intro: translate('traducciones.introTabla.intro9'),
                title: translate('traducciones.introTabla.title9'),
                element: '#asigDocumento',
                position: 'bottom'
            },
            {
                intro: translate('traducciones.introTabla.intro10'),
                title: translate('traducciones.introTabla.title10'),
                element: '#verDocumento',
                position: 'bottom'
            }                        
        ],  
    }

    const getTour = (stepsGuide, isDisable, time, compInit) =>{
        if(inTour.querys){
            setTimeout(()=>{
                compInit != '' ? introNodos = introJs(compInit) : introNodos = introJs()
                introNodos.setOptions({
                    disableInteraction: isDisable,
                    doneLabel: translate('traducciones.introBotones.doneLabel'),
                    nextLabel: translate('traducciones.introBotones.nextLabel'),
                    prevLabel: translate('traducciones.introBotones.prevLabel'),
                    steps: stepsGuide             
                  });
        
                introNodos.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).oncomplete(()=>{
                    showHelp()
                }).onexit(()=>{
                    showHelp()
                }).start();
            },time)
        }
    }

    let introHelp;
    introHelp = introJs('#root');
    introHelp.setOptions({
        disableInteraction: false,
        doneLabel: translate('traducciones.introBotones.doneLabel'),
        nextLabel: translate('traducciones.introBotones.nextLabel'),
        prevLabel: translate('traducciones.introBotones.prevLabel'),
        tooltipPosition:'top',
        steps: [
                {
                intro: translate('traducciones.introHelp.intro1'),
                title: translate('traducciones.introHelp.title1'),
                element: '#help'
                },
        ]                
    });

    const showHelp = () =>{
        const offPermisos = {
            querys: false,
            carga: false,
            admin: false,
            estructura: false,
        }
        if(document.cookie == 'introjs-dontShowAgain=true'){
            document.cookie = 'introjs-dontShowAgain =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
                setTimeout(()=>{
                    introHelp.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).oncomplete(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).onexit(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).start();                         
                },500)
        }
    }

    function crearCookie(nombre, valor, diasParaExpirar) {
    var fechaExpiracion = new Date();
    fechaExpiracion.setDate(fechaExpiracion.getDate() + diasParaExpirar);
    var cookie = nombre + '=' + valor + '; expires=' + fechaExpiracion.toUTCString() + '; path=/';
    document.cookie = cookie;
    }

    useEffect(() => {
        getTour(stepsTo.welcome, false, 800, '#results')
    }, []);

    useEffect(()=>{
        if(allReady == 3){
            setTimeout(()=>{
                setOpenEspera(false)
                exportarCsvAll()
                swal({
                    icon : 'success',
                    text : translate('traducciones.stepsDownCsv.final')
                })
            },500)
        }
    },[allReady])
    //Esta sera la funcion para llamar a la api de MaxImage para cambiar los permisos de los socumentos resultantes de la busqueda
    const cambiarPermisosDocumento = (doc) => {
        setDocument(doc);
        dataProvider.getList('usuarios', { filter:{} })
        .then(({ data }) => {
            if(dataUsers == null){
                setDataUsers(data);
            }
            dataProvider.getOne('permisosDocumentos', { id: doc.IdDocument})
            .then(({ data }) => {  
                if(data != null && data.data != null){
                    if(data.data.Users != null){
                        setDataUsersSeleccionados(data.data.Users);
                    }
                    if(data.data.Groups != null){
                        setDataGruposSeleccionados(data.data.Groups);
                    }
                }
                dataProvider.getList('grupos', { filter:{} })
                .then(({ data }) => {          
                    if(dataGrupos == null){
                        setDataGrupos(data);
                    }  
                    setOpenEditUser(true);
                });
            })
            .catch(error => {
            });   
        })
        .catch(error => {
        });
    }

    const handleCloseClick = () => {
        setOpenEditUser(false);
    }

    const handleCloseClick2 = () => {
        setOpenEditDocument(false);
    }


    const EditaDocumento = (doc) => {
        doc.DatosDocumento.map((d)=>{
            if(d.Multiple){
                if (!d.ValoresLlaveObj) {
                    d.ValoresLlaveObj = d.ValoresLlave.map((v) => ({
                      ['valor-' + d.IdIndice]: v,
                    }));
                  }
                if(d.ValoresTabla){
                    d.ValoresLlaveObj=compararValorMul(d.ValoresTabla,d.ValoresLlaveObj)
                }
            }else{
                if(d.ValoresTabla){
                    d.ValorLlave = compararValor(d.ValoresTabla,d.ValorLlave)
                }
            }
        })
        setOpenEditDocument(true);
        setDocumentoEditar(doc);
    }

    //Funcion que actualiza los permisos del documento seleccionado en la api de MAxImage
    const handleSubmitEditUsers = async values => {
        setLoadinga(true);
        dataProvider.update('accessDocument', { id: documentt.IdDocument , Users : values.usuarios, Grupos : values.grupos })
        .then(({ data }) => {
            setLoadinga(false);
            setOpenEditUser(false);
        })
        .catch(error => {
            setLoadinga(false);
        });  
    };


    //Funcion que actualiza los datos del documento seleccionado en la api de MAxImage
    const handleSubmitUpdateDocument = async values => {
        setLoadinga(true);
        setLoadinga(false);
        var datos = [];
        documentoEditar.DatosDocumento.forEach(function (record, index) { 
            if(record.ValorLlave == undefined){
                record.ValorLlave = ''
            }
            if(typeof values['campo-' + record.IdIndice] === 'string'){
                record.ValorLlave = values['campo-' + record.IdIndice] ? values['campo-' + record.IdIndice].trim() : record.ValorLlave.trim();
            }else{
                record.ValorLlave = values['campo-' + record.IdIndice] ? values['campo-' + record.IdIndice] : record.ValorLlave
            }
                if(record.Multiple){
                    record.ValorLlave = "";
                    var valores = [];
                    values['multiple-' + record.IdIndice].forEach(function(e){
                        if(e){
                            if(e["valor-" + record.IdIndice] != "" && e["valor-" + record.IdIndice] != undefined && e["valor-" + record.IdIndice] != null){
                                valores.push(e["valor-" + record.IdIndice]);
                            }
                        }
                    });
                    record.ValoresLlave = valores;
                    // record.ValoresLlaveObj = undefined;
                    if(record.ValoresLlave.length==0){
                        record.ValoresLlave=[''];
                    }
                }
                if(record.IdIndice != 0){
                    datos.push(record);
                }
        });

        
        dataProvider.update('documento', { IdDocument: documentoEditar.IdDocument , IdNode : documentoEditar.IdNode, DocumentData : datos })
        .then(({ data }) => {
            setLoadinga(false);
            setOpenEditDocument(false);
        })
        .catch(error => {
            setLoadinga(false);
            setOpenEditDocument(false);
        });  
    };

    //Funcion para seleccionar un item de la tabla en el caso que tenga el componente de tipo check
    const onToggleItem = (e) => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }
    }

    //Funcion para seleccionar todos en caso de que exista el selector total
    const handleSelectAll = () => {
        setTodosSeleccionados(!todosSeleccionados);
        if(dataSearch && dataSearch.data){
            let marcas = dataSearch.data.map(li => li.IdDocument + "");
            setIsCheck(marcas);
        }

        if (todosSeleccionados) {
          setIsCheck([]);
        }
      };


    function b64toBlob(b64Data, contentType) {
        contentType = contentType || '';
        var sliceSize = 512;
        b64Data = b64Data.replace(/^[^,]+,/, '');
        b64Data = b64Data.replace(/\s/g, '');
        var byteCharacters = window.atob(b64Data);
        var byteArrays = [];
    
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);
    
            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
    
            var byteArray = new Uint8Array(byteNumbers);
    
            byteArrays.push(byteArray);
        }
    
        var blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }

    const exportarCsv = () => {
        swal({
            title: translate('traducciones.notificaciones.aviso'),
            text:  translate('traducciones.notificaciones.avisoMsj'),
            buttons:{
                cancel: {
                  text: translate('traducciones.campos.cancelar'),
                  value: null,
                  visible: true,
                  className: "bg-danger text-white",
                  closeModal: true,
                },
                confirm: {
                  text: translate('traducciones.campos.aceptar'),
                  value: true,
                  visible: true,
                  className: "btnVerde text-white",
                  closeModal: true
                }
            }
        }).then((res)=>{
            if(res){
                setLoadinga(true);
                var datatoExport = [];
                if(dataSearch && dataSearch.data){
                    var hayseleccionados = false;
                    dataSearch.data.forEach(d => {
                        let document = []
                        if(isCheck.includes("" + d.IdDocument)){
                            document.push({
                               ['Ubicación']: `"${d.IdNodePath.replace(/\n/g, ' ')}"`
                           }); 
                            hayseleccionados = true;
                            d.DatosDocumento.forEach(e => {
                                if(e.Multiple == true){
                                    document.push({
                                        [e.NombreIndice] : `"${e.ValoresLlave.join(',').replace(/\n/g, ' ')}"`
                                    })
                                } else if(e.NombreIndice != 'UBICACIÓN'){
                                document.push({
                                    [e.NombreIndice] : `"${e.ValorLlave.replace(/\n/g, ' ')}"`
                                });    
                            }     
                            });
                            datatoExport.push(document);
                        }
                    });
                    if(hayseleccionados){
                        jsonExport(datatoExport,{rowDelimiter: ',',textDelimiter:'"'}, function(err, csv){
                            setLoadinga(false);
                            if(err) return console.log(err);
                            const blob = new Blob(["\ufeff",csv], { type: "text/csv;charset=utf-8" });
                            if (window.saveAs) { window.saveAs(blob, 'csv_info.csv'); }
                            else { navigator.saveBlob(blob, 'csv_info.csv'); }           
                        });
                    }
                    if(!hayseleccionados){
                        setLoadinga(false);
                        notify(translate("traducciones.error.noHaySeleccion"), "error");
                    } 
                }else{
                    setLoadinga(false);
                }
            }
        })
    }

    const exportarCsvAll = () => {
        switch (allReady) {
            case 1: 
            swal({
                title: translate('traducciones.notificaciones.aviso'),
                text:  translate('traducciones.notificaciones.avisoMsj'),
                buttons:{
                    cancel: {
                      text: translate('traducciones.campos.cancelar'),
                      value: null,
                      visible: true,
                      className: "bg-danger text-white",
                      closeModal: true,
                    },
                    confirm: {
                      text: translate('traducciones.campos.aceptar'),
                      value: true,
                      visible: true,
                      className: "btnVerde text-white",
                      closeModal: true
                    }
                }
            }).then((res)=>{
                if(res){
                    swal({
                        title: translate('traducciones.stepsDownCsv.title1'),
                        text:  translate('traducciones.stepsDownCsv.text1') + dataSearch.total +' '+ translate('traducciones.stepsDownCsv.text2') + '\n' +translate('traducciones.stepsDownCsv.text3') ,
                        buttons:{
                            cancel: {
                              text: translate('traducciones.campos.cancelar'),
                              value: 1,
                              visible: true,
                              className: "bg-danger text-white",
                              closeModal: true,
                            },
                            custom: {
                                text: translate('traducciones.stepsDownCsv.btnConfig'),
                                value: 3,
                                visible: true,
                                className: "btnVerde text-white",
                                closeModal: true
                            },
                            // confirm: {
                            //   text: 'Aceptar',
                            //   value: 2,
                            //   visible: true,
                            //   className: "bg-success text-white",
                            //   closeModal: true
                            // },
                          }
                    }).then((res)=>{
                        if(res == 3){
                            setOpenDownCSV(true);
                        }
                    })
                }
            })
                break;
            case 2:
                setOpenEspera(true)
                break;
            case 3:
                setLoadinga(true);
                var datatoExport = [];

                if (allDocuments != null) {
                allDocuments.data.forEach(d => {
                    let document = [];
                        document.push({
                        ['Ubicación']: `"${d.IdNodePath.replace(/\n/g, ' ')}"`, // Agregar comillas
                        });
                    d.DatosDocumento.forEach(e => {
                    if (e.Multiple == true) {
                        document.push({
                        [e.NombreIndice]: `"${e.ValoresLlave.join(',').replace(/\n/g, ' ')}"`, // Agregar comillas
                        });
                    } else if (e.NombreIndice != 'UBICACIÓN') {
                        document.push({
                        [e.NombreIndice]: `"${e.ValorLlave.replace(/\n/g, ' ')}"`, // Agregar comillas
                        });
                    }
                    });
                    datatoExport.push(document);
                });

                jsonExport(datatoExport, { rowDelimiter: ',' }, function(err, csv) {
                    setLoadinga(false);
                    if (err) return console.log(err);
                    const blob = new Blob(["\ufeff", csv], { type: "text/csv;charset=utf-8" });
                    if (window.saveAs) { window.saveAs(blob, 'csv_info.csv'); resetAllReady(); }
                    else { navigator.saveBlob(blob, 'csv_info.csv'); }
                });
                }
                break;       
            default:
                break;
        }    
    }

    const exportar = () => {
        swal({
            title: translate('traducciones.notificaciones.aviso'),
            text: translate('traducciones.notificaciones.avisoMsj'),
            buttons: {
                cancel: {
                    text: translate('traducciones.campos.cancelar'),
                    value: null,
                    visible: true,
                    className: "bg-danger text-white",
                    closeModal: true,
                },
                confirm: {
                    text: translate('traducciones.campos.aceptar'),
                    value: true,
                    visible: true,
                    className: "btnVerde text-white",
                    closeModal: true
                }
            }
        }).then((res) => {
            if (res) {
                if (dataSearch && dataSearch.data) {
                    var datatoupdate = dataSearch;
                    var hayseleccionados = false;
                    datatoupdate.data.forEach(element => {
                        if (isCheck.includes("" + element.IdDocument)) {
                            if (element.IdDocument != 0 && element.IdTipoDocumento != 0) {
                                hayseleccionados = true;
                                setLoadinga(true);
                                dataProvider.getOne('documento', { IdDocument: element.IdDocument })
                                    .then(({ data }) => {
                                        const campo = element.DatosDocumento[0].ValorLlave !== '' ? element.DatosDocumento[0].ValorLlave : 'Sin ' + element.DatosDocumento[0].NombreIndice;
                                        let ext = data.data.TipoDocumento.toLowerCase();
    
                                        let mimeType = ''; // Definir mimeType
                                        let fileName = (element.IdNodePath + campo + '_' + element.IdDocument).replace(/[.,\s]/g, '');
    
                                        if (ext === 'pdf' || ext === 'xls' || ext === 'xlsx' || ext === 'doc' || ext === 'docx' || ext === 'txt' || ext === 'log' || ext === 'csv') {
                                            if (ext === 'pdf') {
                                                mimeType = 'application/pdf';
                                            } else if (ext === 'xls' || ext === 'xlsx') {
                                                mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                                            } else if (ext === 'doc' || ext === 'docx') {
                                                mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                                            } else if (ext === 'txt' || ext === 'log') {
                                                mimeType = 'text/plain';
                                            } else if (ext === 'csv') {
                                                mimeType = 'text/csv';
                                            }
    
                                            fileName += '.' + ext; // Asegurar que la extensión es la correcta
    
                                            if (window.saveAs) {
                                                window.saveAs(b64toBlob(data.data.Base64file, mimeType), fileName);
                                            } else {
                                                navigator.saveBlob(b64toBlob(data.data.Base64file, mimeType), fileName);
                                            }
                                        } else if (ext === 'zip') {
                                            // Agregar soporte para archivos ZIP
                                            if (window.saveAs) {
                                                window.saveAs(b64toBlob(data.data.Base64file, 'application/zip'), fileName + '.zip');
                                            } else {
                                                navigator.saveBlob(b64toBlob(data.data.Base64file, 'application/zip'), fileName + '.zip');
                                            }
                                        } else {
                                            triggerBase64Download('data:image/' + data.data.TipoDocumento.toLowerCase() + ';base64,' + data.data.Base64file, fileName);
                                        }
                                    }).finally(() => {
                                        setLoadinga(false);
                                    })
                            }
                        }
                    });
                    
                    if (!hayseleccionados) {
                        notify(translate('traducciones.error.noHaySeleccion'), 'error');
                    }
                }
            }
        })
    }
          
    const exportarAll = () => {   
        if(dataSearch && dataSearch.data){
            var datatoupdate = dataSearch;
            var hayseleccionados = false;
            datatoupdate.data.forEach(element => {
                setLoadinga(true);
                dataProvider.getOne('documento', { IdDocument: element.IdDocument})
                .then(({ data }) => {                        
                    let ext = data.data.TipoDocumento.toLowerCase();
                    if(ext  == "pdf" || ext == "xls" || ext == "xlsx" || ext == "doc" || ext == "docx" || ext == "txt"){
                        if (window.saveAs) { window.saveAs(b64toBlob(data.data.Base64file,"application/" + data.data.TipoDocumento.toLowerCase()), element.IdDocument + '_document'); }
                        else { navigator.saveBlob(b64toBlob(data.data.Base64file,"application/" + data.data.TipoDocumento.toLowerCase()), element.IdDocument + '_document'); }
                    }else{
                        triggerBase64Download("data:image/" + data.data.TipoDocumento.toLowerCase() +";base64," + data.data.Base64file, element.IdDocument + '_document')
                    }
                    setLoadinga(false);
                })
            });  
        }
    }

    if(loadinga){
        return <Loading />
    }
    
    const compararValor = (arreglo, valor) => {
        const caracteres = /[.,]/g;
        let dv = "";
        if(typeof valor === 'string'){
            if(valor != ''){
                arreglo.map((v) => {
                    if(v.replace(caracteres, "").toUpperCase().includes(valor.replace(caracteres, "").toUpperCase())){
                        dv=v;
                    }
                });
                return dv;
            }
        }else{
            return valor;
        }
    };

    const compararValorMul = (data, ValoresLlaveObj) => {
        let newValores;
        let newV=[];
        if(ValoresLlaveObj.length>0){
            ValoresLlaveObj && ValoresLlaveObj.length>0 && ValoresLlaveObj.map((v)=>{
                if(Object.values(v)[0] != ''){
                    data && data.length>0 && data.map((d)=>{
                        if(d.replace(/[.,]/g, '').includes(Object.values(v)[0].replace(/[.,]/g, ''))){
                            newV.push({
                                [Object.keys(v)] : d
                            })
                        }
                    })
                }
            })
        }
        newV.length>0 ? newValores = newV : newValores = ValoresLlaveObj
        return newValores
    };

    let pathElements;

    const handleSubmitCSV = () =>{
        if(parseInt(numResults) > dataSearch.total || numResults < 0){
            swal({
                icon: 'info',
                text: translate('traducciones.stepsDownCsv.resultError')
            })
        }else{
            getAllDocuments(numResults)
            setOpenDownCSV(false);
            setOpenEspera(true);
        }
    }

    return  (
        <div id='contentR'>
        <Card style={{ overflowX: 'auto'}} id='results'>
            <Table className={"customDataGrid"}>
                { false ? (
                <TableHead>
                <TableRow>
                    <TableCell>
                    </TableCell>
                    <TableCell>
                    </TableCell>                    
                    <TableCell></TableCell>                    
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    {/*
                        dataSearch && dataSearch.data && dataSearch.data.length > 0 && dataSearch.data[0].DatosDocumento && dataSearch.data[0].DatosDocumento.map(({ NombreIndice }) => {
                            
                            
                            
                            
                            return  (
                                <TableCell>
                                    <div style={{minWidth: "max-content"}}>
                                        <a className="orderCustom" onClick={() => cambiaOrden(NombreIndice) } >{ NombreIndice}
                                            { NombreIndice == order ? (
                                        <span>
                                        { sort ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}    
                                        </span>                     
                                            ) : null
                                            }                                       
                                        </a>
                                    </div>
                                </TableCell>
                                )
                            }
                        )            
                        */}  
                </TableRow>

                </TableHead>
                ) : null }
                <TableBody>
                    {
                        
                        dataSearch && dataSearch.data && dataSearch.data.map(elem => {
                            return (  
                            <TableRow style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)'}}>
                                <TableCell padding="none" style={{width : '40px', padding: 0}}>
                                    { elem ? (
                                    <Checkbox
                                        id={"" + elem.IdDocument}
                                        checked= { isCheck ? isCheck.includes("" + elem.IdDocument) :false }
                                        onClick={onToggleItem}
                                        className='checkDocumento'
                                        // disabled={elem.IdDocument == 0 && elem.IdTipoDocumento == 0}
                                    />
                                    ) : null }
                                </TableCell>
                                <TableCell style={{width : '40px', padding: 0}}>
                                { checkPermiso('EditarDocumentosConsultados') &&
                                    <Button id='editDocumento' onClick={ () => EditaDocumento(elem) } 
                                    disabled={elem.IdDocument == 0 && elem.IdTipoDocumento == 0}
                                    >
                                            <EditIcon />
                                    </Button>
                                }
                                </TableCell>
                                <TableCell style={{width : '40px', padding: 0}}>
                                { checkPermiso('EditarDocumentosConsultados') &&
                                    <Button id='reasigDocumento' onClick={() => selectNode(elem) }
                                    disabled={elem.IdDocument == 0 && elem.IdTipoDocumento == 0}>
                                            <CallSplitIcon/>
                                    </Button>
                                }
                                </TableCell>
                                    <TableCell style={{width : '40px', padding: 0}}>
                                    { checkPermiso('EliminarDocumentosConsultados') &&
                                        <Button id='deleteDocumento' onClick={ () => deleteDocument(elem) }
                                        disabled={elem.IdDocument == 0 && elem.IdTipoDocumento == 0}
                                        >
                                                <DeleteIcon />
                                        </Button>
                                    }
                                </TableCell>
                                <TableCell style={{width : '40px', padding: 0}}>
                                { checkPermiso('AsignarPermisosaDocumento') &&
                                    <Button id='asigDocumento' onClick={ () => cambiarPermisosDocumento(elem) } 
                                    disabled={elem.IdDocument == 0 && elem.IdTipoDocumento == 0}
                                    >
                                            <LockIcon />
                                    </Button>
                                }
                                </TableCell>
                                <TableCell style={{width : '40px', padding: 0}} title={  translate('traducciones.titulos.ubicacion') + " : " + elem.IdNodePath }>
                                    { consultaDocumento != null ? (
                                        <Button id='verDocumento' onClick={() => consultaDocumento(elem) } 
                                        disabled={elem.IdDocument == 0 && elem.IdTipoDocumento == 0}
                                        >
                                        <VisibilityIcon />
                                        </Button>
                                        ) : null
                                    }
                                </TableCell> 
                                {/* <TableCell>
                                    { true ? (
                                    <div style={{color:"#5c9d66"}}>
                                        <b>Ubicación</b>
                                    </div>
                                    ): null }
                                    <div style={{minWidth: "max-content", minHeight: "1.8vh", marginRight:'-80px'}}>
                                    <div hidden>
                                        {pathElements = elem.IdNodePath.split("/")}
                                    </div>
                                    {pathElements.map((element, index) => (
                                    <div key={index}>
                                        {element}
                                        {index !== pathElements.length - 1 && <span>/</span>}
                                    </div>
                                    ))} */}
                                    {/* <div>{elem.IdNodePath}</div> */}
                                    {/* </div>
                                </TableCell>  */}
                                {   
                                    elem && elem.DatosDocumento.map(({ ValorLlave, NombreIndice, ValoresLlave},index) => {

                                    return  (                                                    
                                            <TableCell>
                                                { true ? (
                                                <div style={{color:"#30b165", minWidth: "max-content"}}>
                                                <b>{NombreIndice}</b>
                                                </div>
                                                ): null }
                                                <div style={{minWidth: "max-content", minHeight: "1.8vh"}}>
                                                { ValorLlave}
                                                { ValoresLlave && ValoresLlave.map((v,i) => {
                                                    return <div>
                                                        { v }
                                                    </div>
                                                })}
                                                </div>
                                            </TableCell>
                                        )
                                    }
                                    
                                    )
                                }
                               
                                
                            </TableRow>
                            )                
                        })
                    }
                </TableBody>
            </Table>
            <div className='row'>
                <div className='col-md-8' style={{
                    minHeight: '52px',
                    paddingLeft: '20px',
                    paddingTop: '10px',
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: '400',
                    lineHeight: '1.43',
                    letterSpacing: '0.01071em',
                    fontSize: '14px !important'
                }}>

                    { esBastanteo != null && dataSearch && dataSearch.data && dataSearch.data.length > 3 ? (
                        <Button 
                        style={{ backgroundColor: "#30b165", color : "white", padding: "3px 10px", marginRight: '20px'}}
                        component={Link}
                        title = {translate('traducciones.botones.consultar')}
                        onClick={() => {
                            history.push({
                                pathname: '/bastanteoSearch',
                                data: dataSearch.data
                            });
                        } } >
                            {translate('traducciones.botones.consultar')}
                        </Button>
                        ) : null
                    }

                        <div id='selecTodos'>
                            <Checkbox
                            style={{padding: "0px", marginBottom:'10px'}}
                            id='checkall'
                            name='checkall'
                                checked={todosSeleccionados}
                                onClick={handleSelectAll}
                            />
                            <span style={{marginLeft:'10px', color: '#30b165'}}><b>{translate('traducciones.campos.checkMarcar')}</b></span>
                        </div>
                        { hidePorcentage == false || hidePorcentage == 'false' ? (
                            <>
                               <span>{ translate('traducciones.titulos.porcentaje')}</span><strong>{dataSearch.porcentaje}%</strong>
                            </>
                        ): null}
                        { permisos && permisos.EXPORTAR ? (
                        <div style={{ padding: '10px', paddingLeft: '0px'}}>


                        <div>
                            <Button
                                id="basic-button"
                                style={{ backgroundColor: "#30b165", color : "white", padding: "3px 10px", marginRight: '20px'}}
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                            >
                                {translate('traducciones.botones.opcionesDescarga')}
                            </Button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={ () => { handleClose(); exportar(); } }><span>{translate('traducciones.botones.descargarSeleccionados')}</span></MenuItem>
                                {/* <MenuItem onClick={ () => { handleClose(); exportarAll(); } }>{translate('traducciones.botones.descargarSeleccionadosAll')}</MenuItem> */}
                                <MenuItem onClick={ () => { handleClose(); exportarCsv(); } }><span>{translate('traducciones.botones.descargarCsv')}</span></MenuItem>
                                <MenuItem onClick={ () => { handleClose(); exportarCsvAll(); } }><span>{translate('traducciones.botones.descargarCsvAll')}</span></MenuItem>
                            </Menu>
                            </div>

                       
                        </div>
                        ):null}
                </div>
                <div className='col-md-4'>
                    <Pagination perPage= {perPage} setPage= {setPage} setPerPage = {setPerPage} page={page} total= {dataSearch.total} style={{".RaPaginationActions-currentPageButton":{color: 'white',backgroundColor: '#5c9d66'}}}/>
                </div>
            </div>
        </Card>
        <Dialog
            fullWidth
            open={openEditUser}
            >
            <DialogTitle>{ translate('traducciones.modal.editarUsuariosDocumento') }</DialogTitle>
            <FormWithRedirect
            resource="nodos"
            save={handleSubmitEditUsers}
            render={({
                handleSubmitWithRedirect,
                pristine,
                saving
            }) => (
                <>
                <DialogContent>
                    {dataUsers ? (
                    <AutocompleteArrayInput options= { {fullWidth: true } }  optionText="Nombre" defaultValue={ dataUsersSeleccionados } optionValue="Nombre" source="usuarios" choices= { dataUsers ? dataUsers : null } style={{width:'100%'}} />
                    ) : null }
                    {dataUsers ? (
                    <AutocompleteArrayInput options= { {fullWidth: true } }  optionText="GrupoNombre" defaultValue={ dataGruposSeleccionados } optionValue="GrupoNombre" source="grupos" choices= { dataGrupos ? dataGrupos : null } style={{width:'100%'}} />
                    ) : null }
                </DialogContent>
                <DialogActions>
                    <SaveButton
                        label="ra.action.save"
                        handleSubmitWithRedirect={
                            handleSubmitWithRedirect
                        }
                        pristine={pristine}
                        saving={saving}
                    />
                    <Button onClick={handleCloseClick} >
                        { translate('ra.action.cancel')}
                    </Button>
                </DialogActions>
                </>
            )}
        />
        </Dialog>

        <Dialog
            fullWidth
            open={openEditDocument}
            >
            <DialogTitle>{ translate('traducciones.modal.ediarDatosDocumento') }</DialogTitle>
            <FormWithRedirect
            resource="nodos"
            save={handleSubmitUpdateDocument}
            render={({
                handleSubmitWithRedirect,
                pristine,
                saving
            }) => (
                <>
                <DialogContent>
                <div fullWidth>
                    <div className="row">
                        <div className="col-md-12">
                            { documentoEditar ? (
                                <div className="row">
                                    {   
                                    documentoEditar.DatosDocumento.map(
                                        (item) => {
                                            return (                                            
                                            <div className="col-md-6" hidden={item.IdIndice == 0}>
                                                 { item.Multiple ? (
                                                    <div>
                                                         <ArrayInput defaultValue={ item.ValoresLlaveObj } className="valor_iterator" label={ "Campos " + item.NombreIndice }  source={"multiple-" + item.IdIndice} style={{ width: "100%" }}>
                                                            <SimpleFormIterator>
                                                            { item.TipoIndice == 1 ? (<TextInput label={ "Campo " + item.NombreIndice }  source={"valor-" + item.IdIndice} /> ) : null }
                                                            { item.TipoIndice == 7 && item.ValoresTabla ? (
                                                                <SelectInput label={ "Campo " + item.NombreIndice } resettable={ true } validate={ item.Requerido ? [required()] : null} source={"valor-" + item.IdIndice}  choices={ item.ValoresTabla.map(value => ({id: value, name: value})) } />
                                                            ) : null }                                                            
                                                            { item.TipoIndice == 2 ? (<NumberInput  resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={"valor-" + item.IdIndice} />) : null }
                                                            { item.TipoIndice == 3 ? (<TextInput resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={"valor-" + item.IdIndice} />) : null }
                                                            { item.TipoIndice == 4 ? (<DateInput resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={"valor-" + item.IdIndice}  />) : null }
                                                            { item.TipoIndice == 5 ? (<TextInput type="time" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={"valor-" + item.IdIndice}  />) : null }
                                                            { item.TipoIndice == 6 ? (<TextInput type="email" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={"valor-" + item.IdIndice}  />) : null }
                                                            </SimpleFormIterator>
                                                         </ArrayInput>   
                                                    </div>        
                                                ) : (
                                                <div>
                                                { item.TipoIndice == 1 && !item.ValoresTabla ? (<TextInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } type="text" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice  }  />) : null }
                                                { item.TipoIndice == 7 && item.ValoresTabla ? (
                                                    <div>
                                                        <SelectInput style={{ width: "100%" }} name={ "campo-" + item.IdIndice + "" } defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  choices={ item.ValoresTabla.map(value => ({id: value, name: value})) } />
                                                    </div>
                                                ) : null }
                                                
                                                { item.TipoIndice == 2 ? (<NumberInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  />) : null }
                                                { item.TipoIndice == 3 ? (<TextInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } type="text" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  />) : null }
                                                { item.TipoIndice == 4 ? (<DateInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } name={ "campo-" + item.IdIndice + "]" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  />) : null }
                                                { item.TipoIndice == 5 ? (<TextInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } type="time" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  />) : null }
                                                { item.TipoIndice == 6 ? (<TextInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } type="email" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  />) : null }
                                                </div>
                                                ) }
                                            </div>
                                        )})
                                    }    
                                </div>
                                ) : null 
                            }
                        </div>
                    </div>
                    
                </div>
                </DialogContent>
                <DialogActions>
                    <SaveButton
                        label="ra.action.save"
                        handleSubmitWithRedirect={
                            handleSubmitWithRedirect
                        }
                        pristine={pristine}
                        saving={saving}
                    />
                    <Button onClick={handleCloseClick2} >
                        { translate('ra.action.cancel')}
                    </Button>
                </DialogActions>
                </>
            )}
        />
        </Dialog>
        <Dialog open={openSelectNode}>
            <DialogTitle>{ translate('traducciones.titulos.reasignarDocumento')}</DialogTitle>
            <DialogContent>
                <div style={{ padding: '10px', minWidth: "400px"}}>       
                    <Query type="getList" resource="nodos">
                        {({ data, loading, error }) => {
                            if (loading) { return <Loading />; }
                            if (error) { return <Error />; }
                            return (
                                <div  className="row">
                                    <div  className="col-md-12">
                                    {   data.map(
                                        (item) => (
                                            <div>
                                                <div className= { classes.nodo } > 
                                                    <FolderIcon style={{ marginRight: '5px', color : '#5c9d66'}} /> 
                                                    { item.TextoNodo } 
                                                    <Button className= { nodoActual == item.RutaNodo ? classes.oculto : "" } title={ translate('traducciones.titulos.reasignar')}  onClick={() => viewNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                                        <LocationSearchingIcon style={{ fontSize: '16px'}}/>
                                                    </Button>
                                                    { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                                                    <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                                        <ExpandLessIcon />
                                                    </Button>
                                                    }
                                                    { item.colapsado && item.hijos && item.hijos.length > 0 &&
                                                    <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                                        <ExpandMoreIcon />
                                                    </Button>
                                                    }
                                                </div>
                                                { !item.colapsado &&
                                                <div style={{ marginLeft: '-20px'}}>
                                                    { dibujaNodosHijos(item,classes,viewNode,translate,nodoActual,colapsar) }
                                                </div>
                                                }
                                            </div>
                                        ))
                                    }
                                    </div>                                        
                                </div>
                            );
                        }}
                    </Query>
                </div> 
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>setOpenSelectNode(false)} >
                    { translate('ra.action.close')}
                </Button>
            </DialogActions>
        </Dialog>
        <Dialog open={openDownCSV}>
            <DialogTitle>{translate('traducciones.stepsDownCsv.title')}</DialogTitle>
            <DialogContent>
                    <span>{translate('traducciones.stepsDownCsv.inputNumber')}</span>
                    <input type='number' className='form-control' onChange={(e)=>setNumResults(e.target.value)} value={numResults}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmitCSV}>
                    { translate('ra.action.confirm')}
                </Button>
                <Button onClick={()=>setOpenDownCSV(false)}>
                    { translate('ra.action.close')}
                </Button>
            </DialogActions>
        </Dialog>
        <Dialog open={openEspera}>
            <DialogContent>
                <div>
                <Loader/>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>setOpenEspera(false)}>
                    { translate('ra.action.close')}
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}

export default TableResultado;