import React from 'react'
import { useState } from 'react';
import swal from 'sweetalert';
import Loader2 from '../Components/Loader2';
import { useCheckAuth } from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import '../Libs/ExternalViewer/Resources/Styles/Loader2.css'
import MyFooter from '../Components/MyFooter';

const Identificadores = () => {

  const apiUrl = window.$parametros_generales.REACT_APP_BASE_URL;

  const [dataCurp, setDataCurp] = useState(null);
  const checkAuth = useCheckAuth();
  const [loading, setLoading] = useState(false)
  const [dataToGet, setDataToGet] = useState({
    Identificador_curp: '',
    GoRenapo: false
  })

  const [dataToSave, setDataToSave] = useState({
    Identificador: '',
    Curp: false,
    Nombres: '',
    ApellidoPaterno: '',
    ApellidoMaterno: '',
    ClaveSexo: '',
    Sexo: '',
    FechaNacimiento: '',
    Nacionalidad: '',
    ClaveEntidadNacimiento: '',
    EntidadNacimiento: '',
    ClaveDocProbatorio: '',
    DocProbatorio: '',
    ClaveStatusCurp: '',
    StatusCurp: '',
    AnioRegistro: '',
    ClaveEntidadEmisora: '',
    ClaveMunicipioRegistro: '',
    MunicipioRegistro: '',
    Foja: '',
    FolioCarta: '',
    Libro: '',
    NumActa: '',
    NumEntidadRegistrante: '',
    EntidadRegistrante: '',
    NumRegExtranjeros: '',
    Tomo: ''
  })

  const getCurpInformation = (e) =>{
    e.preventDefault()
    checkAuth()
    setLoading(true)
    setDataCurp(null)
    const url = apiUrl + 'Functions/RequestCurpInformation'
    
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', // Cambia el tipo de contenido según tus necesidades
        'Authorization': `Bearer ${localStorage.getItem('token')}`, // Agrega el token de autorización aquí
      },
      body: JSON.stringify(dataToGet), // data es el cuerpo de la solicitud en formato JSON
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Error de red o servidor');
        }
        return response.json(); // Si esperas una respuesta JSON del servidor
      })
      .then(data => {
        // Maneja la respuesta del servidor aquí
        // console.log('Respuesta del servidor:', data);
        setLoading(false)
        if(data.Success){
          if(data.Token != null){
            localStorage.setItem('token', data.Token)
            // console.log('Token actualizado');
          }
          if(data.Information != null){
            // console.log(data.Information);
            setDataCurp(data.Information)
          }
        }else{
          swal({
            icon: 'error',
            text: data.Message ? data.Message : 'No se encontrarón resultados'
          })
        }
      })
      .catch(error => {
        // Maneja los errores aquí
        console.error('Error:', error);
        setLoading(false)
      });
  }

  const setCurpInformation = (e) =>{
    e.preventDefault()
    checkAuth()
    const url = apiUrl + 'Functions/SetCurpInformation'
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', // Cambia el tipo de contenido según tus necesidades
        'Authorization': `Bearer ${localStorage.getItem('token')}`, // Agrega el token de autorización aquí
      },
      body: JSON.stringify(dataToSave), // data es el cuerpo de la solicitud en formato JSON
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Error de red o servidor');
        }
        return response.json(); // Si esperas una respuesta JSON del servidor
      })
      .then(data => {
        // Maneja la respuesta del servidor aquí
        // console.log('Respuesta del servidor:', data);
        if(data.Token != null){
          localStorage.setItem('token', data.Token)
          // console.log('Token actualizado');
        }
        if(data.Success){
          // console.log('Se ha creado');
          swal({
            icon:'success',
            text:'¡Guardado con éxito!',
          })
          // Restablece los valores de los campos de entrada
          resetFormSave()

        }else{
          swal({
            icon: 'info',
            text: data.Message ? data.Message : 'Lo sentimos, no se pudo guardar el identificador'
          })
        }
      })
      .catch(error => {
        // Maneja los errores aquí
        console.error('Error:', error);
      });
    
  }

  const handleFormGetChange = (e) => {
    const { id, value, type, checked } = e.target;

    // Manejar campos de entrada y casilla de verificación por separado
    setDataToGet((prevData) => ({
      ...prevData,
      [id]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleFormSetChange = (e) => {
    const { id, value, type, checked } = e.target;

    // Manejar campos de entrada y casilla de verificación por separado
    setDataToSave((prevData) => ({
      ...prevData,
      [id]: type === 'checkbox' ? checked : value.toUpperCase(),
    }));
  };

  function validarTexto(input) {
    // Expresión regular que solo permite letras (mayúsculas y minúsculas) y espacios.
    const regex = /^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]+$/;
    
    // Valor actual del campo de entrada.
    const valor = input.value;
  
    // Si el valor no coincide con la expresión regular, reemplázalo por un valor limpio.
    // if (!regex.test(valor)) {
    //   input.value = valor.replace(/[^A-Za-z\s]+/g, '');
    // }
  }

  const handleDelete = (userData) =>{
    //Se manda un aviso de confirmacion para la eliminación de los datos
    swal({
      text:'¿Esta seguro de eliminar los datos de ' +userData.Identificador+'?',
      buttons:{
        cancel: {
          text: 'Cancelar',
          value: false,
          visible: true,
          className: "bg-danger text-white",
          closeModal: true,
        },
        confirm: {
          text: 'Confirmar',
          value: true,
          visible: true,
          className: "bg-success text-white",
          closeModal: true
        }
      }
    }).then((res)=>{
      if(res){
        //Se hace la petición a la API para eliminar los datos
        deleteData(userData)
      }
    })
  }

  const deleteData = (userData) =>{
    const url = apiUrl + 'Functions/DeleteCurpInformation'
    // console.log('Información a eliminar -> ',userData);
    const dataToDelete = {
        Identificador_curp: userData.Identificador,
        GoRenapo: false
    }

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', // Cambia el tipo de contenido según tus necesidades
        'Authorization': `Bearer ${localStorage.getItem('token')}`, // Agrega el token de autorización aquí
      },
      body: JSON.stringify(dataToDelete), // data es el cuerpo de la solicitud en formato JSON
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Error de red o servidor');
        }
        // console.log(response);
        return response.json(); // Si esperas una respuesta JSON del servidor
      })
      .then(data => {
        // Maneja la respuesta del servidor aquí
        // console.log('Respuesta del servidor:', data);
        if(data.Success){
          if(data.Token != null){
            localStorage.setItem('token', data.Token)
            // console.log('Token actualizado');
          }
          swal({
            icon: 'success',
            text: '¡Información eliminada correctamente!'
          })
          resetFormGet()
        }
      })
      .catch(error => {
        // Maneja los errores aquí
        console.error('Error:', error);
      });
  }

  const resetFormGet = () =>{
    setDataCurp(null)
    setDataToGet({
      Identificador_curp: '',
      GoRenapo: false
    })
  }

  const resetFormSave = () =>{
    setDataToSave({
      Identificador: '',
      Curp: false,
      Nombres: '',
      ApellidoPaterno: '',
      ApellidoMaterno: '',
      ClaveSexo: '',
      Sexo: '',
      FechaNacimiento: '',
      Nacionalidad: '',
      ClaveEntidadNacimiento: '',
      EntidadNacimiento: '',
      ClaveDocProbatorio: '',
      DocProbatorio: '',
      ClaveStatusCurp: '',
      StatusCurp: '',
      AnioRegistro: '',
      ClaveEntidadEmisora: '',
      ClaveMunicipioRegistro: '',
      MunicipioRegistro: '',
      Foja: '',
      FolioCarta: '',
      Libro: '',
      NumActa: '',
      NumEntidadRegistrante: '',
      EntidadRegistrante: '',
      NumRegExtranjeros: '',
      Tomo: ''
    })
  }

  return (
    <div className='row'>
      <h4 className='mt-2'>Administración de Identificadores </h4>
      <hr/>
        {/* <h4>Consultar RFC</h4> */}
        <div className="row">
          <div className="col-md-4 mb-3">
            {/* <button className="btn btn-primary bg-success" style={{marginBottom:'10px'}}>Ingresar nuevo</button> */}
            <div className="card">
              <h5 className="card-header">Consultar CURP/Identificador</h5>
              <div className="card-body">
                <form onSubmit={getCurpInformation}>
                  <label>Identificador</label>
                  <input type="text" value={dataToGet.Identificador_curp.toUpperCase()} className="form-control" id="Identificador_curp" placeholder="Ingrese CURP o un identificador" required onChange={handleFormGetChange}/>
                  <input className="form-check-input" type="checkbox" id="GoRenapo" onChange={handleFormGetChange} value={dataToGet.GoRenapo}/> Buscar en RENAPO
                  <br/><br/><button className="btn" type="submit" disabled={loading} style={{backgroundColor:'#30b165', color:'white', float:'right'}}><span>Consultar </span><SearchIcon/></button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-8 mb-3">
            {dataCurp != null ? (
              <div>
                {dataCurp.Nombres && dataCurp.ApellidoPaterno && dataCurp.ApellidoMaterno ? <span style={{fontWeight:'bold'}}>¡Información recuperada con éxito!</span> : <span style={{fontWeight:'bold'}}>No se encontró información relacionada al identificador ingresado</span>}  
              <div className="row">
              <div className="col-md-4 mb-3">
                <label>Nombres</label>
                <input type="text" className="form-control" id="NombresQ" disabled value={dataCurp.Nombres ? dataCurp.Nombres : 'N/A'}/>
              </div>
            <div className="col-md-3 mb-3">
                <label>Apellido Paterno</label>
                <input type="text" className="form-control" id="ApellidoPaternoQ" value={dataCurp.ApellidoPaterno ? dataCurp.ApellidoPaterno : 'N/A'} disabled/>
              </div>
              <div className="col-md-3 mb-3">
                <label>Apellido Materno</label>
                <input type="text" className="form-control" id="ApellidoMaternoQ" value={dataCurp.ApellidoMaterno ? dataCurp.ApellidoMaterno : 'N/A'} disabled/>
              </div>
              {dataCurp.Nombres && dataCurp.ApellidoPaterno && dataCurp.ApellidoMaterno ? (
              <div className="col-md-3 mb-3">
                <button className="btn btn-primary bg-danger" type="button" style={{border:'1px solid'}} onClick={()=>handleDelete(dataCurp)}><span>Eliminar</span><DeleteIcon/></button>
              </div>
              ) : null }
            </div>
            </div>
            ): null }
            {loading ?(
              <div style={{marginTop:'7%'}}>
              <div>
                <center>
                  <Loader2/>
                  <span style={{color:'GrayText'}}>Consultando datos...</span>
                </center>
              </div>
            </div>
            ): null}
          </div>
        </div>
      <hr style={{marginTop:'10px'}}/>
      <form onSubmit={setCurpInformation}>
          <div className="row">
            <div className="col-md-12 mb-3">
            <div className="card">
              <h5 className="card-header">Ingresar nuevo CURP/Identificador</h5>
              <div className="card-body">
                <div className='row'>
                  <div className='col-md-3 mb-3'>
                    <label>Identificador</label>
                    <input type="text" className="form-control" id="Identificador" placeholder="Ingrese CURP o un identificador" onChange={handleFormSetChange} value={dataToSave.Identificador.toUpperCase()} required/>
                    <input className="form-check-input" style={{marginLeft:'3px'}} type="checkbox" id="Curp" onChange={handleFormSetChange} checked={dataToSave.Curp}/> ¿Ingresa un CURP?
                  </div>
                  <div className="col-md-3 mb-3">
                    <label>Nombres</label>
                    <input type="text" className="form-control" id="Nombres" placeholder="Ingrese nombre(s)" onChange={handleFormSetChange} value={dataToSave.Nombres.toUpperCase()} onInput={(e)=>validarTexto(e.target)} required/>
                  </div>
                <div className="col-md-3 mb-3">
                    <label>Apellido Paterno</label>
                    <input type="text" className="form-control" id="ApellidoPaterno" placeholder="Ingrese apellido paterno" onChange={handleFormSetChange} value={dataToSave.ApellidoPaterno.toUpperCase()} onInput={(e)=>validarTexto(e.target)} required/>
                  </div>
                  <div className="col-md-3 mb-3">
                    <label>Apellido Materno</label>
                    <input type="text" className="form-control" id="ApellidoMaterno" placeholder="Ingrese apellido materno" onChange={handleFormSetChange} value={dataToSave.ApellidoMaterno.toUpperCase()} onInput={(e)=>validarTexto(e.target)} required/>
                    <br/><button className="btn" type="submit" style={{float:'right', backgroundColor:'#30b165', color:'white'}}><span>Guardar </span><SaveIcon/></button>
                  </div>
                  {/* <div className="col-md-3 mb-3">
                    <button className="btn btn-primary bg-danger" type="button" style={{border:'1px solid'}} onClick={resetFormSave}>Limpiar formulario</button>
                  </div> */}
                </div>
              </div>
            </div>
            </div>
          </div>
        </form>
        {/* <hr style={{marginTop:'10px'}}/> */}
        <MyFooter/>
    </div>
  )
}

export default Identificadores