import React from 'react';
import { useState , useEffect } from 'react';
import {
    Datagrid,
    List,
    TextField,
    EditButton,
    useTranslate,
    Pagination,
    FormWithRedirect,
    useDataProvider,
    DeleteButton,
} from 'react-admin';
import withWidth from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import '../Libs/ExternalViewer/Resources/Styles/digitalizarStyles.css'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Dialog, DialogContent } from '@mui/material';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import '../Libs/ExternalViewer/Resources/Styles/predefinidasStyle.css'
import swal from 'sweetalert';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import HelpIcon from '@mui/icons-material/Help';
import { ComponentPredefinidas } from '../Components/ComponentPredefinidas';
import MyFooter from '../Components/MyFooter';

const useStyles = makeStyles({
    oculto : {
        visibility : "hidden"
    },
    nodo : {
        padding: '6px',
        border: '1px solid #319b4252',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '12px',
        width: 'max-content'
    },
    nodoSeleccionado :  {
        padding: '6px',
        border: '1px solid black',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '12px',
        backgroundColor: "#319b4257",
        color: '#277233'
    },
    noScroll: {
        "& .MuiDialog-paper": {
          overflow: "hidden",
        }
    },
    card: {
        width: '600px', 
        height: '300px', 
        margin: 'auto',
        marginTop: '50px',
    }
});

const ListPagination = props => <Pagination rowsPerPageOptions={[5,10, 25, 50, 100]} {...props} />;

const BusquedaPredefinida = ({ width,...props }) => {
    
    //Variables para control de Dialogs
    const [openNombre, setOpenNombre] = useState(false);
    const [dataUsers, setDataUsers] = useState(null);
    const [dataGrupos, setDataGrupos] = useState(null);
    const [dataNodos, setDataNodos] = useState([]);
    const [showGuide, setShowGuide] = useState(true)

    const translate = useTranslate();
    const dataProvider=useDataProvider();
    const inTour = JSON.parse(localStorage.getItem('activeTour'))

    const permisos = props.permissions ? props.permissions.ConsultaPredefinida : null; 
    const checkPermiso = (permiso) => {
        if(permisos){
            return permisos[permiso];
        }
        return false;
    }

    const getUsersAndGroups = () =>{
            if(dataUsers == null || dataGrupos == null){
                dataProvider.getList('usuarios', { filter:{} })
                .then(({ data }) => {
                    if(dataUsers == null){
                        setDataUsers(data);
                    }
                        dataProvider.getList('grupos', { filter:{} })
                        .then(({ data }) => {          
                            if(dataGrupos == null){
                                setDataGrupos(data);
                            }  
                        }).catch(error=>{
                        })
                })
                .catch(error => {
                });
            }
    }

    useEffect((getUsersAndGroups),[])

    let introHelp;
    introHelp = introJs('#root');
    introHelp.setOptions({
        disableInteraction: false,
        doneLabel: translate('traducciones.introBotones.doneLabel'),
        nextLabel: translate('traducciones.introBotones.nextLabel'),
        prevLabel: translate('traducciones.introBotones.prevLabel'),
        tooltipPosition:'top',
        steps: [
                {
                intro: translate('traducciones.introHelp.intro1'),
                title: translate('traducciones.introHelp.title1'),
                element: '#help'
                },
        ]                
    });

    const showHelp = () =>{
        const offPermisos = {
            querys: false,
            carga: false,
            admin: false,
            estructura: false,
        }
        if(document.cookie == 'introjs-dontShowAgain=true'){
            document.cookie = 'introjs-dontShowAgain =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
                setTimeout(()=>{
                    introHelp.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).oncomplete(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).onexit(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).start();                         
                },500)
        }
    }

    function crearCookie(nombre, valor, diasParaExpirar) {
    var fechaExpiracion = new Date();
    fechaExpiracion.setDate(fechaExpiracion.getDate() + diasParaExpirar);
    var cookie = nombre + '=' + valor + '; expires=' + fechaExpiracion.toUTCString() + '; path=/';
    document.cookie = cookie;
    }
    
    let introPredefinidas = introJs()
    useEffect(() => {
        if(inTour.querys){
            setTimeout(()=>{
                const showDelete = props.permissions.ConsultaPredefinida ? props.permissions.ConsultaPredefinida.EliminarConsultaPredefinida : null;
                const noQuerys = document.querySelectorAll('#play')
                if(noQuerys.length > 0){
                    if(showDelete){
                        introPredefinidas = introJs('#search-box');
                        introPredefinidas.setOptions({
                        disableInteraction: false,
                        doneLabel: translate('traducciones.introBotones.doneLabel'),
                        nextLabel: translate('traducciones.introBotones.nextLabel'),
                        prevLabel: translate('traducciones.introBotones.prevLabel'),
                        tooltipPosition:'top',
                        steps: [
                            {
                                intro: translate('traducciones.introPredefinidas.cintro1'),
                                title: translate('traducciones.introPredefinidas.ctitle1'),
                                element: '#container'
                            },
                            {
                                intro: translate('traducciones.introPredefinidas.cintro2'),
                                title: translate('traducciones.introPredefinidas.ctitle2'),
                                element: '#NameQuery'
                            },
                            {
                                intro: translate('traducciones.introPredefinidas.cintro3'),
                                title: translate('traducciones.introPredefinidas.ctitle3'),
                                element: '#delete'
                            },
                            {
                                intro: translate('traducciones.introPredefinidas.cintro4'),
                                title: translate('traducciones.introPredefinidas.ctitle4'),
                                element: '#play'
                            },
                        ]                                      
                      });        
                    }else{
                        introPredefinidas = introJs('#search-box');
                        introPredefinidas.setOptions({
                        disableInteraction: false,
                        doneLabel: translate('traducciones.introBotones.doneLabel'),
                        nextLabel: translate('traducciones.introBotones.nextLabel'),
                        prevLabel: translate('traducciones.introBotones.prevLabel'),
                        tooltipPosition:'top',
                        steps: [
                            {
                                intro: translate('traducciones.introPredefinidas.cintro1'),
                                title: translate('traducciones.introPredefinidas.ctitle1'),
                                element: '#container'
                            },
                            {
                                intro: translate('traducciones.introPredefinidas.cintro2'),
                                title: translate('traducciones.introPredefinidas.ctitle2'),
                                element: '#NameQuery'
                            },
                            {
                                intro: translate('traducciones.introPredefinidas.cintro4'),
                                title: translate('traducciones.introPredefinidas.ctitle4'),
                                element: '#play'
                            },
                        ]                                      
                      });
                    }
                }else{
                    introPredefinidas = introJs('#search-box');
                    introPredefinidas.setOptions({
                        disableInteraction: false,
                        doneLabel: translate('traducciones.introBotones.doneLabel'),
                        nextLabel: translate('traducciones.introBotones.nextLabel'),
                        prevLabel: translate('traducciones.introBotones.prevLabel'),
                        tooltipPosition:'top',
                        steps: [
                            {
                                intro: translate('traducciones.introPredefinidas.sintro1'),
                                title: translate('traducciones.introPredefinidas.stitle1'),
                                element: '#container'
                            },
                            {
                                intro: 'Imagina que a menudo realizas la misma búsqueda en Aiidara®. Para agilizar este proceso, puedes crear una consulta predefinida.',
                                title: '¿Como funcionan?',
                                element: '#container'
                            },
                            {
                                intro: 'Elige los nodos en los que deseas realizar la consulta. Por ejemplo, podrían ser “Clientes”, “Productos” o “Transacciones”.',
                                title: 'Selecciona nodos',
                                element: '#container'
                            },
                            {
                                intro: 'Los campos son las características o atributos que deseas consultar. Selecciona los campos relevantes para tu búsqueda. Por ejemplo, podrían ser “Nombre”, “Fecha” o “Categoría”.',
                                title: 'Campos de búsqueda',
                                element: '#container'
                            },
                            {
                                intro: 'Si hay campos que siempre tienen el mismo valor en tus consultas, puedes asignarles un valor fijo. Esto significa que no tendrás que ingresar ese valor cada vez que ejecutes la consulta.',
                                title: 'Valores fijos',
                                element: '#container'
                            },
                            {
                                intro: 'Decide quiénes tendrán acceso a esta consulta predefinida. <br/><br/>Puedes asignar permisos a usuarios específicos o grupos.',
                                title: 'Asignar permisos',
                                element: '#container'
                            },
                            {
                                intro: 'Ahora solo tienes que ejecutar la consulta predefinida y ahorrar tiempo en tus búsquedas habituales. 😊',
                                title: '¡Listo!',
                                element: '#container'
                            },
                            {
                                intro: translate('traducciones.introPredefinidas.sintro2'),
                                title: translate('traducciones.introPredefinidas.stitle2'),
                                element: '.button-38'
                            },
                        ]                    
                      });
                }
        
                introPredefinidas.setOption("dontShowAgain", true).oncomplete(()=>{
                    showHelp()
                }).onexit(()=>{showHelp()}).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).start();
            },1000)
        }
      }, []);
    
    let introCreacion = introJs()
    const showCreate = () => {
        introCreacion = introJs('.MuiDialog-container');
        introCreacion.setOptions({
            disableInteraction: false,
            doneLabel: translate('traducciones.introBotones.doneLabel'),
            nextLabel: translate('traducciones.introBotones.nextLabel'),
            prevLabel: translate('traducciones.introBotones.prevLabel'),
            steps: [
                {
                    element: '#dialogCreate',
                    intro: translate('traducciones.introCreate.intro1'),
                    title: translate('traducciones.introCreate.title1'),
                    position: 'left',
                    scrollTo: 'off'
                },
                {
                    element: '#NombreQuery',
                    intro: translate('traducciones.introCreate.intro2'),
                    title: translate('traducciones.introCreate.title2'),
                },
                {
                    element: '#operador',
                    intro: translate('traducciones.introCreate.intro3'),
                    title: translate('traducciones.introCreate.title3'),
                    position: 'bottom',
                    scrollTo: 'off'
                },
                {
                    element: '#camposSeleccionados',
                    intro: translate('traducciones.introCreate.intro6'),
                    title: translate('traducciones.introCreate.title6'),
                },
                {
                    element: '#btnAddCampo',
                    intro: translate('traducciones.introCreate.intro7'),
                    title: translate('traducciones.introCreate.title7'),
                    disableInteraction: true
                },
                {
                    element: '#permisosQuery',
                    intro: translate('traducciones.introCreate.intro8'),
                    title: translate('traducciones.introCreate.title8'),
                },
                {
                    element: '#acordion',
                    intro: '🚀 En esta sección, podrás habilitar funciones adicionales para personalizar aún más tus consultas predefinidas. <br/><br/>Exploraremos las siguientes opciones:',
                    title: 'Opciones avanzadas',
                    position: 'left',
                    scrollTo: 'off'
                },
                {
                    element: '#btnSave',
                    intro: translate('traducciones.introCreate.intro9'),
                    title: translate('traducciones.introCreate.title9'),
                    scrollTo: 'off'
                }                       
            ]                    
        })
        introCreacion.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')
        ).start()
    }

    let introAdvanced = introJs()
    const showAdvanced = () => {
        introAdvanced = introJs('.MuiDialog-container');
        introAdvanced.setOptions({
            disableInteraction: false,
            doneLabel: translate('traducciones.introBotones.doneLabel'),
            nextLabel: translate('traducciones.introBotones.nextLabel'),
            prevLabel: translate('traducciones.introBotones.prevLabel'),
            steps: [
                {
                    element: '#CheckInterseccion',
                    intro: translate('traducciones.introCreate.intro4'),
                    title: translate('traducciones.introCreate.title4'),
                    position: 'left',
                    scrollTo: 'off'
                },
                {
                    element: '#CheckComun',
                    intro: translate('traducciones.introCreate.intro5'),
                    title: translate('traducciones.introCreate.title5'),
                },
                {
                    element: '#CheckComparacion',
                    intro: 'Esta opción te permite realizar una comparación de los datos ingresados por cada nodo, con el fin de obtener resultados más completos',
                    title: 'Comparación entre nodos',
                },
                {
                    element: '#btnSave',
                    intro: translate('traducciones.introCreate.intro9'),
                    title: translate('traducciones.introCreate.title9'),
                    scrollTo: 'off'
                }                      
            ]                    
        })
        introAdvanced.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')
        ).start()
    }

    const handleOpenCreate = () =>{
        introPredefinidas.exit();
        setOpenNombre(true)
        // setTimeout(()=>{
        //     if(showGuide){
        //         showCreate();
        //     }
        // },1000)
    }
    
    let introSelectCampos = introJs();
    const showSelectCampos = () =>{
        introSelectCampos = introJs('#dialogAddCampos');     
        introSelectCampos.setOptions({
            disableInteraction: false,
            doneLabel: translate('traducciones.introBotones.doneLabel'),
            nextLabel: translate('traducciones.introBotones.nextLabel'),
            prevLabel: translate('traducciones.introBotones.prevLabel'),
            steps: [
                {
                    intro: translate('traducciones.introhandleOpen.intro1'),
                    title: translate('traducciones.introhandleOpen.title1'),
                    position: 'left',
                    scrollTo: 'off'
                },
                {
                    element: '#nodo',
                    intro: translate('traducciones.introhandleOpen.intro2'),
                    title: translate('traducciones.introhandleOpen.title2'),
                    scrollTo: 'off'
                },
                {
                    element: '#verCampos',
                    intro: translate('traducciones.introhandleOpen.intro3'),
                    title: translate('traducciones.introhandleOpen.title3'),
                    scrollTo: 'off'
                },
                {
                    intro: translate('traducciones.introhandleOpen.intro5'),
                    title: translate('traducciones.introhandleOpen.title5'),
                    scrollTo: 'off'
                },
                {
                    element: '#back',
                    intro: translate('traducciones.introhandleOpen.intro4'),
                    title: translate('traducciones.introhandleOpen.title4'),
                    scrollTo: 'off'
                },
            ]                    
        })
        introSelectCampos.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')
        ).start()
    }

    let introCampos = introJs();
    const showCampos = () =>{
        introCampos = introJs('#dialogAddCampos');
        introCampos.setOptions({
            disableInteraction: false,
            doneLabel: translate('traducciones.introBotones.doneLabel'),
            nextLabel: translate('traducciones.introBotones.nextLabel'),
            prevLabel: translate('traducciones.introBotones.prevLabel'),
            steps: [
                {
                    element: '#listCampos',
                    intro: 'Aqui encontraras los campos disponibles del nodo, estos seran los criterios para tu consulta, puedes seleccionar los que desees.',
                    title: 'Campos disponibles',
                    position: 'left',
                    // disableInteraction: true,
                    scrollTo: 'off'
                },
                {
                    element: '#btnAddCampos',
                    intro: 'Una vez que hayas marcado los campos que necesitas, dale clic al botón correspondiente para agregarlos a tu consulta.<br/><br/>Estos campos se convertirán en los parámetros de búsqueda que utilizarás para filtrar tus resultados.',
                    title: 'Agregar campos',
                    scrollTo:'off'
                },
                {
                    element: '#back',
                    intro: 'Después de agregar los campos, puedes regresar a la creación de tu consulta.<br/>Verás tus campos agregados, ordenados por su nodo correspondiente. Ahí podrás asignarles un valor fijo o eliminarlos de la búsqueda si es necesario.',
                    title: 'Regresar',
                    scrollTo:'off'
                },
            ]                    
        })
        introCampos.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')
        ).start()
    }

    let introValoresFijos = introJs()
    const showValoresFijos = () => {
        introValoresFijos = introJs('#dialogValorFijo');
        introValoresFijos.setOptions({
            disableInteraction: true,
            doneLabel: translate('traducciones.introBotones.doneLabel'),
            nextLabel: translate('traducciones.introBotones.nextLabel'),
            prevLabel: translate('traducciones.introBotones.prevLabel'),
            steps: [
                {
                    element: '#fieldsSelect',
                    intro: 'En esta sección, encontrarás los campos que has agregado específicamente de este nodo.<br/><br/>Estos campos actuarán como filtros para traer la información del nodo que coincida con los datos que buscas.',
                    title: 'Campos seleccionados del nodo',
                    scrollTo: 'off'
                },
                {
                    element: '#NombreIndice',
                    intro: 'Cada uno de los campos tiene un nombre específico que lo identifica.',
                    title: 'Nombre del campo',
                    scrollTo: 'off'
                },
                {
                    element: '#ValorFijo',
                    intro: 'Si al realizar la búsqueda siempre ingresas el mismo dato, puedes asignarle un valor fijo a cada campo.<br/><br/>Por ejemplo, si buscas “Clientes con compras recientes”, podrías asignar una fecha específica como “2023-03-01” para que la búsqueda siempre se realice hacia esa fecha.',
                    title: 'Asignar valor fijo',
                    scrollTo: 'off'
                },
                {
                    element: '#btnSaveCampo',
                    intro: translate('traducciones.introValorFijo.intro5'),
                    title: translate('traducciones.introValorFijo.title5'),
                    scrollTo: 'off'
                },                        
            ]                    
        })
        introValoresFijos.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')
        ).start()
    }
    
    // Función que valida los detalles de la consulta, crea la cadena logica y hace la petición al servicio para agregar la búsqueda
    const handleSubmit = (values) =>{
        let fields = [];
        let count=1;
        let cadena = '';
        const operator = values.operador;
        const comparador = values.comparador
        
        if(dataNodos.length > 0){
            dataNodos.map((nodo)=>{
                if(cadena != ''){
                    cadena = cadena + '|'
                }
                nodo.fields.map((d,index)=>{
                    if (operator != '>= <='){
                        if(d.fechaCreacion){
                            if(d.ValorFijo){
                                cadena = cadena + d.IdNodo + '¦' + operator + '¦' + 'Date' + count + '¦' + d.ValorFijo.trim() + (comparador ? comparador : '¦') 
                            }else{
                                cadena = cadena + d.IdNodo + '¦' + operator + '¦' + 'Date' + count + '¦' + (comparador ? comparador : '¦')
                                count++
                            }
                        }else{
                            if(d.ValorFijo){
                                cadena = cadena + d.IdNodo + '¦' + operator + '¦' + d.IdIndice + '¦' + d.ValorFijo.trim()  + (comparador ? (nodo.fields.length-1 == index ? '¦' : comparador) : '¦')
                            }else{
                                cadena = cadena + d.IdNodo + '¦' + operator + '¦' + d.IdIndice + '¦' + (comparador ? (nodo.fields.length-1 == index ? '' : comparador) : '')
                            }
                        }
                        fields.push(d)
                    }else{
                        if(d.fechaCreacion){
                            cadena = cadena + d.IdNodo + '>=,' + 'Date'+ count + '|' 
                            count++
                            cadena = cadena + d.IdNodo + '<=,' + 'Date'+ count + '|'
                            count++
                        }else{
                            cadena = cadena + d.IdNodo + '>=,' + d.IdIndice + '|' + d.IdNodo + '<=,' + d.IdIndice + '|'
                        }
                        fields.push(d)
                    }
                })
            })
            if(values.NombreQuery.trim() != ''){
    
                        // cadena = cadena.slice(0,-1)
    
                        let dataQuery={
                            IdQuery: 1,
                            NombreBusqueda: values.NombreQuery.trim(),
                            CadenaLogica: cadena,
                            Conector: values.CheckInterseccion,
                            CampoComun: values.CheckComun,
                            NombreCampoComun: values.CheckComun ? (values.NombreComun ? values.NombreComun : 'Campo común' ) : '' ,
                            FieldList: fields,
                            IdUsuarios: values.usuarios ? values.usuarios : [],
                            IdGrupos: values.grupos ? values.grupos : []
                        }
                        setShowGuide(false)
                        // dataProvider.create('predefinida',{ data:dataQuery })
                        // .then(({ data }) => {
                        //     setOpenNodos(false)
                        //     setOpenNombre(false)
                        //     refresh();
                        //     swal({
                        //         text:'Nueva consulta creada',
                        //         timer: 1200,
                        //         icon: 'success'
                        //     })
                        // }).catch((error)=>{
                        //     swal({
                        //         text: error.message,
                        //         timer: 1200,
                        //         icon: 'error'
                        //     })
                        // })
            }else{
                swal({
                    text:'Debe ingresar el nombre de la consulta',
                    icon:'info'
                })
            }
        }else{
            swal({
                text: 'Debe agregar al menos un campo a la consulta',
                icon:'info'
            })
        }
    }

    return(
        <div>
            {checkPermiso('AgregarConsultaPredefinida') && (
            <div>
            <button className="button-38" style={{width:'20em'}} onClick={handleOpenCreate}>
                <PlaylistAddIcon style={{marginRight:'5px'}}/>
                <span>{translate('traducciones.botones.crearPre')}</span>
            </button>            
            </div>
            )}
        <div style={{marginTop:'10px', minHeight:'57vh'}} id='container'>
        <div id='search-box'></div>
        <List
                {...props}
                title={translate('traducciones.titulos.busquedaPredefinida')}
                registro={ props.record} 
                bulkActionButtons={false}            
                actions = {null}
                pagination={<ListPagination />} >
                   
                    <Datagrid isRowSelectable={record => record.id < 1} >
                        <TextField source="IdQuery" label= "traducciones.campos.idQuery" />
                        <TextField source="NombreBusqueda" id='NameQuery' label= "traducciones.campos.nombreQuery"/>
                        <EditButton label="" icon={ <PlayArrowIcon /> } className="actionButton" id='play' title= { translate("traducciones.titulos.ejecutarConsulta") } />
                        {checkPermiso('EliminarConsultaPredefinida') && <DeleteButton label="" className="actionButton" undoable={false} id='delete' confirmTitle={translate('Eliminar consulta')} confirmContent={translate('¿Esta seguro que desea eliminar la consulta?')}/>}
                    </Datagrid>                
        </List>
            </div>                
                <Dialog id='dialogCreate' fullWidth open={openNombre}>
                    <DialogTitle>Nueva consulta predefinida<HelpIcon onClick={()=>{setShowGuide(true); showCreate();}} style={{float:'right',cursor: 'help'}}/></DialogTitle>
                        <FormWithRedirect 
                            resource="catalogos"
                            save={handleSubmit}
                            render={({
                                handleSubmitWithRedirect,
                                pristine,
                                saving
                            }) => (
                                <>
                                <DialogContent>                                    
                                  <ComponentPredefinidas dataUsers={dataUsers} dataGrupos={dataGrupos} setOpenNombre={setOpenNombre}/>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={()=>{
                                        setOpenNombre(false)
                                        }} >
                                        { translate('ra.action.cancel')}
                                    </Button>
                                </DialogActions>
                                </>
                            )}
                        />
                </Dialog>
            <MyFooter/>
        </div>
    )
}

export default withWidth()(BusquedaPredefinida);