import React from 'react';
import {
    Datagrid,
    List,
    TextField,
    TopToolbar,
    CreateButton,
    DeleteButton,
    EditButton,
    useRefresh,
    useRedirect,
    useNotify,
    useTranslate,
    Pagination,
    Button,
    useRecordContext,
    SimpleForm,
} from 'react-admin';
import UsuarioCreate from './UsuarioCreate';
import UsuarioEdit from './UsuarioEdit';
import { Route } from 'react-router';
import { Drawer } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import MobileUsuarioList from './Responsive/MobileUsuarioList';
import { ComponentPAY } from '../Components/ComponentPay'
import Container from '@mui/material/Container';
import PostFilter from '../Components/PostFilter';
import '../Libs/ExternalViewer/Resources/Styles/predefinidasStyle.css'
import GroupIcon from '@mui/icons-material/Group';
import { useState, useEffect } from 'react';
import { Chip, Dialog, DialogContent, DialogTitle, Toolbar } from '@mui/material';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import MyFooter from '../Components/MyFooter';

const UsuariosActions = ({ 
    basePath,
    checkPermiso
    }) => {   
    return (
    <TopToolbar>
        { checkPermiso('AgregarUsuarios') && <CreateButton id='createUser' basePath={basePath} label="traducciones.titulos.nuevoUsuario"  /> }
    </TopToolbar>
)};

const UsuariosList = ({ width,...props }) => {

    const permisos = props.permissions ? props.permissions.Usuarios : null;    
    const checkPermiso = (permiso) => {
        if(permisos){
            return permisos[permiso];
        }
        return false;
    }
    const inTour = JSON.parse(localStorage.getItem('activeTour'))

    const refresh = useRefresh();
    const redirect = useRedirect();
    const notify = useNotify();
    const [ groupsByUser, setGroupsByUser ] = useState({});
    const [ openGroups, setOpenGroups ] = useState(false)
    const translate = useTranslate();

    let introHelp;
    introHelp = introJs('#root');
    introHelp.setOptions({
        disableInteraction: false,
        doneLabel: translate('traducciones.introBotones.doneLabel'),
        nextLabel: translate('traducciones.introBotones.nextLabel'),
        prevLabel: translate('traducciones.introBotones.prevLabel'),
        tooltipPosition:'top',
        steps: [
                {
                intro: translate('traducciones.introHelp.intro1'),
                title: translate('traducciones.introHelp.title1'),
                element: '#help'
                },
        ]                
    });

    const showHelp = () =>{
        const offPermisos = {
            querys: false,
            carga: false,
            admin: false,
            estructura: false,
        }
        if(document.cookie == 'introjs-dontShowAgain=true'){
            document.cookie = 'introjs-dontShowAgain =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
                setTimeout(()=>{
                    introHelp.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).oncomplete(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).onexit(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).start();                         
                },500)
        }
    }

    function crearCookie(nombre, valor, diasParaExpirar) {
    var fechaExpiracion = new Date();
    fechaExpiracion.setDate(fechaExpiracion.getDate() + diasParaExpirar);
    var cookie = nombre + '=' + valor + '; expires=' + fechaExpiracion.toUTCString() + '; path=/';
    document.cookie = cookie;
    }

    const stepsToIntro = {
        usuarios:
        [
            {
              intro: translate('traducciones.introUsuariosList.intro1'),
              title: translate('traducciones.introUsuariosList.title1'),
              // element: '.MuiTable-root',
              scrollTo: 'off'
            },
            {
              intro: translate('traducciones.introUsuariosList.intro2'),
              title: translate('traducciones.introUsuariosList.title2'),
              element: '#nombre',
              scrollTo: 'off',
              position: 'bottom'
            },
            {
              intro: translate('traducciones.introUsuariosList.intro3'),
              title: translate('traducciones.introUsuariosList.title3'),
              element: "#rol",
              scrollTo: 'off',
              position: 'bottom'
            },
            {
              intro: translate('traducciones.introUsuariosList.intro4'),
              title: translate('traducciones.introUsuariosList.title4'),
              element: '#estadoUser',
              scrollTo: 'off',
              position: 'bottom'
            },
            {
              intro: translate('traducciones.introUsuariosList.intro5'),
              title: translate('traducciones.introUsuariosList.title5'),
              element: "#estadoPass",
              scrollTo: 'off',
              position: 'bottom'
            },
            {
              intro: translate('traducciones.introUsuariosList.intro6'),
              title: translate('traducciones.introUsuariosList.title6'),
              element: '#vigenciaPass',
              scrollTo: 'off',
              position: 'bottom'
            },
            {
              intro: translate('traducciones.introUsuariosList.intro7'),
              title: translate('traducciones.introUsuariosList.title7'),
              element: "#email",
              scrollTo: 'off',
              position: 'bottom'
            },
            {
              intro: translate('traducciones.introUsuariosList.intro8'),
              title: translate('traducciones.introUsuariosList.title8'),
              element: "#verGrupos",
              scrollTo: 'off',
              position: 'bottom'
            },
            {
              intro: translate('traducciones.introUsuariosList.intro9'),
              title: translate('traducciones.introUsuariosList.title9'),
              element: '#editUser',
              scrollTo: 'off',
              position: 'bottom'
            },
            {
              intro: translate('traducciones.introUsuariosList.intro10'),
              title: translate('traducciones.introUsuariosList.title10'),
              element: "#deleteUser",
              scrollTo: 'off',
              position: 'bottom'
            },
            {
              intro: translate('traducciones.introUsuariosList.intro11'),
              title: translate('traducciones.introUsuariosList.title11'),
              element: '#createUser',
              scrollTo: 'off',
              position: 'bottom',
              disableInteraction: false
            }
          ]          
    }

    let introDocuments = introJs();
    useEffect(() => {
        if(inTour.admin){
            setTimeout(()=>{
                introDocuments = introJs('#search-box');
                introDocuments.setOptions({
                    disableInteraction: true,
                    doneLabel: translate('traducciones.introBotones.doneLabel'),
                    nextLabel: translate('traducciones.introBotones.nextLabel'),
                    prevLabel: translate('traducciones.introBotones.prevLabel'),
                    tooltipPosition:'top',
                    steps: stepsToIntro.usuarios            
                  });
        
                introDocuments.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).oncomplete(()=>{
                    showHelp()
                }).onexit(()=>{
                    showHelp()
                }).start();
            },1000)
        }
      }, []);

    const ListPagination = props => <Pagination rowsPerPageOptions={[5,10, 25, 50, 100]} {...props} />;


    const handleClose = () => {
        props.history.push("/usuarios");
    };

    const onSuccess = () => {
        redirect('/usuarios');
        notify('traducciones.notificaciones.addUsuarioOk')
        refresh();
    };

    const onSuccessEdit = () => {
        redirect('/usuarios');
        notify('traducciones.notificaciones.editUsuarioOk')
        refresh();
    };

    const MembershipGroupsField = ({ record }) => {
        const membershipGroups = record.MembershipGroups || [];
        // console.log(membershipGroups);
        if(membershipGroups.length > 0 ){
            // return  <span>
            //             {membershipGroups.map((group, index) => (
            //             <React.Fragment key={index}>
            //                 {index > 0 && <br />} {/* Agrega un salto de línea antes de todos excepto el primero */}
            //                 {group}
            //             </React.Fragment>
            //             ))}
            //         </span>;
            return <span>{membershipGroups.join(', ')}</span>;
        }else{
            return <span>Sin grupo</span>
        }
    };
    
    const CustomButton = () => {
        const record = useRecordContext();
      
        const handleButtonClick = () => {
        //   console.log("Información de la fila:", record);
          setOpenGroups(true)
          setGroupsByUser(record)
        };
      
        return (
          <Button
            label=""
            className="actionButton"
            onClick={handleButtonClick}
            title='Ver grupos'
            id='verGrupos'
          >
            <GroupIcon />
          </Button>
        );
      };

    return (
        <div className='listaMovil'>   
            <div id='search-box'></div>

            {/*
            <Container  maxWidth="md" sx={{ mb: 3 }}>
                <ComponentPAY />
            </Container>
            */}
        <div style={{minHeight:'60vh'}}>
            <List
                {...props}
                title={translate('traducciones.titulos.gestionDeUsuarios')}
                actions = { <UsuariosActions checkPermiso = { checkPermiso } /> }
                sort={{ field: 'Nombre', order: 'ASC' }}
                registro={ props.record} 
                bulkActionButtons={false}
                pagination={<ListPagination />}
                filters={<PostFilter/>}
                >
                { width !== "xs" ? (    
            <Datagrid isRowSelectable={record => record.id < 1} >
                    <TextField id='nombre' source="Nombre" label= "traducciones.campos.nombre" />
                    <TextField id='rol' source="Rol" label= "traducciones.campos.rol" sortBy='IdTipo' />
                    <TextField id='estadoUser' source="NombreEstadoUsuario" label= "traducciones.campos.estadoUsuario" sortable={false} />
                    <TextField id='estadoPass' source="NombreEstadoPassword" label= "traducciones.campos.estadoPassword" sortBy='EstadoPassword'/>
                    <TextField id='vigenciaPass' source="Vigencia" label= "traducciones.campos.vigenciaPassword" />
                    <TextField id='email' source="Email" label= "traducciones.campos.email" />
                    <CustomButton />
                    { checkPermiso('EditarUsuarios') && <EditButton id='editUser' label="" className="actionButton" /> }
                    { checkPermiso('EliminarUsuarios') && <DeleteButton id="deleteUser" label="" className="actionButton" undoable={false} confirmTitle={translate('traducciones.modal.eliminarUsuario')} confirmContent={translate('traducciones.modal.eliminarUsuarioConfirm')}></DeleteButton> }
                </Datagrid>
                ) : (
                    <MobileUsuarioList checkPermiso = { checkPermiso } filters={<PostFilter/>}/>
                )
                }
            </List>
        </div>
        <Route
            path="/usuarios/create"
            action={introDocuments.exit()}
            render={() => (
                <Drawer anchor="right" open onClose={handleClose}>
                    <UsuarioCreate {...props} onCancel={handleClose} onSuccessOk= { onSuccess } />
                </Drawer>
            )}
         />
         <Route path="/usuarios/:id">
            {({ match }) => {
                const isMatch = match && match.params && match.params.id !== "create";
                return (
                <Drawer open={isMatch} anchor="right" onClose={handleClose}>
                    {isMatch ? (
                    <UsuarioEdit
                        id={match.params.id}
                        record={match.params}
                        onCancel={handleClose}
                        onSuccessOk= { onSuccessEdit }
                        {...props}
                    />
                    ) : null}
                </Drawer>
                );
            }}
        </Route>
        <Dialog open={openGroups}>
        <DialogTitle style={{textAlign:'center',fontSize:'16px'}}>Grupos</DialogTitle>
        <DialogContent>
            <SimpleForm toolbar = { 
                <Toolbar style={{float:'right', marginTop:'10px'}}>
                    <Button label="Cerrar" onClick={()=>setOpenGroups(false)}/>
                </Toolbar> } >
                    {groupsByUser.MembershipGroups && groupsByUser.MembershipGroups.length > 0 ? (
                        <>
                            <span style={{ fontSize: '15px' }}>
                            Estos son los grupos a los que <b>{groupsByUser.Nombre}</b> pertenece:
                            </span>
                            <div style={{ marginTop: '10px', display: 'flex', flexWrap: 'wrap', maxWidth: '450px' }}>
                            {groupsByUser.MembershipGroups.map((group, index) => (
                                <Chip key={index} label={group} style={{ marginRight: 5, marginBottom: 5, fontSize: '14px' }} />
                            ))}
                            </div>
                        </>
                        ) : (
                        <span style={{ fontSize:'15px' }}><b>{groupsByUser.Nombre}</b> no pertenece a ningún grupo</span>
                        )}
            </SimpleForm>
        </DialogContent>
    </Dialog> 
    <MyFooter/>
    </div> 
)};

export default withWidth()(UsuariosList);
