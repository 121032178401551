import React from 'react';
import { useState } from 'react';
import {
    useTranslate,
    Error, 
    Loading, 
    Query,
    useDataProvider,
    useRefresh,
    FormWithRedirect,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import '../Libs/ExternalViewer/Resources/Styles/digitalizarStyles.css'
import Button from '@material-ui/core/Button';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FolderIcon from '@material-ui/icons/Folder';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import '../Libs/ExternalViewer/Resources/Styles/predefinidasStyle.css'
import VisibilityIcon from '@material-ui/icons/Visibility';
import 'intro.js/introjs.css';
import BuscadorNodos from './BuscadorNodos';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

const useStyles = makeStyles({
    oculto : {
        visibility : "hidden"
    },
    nodo : {
        padding: '6px',
        border: '1px solid #30b165',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '12px',
        width: 'max-content'
    },
    nodoSeleccionado :  {
        padding: '6px',
        border: '1px solid black',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '12px',
        backgroundColor: "#30b165",
        color: '#277233'
    },
    noScroll: {
        "& .MuiDialog-paper": {
          overflow: "hidden",
        }
    },
    card: {
        width: '600px', 
        height: '300px', 
        margin: 'auto',
        marginTop: '50px',
    }
});

function SeleccionCampos({addCampos,setCampos,dataNodos,setnodoPadreElemento,setDataIndices, nodoPadreElemento, dataIndices, handleChangueCampos, fechaCreacion, openFields, setOpenFields}) {

    const [datanodosAbiertos, setDatanodosAbiertos] = useState([]);
    const [randomUpdate, setRandomUpdate] = useState(null);
    // const [nodoPadreElemento, setnodoPadreElemento] = useState(null);    
    const [nodos,setNodos] = useState(null);
    const translate = useTranslate();
    const dataProvider=useDataProvider();
    const refresh = useRefresh();
    const classes = useStyles();

    const viewNode = (nodoPadre) => {
        setCampos([])
        dataProvider.getList('indices', { IdNode: nodoPadre != null ? nodoPadre.RutaNodo : "/"})
            .then(({ data }) => {
                setnodoPadreElemento(nodoPadre);
                setDataIndices(data);
                setOpenFields(true)
            })
            .catch(error => {
        });   
    }

    function dibujaNodosHijos(nodo,classes,addNode,translate,nodoPadreElemento,colapsar,checknodoabierto){
        if(nodo.hijos != undefined){
            return (
                <div>
                    {nodo.hijos.map(
                    (item) => {
                        checknodoabierto(item);
                        return (
                        <div  style={{ marginLeft: '40px', marginTop : '-5px', width: 'max-content'}}>
                        <div style={{ float: 'left' }}>
                            <SubdirectoryArrowRightIcon style={{ fontSize: '30px' }}/>
                        </div>
                        <div className={ nodoPadreElemento != null && nodoPadreElemento.RutaNodo == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > <FolderIcon style={{ marginRight: '5px'}} />
                            { item.TextoNodo }
                            <Button title={ translate('traducciones.titulos.selectNodo')} onClick={() => addNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                <VisibilityIcon style={{ fontSize: '16px'}}  />
                            </Button>
                            { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                <ExpandLessIcon />
                            </Button>
                            }
                            { item.colapsado && item.hijos && item.hijos.length > 0 &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                <ExpandMoreIcon />
                            </Button>
                            }
                        </div>
                        { !item.colapsado &&
                        <div>
                            { dibujaNodosHijos(item,classes,addNode,translate,nodoPadreElemento,colapsar,checknodoabierto) }
                        </div>
                        }
                    </div>
                    )})
                    }
                </div>
            )
        }
    }

    const colapsar = (nodo) => {
        setRandomUpdate(Math.random());
        if(nodo.colapsado){
            datanodosAbiertos.push(nodo.RutaNodo)
            setDatanodosAbiertos(datanodosAbiertos);
        }else{
            setDatanodosAbiertos(datanodosAbiertos.filter(function(nodoguardado) { 
                return nodoguardado !== nodo.RutaNodo;
            }));
        }
        nodo.colapsado = !nodo.colapsado;
    }

    const checknodoabierto = (nodo) => {
        if(datanodosAbiertos.includes(nodo.RutaNodo)){
            nodo.colapsado = false;
        }
    }

    const desplegarNodos = (nodo) => {
        if (nodo.lvl) {
            let nivel = nodo[`n${nodo.lvl}`]
            const elementos = nivel.RutaNodo.split("/").filter((elemento) => elemento !== "");
            const arrayRuta = elementos.map((_, index) => ({
                RutaNodo: `/${elementos.slice(0, index + 1).join("/")}/`,
                colapsado: true,
            }));
            arrayRuta.map((e) => { colapsar(e) })
            // setOpenNodosEncontrados(false)
            viewNode(nivel)
            setnodoPadreElemento(nivel)
        }
    }

    const handleDataUpdated = (nuevaData) => {
        desplegarNodos(nuevaData)
        // setOpenNodosEncontrados(false)
    };

  return (
    <>
    <div id='contenido' className="row" style={{marginTop:'-20px'}}>
        <div className="col-md-12 col-md-custom"  style={{ overflowX: "auto", paddingLeft: "25px"}}>
            {/* <span style={{fontSize:'15px'}}>{translate('traducciones.modal.seleccionNodo')}: </span> */}
            <span style={{fontSize:'14px', fontWeight:'bold'}}>¡Comencemos seleccionando los campos que usaremos para realizar nuestra consulta! Sigue estos breves pasos:</span><br/>
            <span style={{fontSize:'14px'}}>1- Identifica dentro de tu estructura los tipos de documentos a los que deseas consultar.</span><br/>
            <span style={{fontSize:'14px'}}>2- Una vez identificados, revisa los campos disponibles en dichos documentos.</span><br/>
            <span style={{fontSize:'14px'}}>3- Selecciona los campos que necesitarás utilizar para tu consulta.</span><br/><br/>
            <span id='noCampos' style={{float:'right',fontSize:'14px', marginTop:'20px', backgroundColor: '#30b165', color:'white', padding:'8px',borderRadius:'10px'}}>Nodos seleccionados: <b>{dataNodos.length}</b> </span>
            <BuscadorNodos onDataUpdated={handleDataUpdated} data={nodos} nodoPadreElemento={nodoPadreElemento} viewNode={viewNode} colapsar={colapsar}/> 
            <Query type="getList" resource="nodos">
                {({ data, loading, error }) => {
                    setNodos(data)
                    if (loading) { return <Loading />; }
                    if (error) { return <Error />; }
                    return (
                        <div  className="row">
                            <div  className="col-md-12 col-md-custom"  style={{ overflowX: "auto", padding: "10px"}}>
                            {data.map(
                                (item) => {
                                checknodoabierto(item);
                                return (
                                    <div>
                                        <div id='nodo' className= { nodoPadreElemento != null && nodoPadreElemento.RutaNodo == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > 
                                            <FolderIcon style={{ marginRight: '5px', color : '#5c9d66'}} /> 
                                            { item.TextoNodo } 
                                            <Button id='verCampos' title={ translate('traducciones.titulos.selectNodo')}  onClick={() => viewNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                                <VisibilityIcon style={{ fontSize: '16px'}}/>
                                            </Button>
                                            { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                                <ExpandLessIcon />
                                            </Button>
                                            }
                                            { item.colapsado && item.hijos && item.hijos.length > 0 &&
                                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                                <ExpandMoreIcon />
                                            </Button>
                                            }
                                        </div>
                                        { !item.colapsado &&
                                        <div style={{ marginLeft: '-20px'}}>
                                            { dibujaNodosHijos(item,classes,viewNode,translate,nodoPadreElemento,colapsar,checknodoabierto) }
                                        </div>
                                        }
                                    </div>
                                )})
                            }
                            </div>
                            {/* { nodoPadreElemento != null ? (
                                <div className="col-md-4 col-md-custom">
                                    <div id='listCampos' style={{maxWidth:'fit-content'}}>
                                    <b><span>Campos de {nodoPadreElemento.TextoNodo}</span></b>
                                    {dataIndices.length>0 ? 
                                        dataIndices.map(item=>{
                                        return(
                                            <div className="checkbox-wrapper-16">
                                                <label className="checkbox-wrapper">
                                                    <input
                                                    type="checkbox"
                                                    className="checkbox-input"
                                                    onChange={(e)=>handleChangueCampos(e,item)}
                                                    defaultValue={false}
                                                    />
                                                    <span className="checkbox-tile">
                                                    <span className="checkbox-icon"></span>
                                                        <span className="checkbox-label">{item.NombreIndice}</span>
                                                    </span>
                                                </label>
                                            </div>
                                        )
                                    }):(
                                        <div style={{fontSize:'15px'}}>
                                            <span>No se encontraron campos</span>
                                        </div>
                                    )}
                                    {dataIndices.length >0 ?(
                                        <div className="checkbox-wrapper-16">
                                            <label className="checkbox-wrapper">
                                                <input
                                                type="checkbox"
                                                className="checkbox-input"
                                                onChange={(e)=>handleChangueCampos(e,fechaCreacion)}
                                                defaultValue={false}
                                                />
                                                <span className="checkbox-tile">
                                                <span className="checkbox-icon"></span>
                                                    <span className="checkbox-label">Fecha creación</span>
                                                </span>
                                            </label>
                                        </div>
                                    ):null}
                                    </div>
                                </div>
                            ) : null } */}
                            {/* {dataIndices.length >0 ? ( 
                            <div id='btnAddCampos' className="col-md-2 col-md-custom button-38" style={{height:'52px', marginTop:'20px'}} onClick={addCampos}>
                                <span>Agregar campo(s)</span>
                            </div>
                            ):(null)} */}
                        </div>
                    );
                }}
            </Query>
        </div>
    </div>
    <Dialog id='dialogValorFijo' maxWidth='xs' fullWidth open={openFields} onClose={() => setOpenFields(false)}>
            <DialogTitle>Campos de {nodoPadreElemento ? nodoPadreElemento.TextoNodo : ''}</DialogTitle>
            <FormWithRedirect 
            resource="catalogos"
            // save={handleSaveComunes}
            render={({
                handleSubmitWithRedirect,
                pristine,
                saving
            }) => (
                <>
                <DialogContent>
                    <>
                    {/* <center> */}
                    {/* <div className='row'> */}
                    { nodoPadreElemento != null ? (
                            <div className="">
                                <div id='listCampos' style={{maxWidth:'fit-content'}}>
                                {/* <b><span></span></b> */}
                                {dataIndices.length>0 ? 
                                    dataIndices.map(item=>{
                                    return(
                                        <div className="checkbox-wrapper-16">
                                            <label className="checkbox-wrapper">
                                                <input
                                                type="checkbox"
                                                className="checkbox-input"
                                                onChange={(e)=>handleChangueCampos(e,item)}
                                                defaultValue={false}
                                                />
                                                <span className="checkbox-tile">
                                                <span className="checkbox-icon"></span>
                                                    <span className="checkbox-label">{item.NombreIndice}</span>
                                                </span>
                                            </label>
                                        </div>
                                    )
                                }):(
                                    <div>
                                        <span>No se encontraron campos</span>
                                    </div>
                                )}
                                {dataIndices.length >0 ?(
                                    <div className="checkbox-wrapper-16">
                                        <label className="checkbox-wrapper">
                                            <input
                                            type="checkbox"
                                            className="checkbox-input"
                                            onChange={(e)=>handleChangueCampos(e,fechaCreacion)}
                                            defaultValue={false}
                                            />
                                            <span className="checkbox-tile">
                                            <span className="checkbox-icon"></span>
                                                <span className="checkbox-label">Fecha creación</span>
                                            </span>
                                        </label>
                                    </div>
                                ):null}
                                </div>
                            </div>
                        ) : null }
                        {/* </center> */}
                    {/* </div> */}
                    </>
                                
                </DialogContent>
                <DialogActions sx={{justifyContent:'space-around'}}>
                    {/* <SaveButton
                        id='btnSaveCampo'
                        label="ra.action.save"
                        handleSubmitWithRedirect={
                            handleSubmitWithRedirect
                        }
                        pristine={pristine}
                        saving={saving}
                    />*/}
                    <Button onClick={()=>{
                        setOpenFields(false)
                        }} >
                        { translate('ra.action.close')}
                    </Button> 
                    {dataIndices.length >0 ? ( 
                    <div id='btnAddCampos' className="button-38" style={{padding:'10px'}} onClick={addCampos}>
                        <span>Agregar campo(s)</span>
                    </div>
                    ):(null)}
                </DialogActions>
                </>
            )}
        />
    </Dialog>
    </>
  )
}

export default SeleccionCampos