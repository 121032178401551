import React from 'react';
import { useState, useEffect } from 'react';
import {
    Datagrid,
    List,
    TextField,
    EditButton,
    useTranslate,
    Pagination,
    DeleteButton,
    Loading, 
    Error, 
    useRefresh,
    Query, 
    Link,
    useDataProvider,
    ReferenceInput,
    AutocompleteInput,
    SimpleForm,
    SaveButton,
    Filter,
    SearchInput,
    ArrayInput,
    SimpleFormIterator,
    TextInput,
    SelectInput,
    NumberInput,
    DateInput,
    required,
    Toolbar
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FolderIcon from '@material-ui/icons/Folder';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import withWidth from '@material-ui/core/withWidth';
import MobileTempDocumentList from './Responsive/MobileTempDocumentList';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PostFilter from '../Components/PostFilter';
import '../Libs/ExternalViewer/Resources/Styles/predefinidasStyle.css'
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import EditIcon from '@mui/icons-material/Edit';
import DocumentPreview from '../Components/DocumentPreview';
import swal from 'sweetalert';
import MyFocusInput from '../Components/MyFocusInput';
import MyFooter from '../Components/MyFooter';

const useStyles = makeStyles({
    nodo : {
        padding: '6px',
        border: '1px solid #319b4252',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '12px',
        width : 'max-content'
    },
    nodoSeccionado :  {
        padding: '6px',
        border: '1px solid black',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '12px',
        backgroundColor: "#319b4257",
        color: '#277233'
    },
    oculto : {
        visibility : "hidden"
    }
});

function dibujaNodosHijos(nodo,classes,viewNode,translate,nodoActual,colapsar){
    if(nodo.hijos != undefined){
        return (
            <div>
                {nodo.hijos.map(
                (item) => (
                    <div  style={{ marginLeft: '40px', marginTop : '-5px'}}>
                        <div style={{ float: 'left' }}>
                            <SubdirectoryArrowRightIcon style={{ fontSize: '30px' }}/>
                        </div>
                        <div className={ nodoActual == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > <FolderIcon style={{ marginRight: '5px'}} />
                            { item.TextoNodo }
                            <Button className= { nodoActual == item.RutaNodo ? classes.oculto : "" }  title={ translate('traducciones.titulos.reasignar')} onClick={() => viewNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                <LocationSearchingIcon style={{ fontSize: '16px'}}  />
                            </Button>
                            { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                <ExpandLessIcon />
                            </Button>
                            }
                            { item.colapsado && item.hijos && item.hijos.length > 0 &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                <ExpandMoreIcon />
                            </Button>
                            }
                        </div>
                        { !item.colapsado &&
                        <div>
                            { dibujaNodosHijos(item,classes,viewNode,translate,nodoActual,colapsar) }
                        </div>
                        }
                    </div>
                ))
                }
            </div>
        )
    }
}



const TempDocumentList = ({ width,...props }) => {

    const permisos = props.permissions ? props.permissions.IndexacióndeDocumentos : null;    
    const checkPermiso = (permiso) => {
        if(permisos){
            return permisos[permiso];
        }
        return false;
    }

    const translate = useTranslate();
    const classes = useStyles();  
    const [openResult, setOpenResult] = useState(false);
    const [openModalUser, setOpenModalUser] = useState(false);    
    const refresh = useRefresh();
    const [dataDocument, setDataDocument] = useState(null);
    const [nodoActual, setNodoActual] = useState("");
    const dataProvider = useDataProvider();
    const inTour = JSON.parse(localStorage.getItem('activeTour'))

    const [randomUpdate, setRandomUpdate] = useState(null);
    const [openIndexar, setOpenIndexar] = useState(false)
    const [dataIndices, setDataIndices] = useState(null)
    const [dataDocumento, setDataDocumento] = useState(null) 
    const [cargando, setCargando] = useState(false)

    let introHelp;
    introHelp = introJs('#root');
    introHelp.setOptions({
        disableInteraction: false,
        doneLabel: translate('traducciones.introBotones.doneLabel'),
        nextLabel: translate('traducciones.introBotones.nextLabel'),
        prevLabel: translate('traducciones.introBotones.prevLabel'),
        tooltipPosition:'top',
        steps: [
                {
                intro: translate('traducciones.introHelp.intro1'),
                title: translate('traducciones.introHelp.title1'),
                element: '#help'
                },
        ]                
    });

    const showHelp = () =>{
        const offPermisos = {
            querys: false,
            carga: false,
            admin: false,
            estructura: false,
        }
        if(document.cookie == 'introjs-dontShowAgain=true'){
            document.cookie = 'introjs-dontShowAgain =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
                setTimeout(()=>{
                    introHelp.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).oncomplete(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).onexit(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).start();                         
                },500)
        }
    }

    function crearCookie(nombre, valor, diasParaExpirar) {
    var fechaExpiracion = new Date();
    fechaExpiracion.setDate(fechaExpiracion.getDate() + diasParaExpirar);
    var cookie = nombre + '=' + valor + '; expires=' + fechaExpiracion.toUTCString() + '; path=/';
    document.cookie = cookie;
    }

    const stepsToIntro = {
        conDocumentos:[
            {
                intro: translate('traducciones.introDocumentsTmp.intro1'),
                title: translate('traducciones.introDocumentsTmp.title1'),
                element: '.list-page',
                scrollTo:'off'
            },
            {
                intro: translate('traducciones.introDocumentsTmp.intro2'),
                title: translate('traducciones.introDocumentsTmp.title2'),
                element: '#userAsig',
                scrollTo:'off'
            },
            {
                intro: translate('traducciones.introDocumentsTmp.intro3'),
                title: translate('traducciones.introDocumentsTmp.title3'),
                element: "#fechaDoc",
                scrollTo:'off',
            },    
            {
                intro: translate('traducciones.introDocumentsTmp.intro4'),
                title: translate('traducciones.introDocumentsTmp.title4'),
                element: '#descDoc',
                scrollTo:'off'
            },
            {
                intro: translate('traducciones.introDocumentsTmp.intro5'),
                title: translate('traducciones.introDocumentsTmp.title5'),
                element: "#tipoDoc",
                scrollTo:'off',
            },   
            {
                intro: translate('traducciones.introDocumentsTmp.intro6'),
                title: translate('traducciones.introDocumentsTmp.title6'),
                element: '#btnIndexar',
                scrollTo:'off'
            },
            {
                intro: translate('traducciones.introDocumentsTmp.intro7'),
                title: translate('traducciones.introDocumentsTmp.title7'),
                element: "#btnNodo",
                scrollTo:'off',
            },  
            {
                intro: translate('traducciones.introDocumentsTmp.intro8'),
                title: translate('traducciones.introDocumentsTmp.title8'),
                element: "#btnUser",
                scrollTo:'off',
            },  
            {
                intro: translate('traducciones.introDocumentsTmp.intro9'),
                title: translate('traducciones.introDocumentsTmp.title9'),
                element: '#btnDelete',
                scrollTo:'off'
            }
        ],
        sinDocumentos:[
            {
                intro: translate('traducciones.introDocumentsTmp.intro10'),
                title: translate('traducciones.introDocumentsTmp.title10'),
                element: '.list-page',
                scrollTo:'off'
            },
            {
                intro: translate('traducciones.introDocumentsTmp.intro11'),
                title: translate('traducciones.introDocumentsTmp.title11'),
                element: "#indexar",
                scrollTo:'off',
                position:'right'
            },        
        ]
    }

    let introDocuments;
    useEffect(() => {
        if(inTour.carga){
            setTimeout(()=>{
                introDocuments = introJs();
                const noQuerys = document.querySelectorAll('#userAsig')
                introDocuments.setOptions({
                    disableInteraction: true,
                    doneLabel: translate('traducciones.introBotones.doneLabel'),
                    nextLabel: translate('traducciones.introBotones.nextLabel'),
                    prevLabel: translate('traducciones.introBotones.prevLabel'),
                    tooltipPosition:'top',
                    steps: noQuerys.length > 0 ? stepsToIntro.conDocumentos : stepsToIntro.sinDocumentos             
                  });
        
                introDocuments.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).oncomplete(()=>{
                    showHelp()
                }).onexit(()=>{
                    showHelp()
                }).start();
            },1000)
        }
      }, []);

    const handleCloseClick = () => {
        setOpenResult(false);
        setOpenModalUser(false);
    }    
    
    const viewNode = (nodoPadre) => {
        if(openIndexar){
            dataProvider.update('documentoTemporal', { id: dataDocument ? dataDocument.IdDocumentTmp : 0, IdNode: nodoPadre.RutaNodo })
            .then((data) => {
                dataProvider.getList('indices', { IdNode: nodoPadre.RutaNodo ? nodoPadre.RutaNodo : "/", Prediction: true, IdDocumentTmp: dataDocumento.IdDocumentTmp})
                    .then(({ data }) => {
                        swal({
                            icon: 'success',
                            text: 'El documento fue re-asignado al nuevo nodo'
                        })
                        setDataIndices(data);
                        dataProvider.getOne('documentos',{id: dataDocumento.IdDocumentTmp})
                        .then(({data})=>{
                            setDataDocumento(data.data)
                        }).catch((error)=>{
                            console.log(error);
                        })
                        setOpenResult(false);
                    })
                    .catch(error => {
                });  
            })
            .catch(error => {
            });   
        }else{
            dataProvider.update('documentoTemporal', { id: dataDocument ? dataDocument.IdDocumentTmp : 0, IdNode: nodoPadre.RutaNodo })
            .then(() => {
                setOpenResult(false);
                refresh();
            })
            .catch(error => {
            });
        }
    }

    const selectNode = (record) => {
        setDataDocument(record);
        setNodoActual(record.IdNode);
        setOpenResult(true);
    }

    const selectUser = (record) => {
        setDataDocument(record);
        setOpenModalUser(true);
    }

    const colapsar = (nodo) => {
        setRandomUpdate(Math.random());
        nodo.colapsado = !nodo.colapsado;
    }

    const handleAsignarUsuario = async values => {
        dataProvider.update('documentoTemporalUser', { idUser: values.user, IdDocumentTmp: dataDocument ? dataDocument.IdDocumentTmp : 0 })
        .then(() => {
            setOpenModalUser(false);
            refresh();
        })
        .catch(error => {
        }); 
    }

    const ListPagination = props => <Pagination rowsPerPageOptions={[5,10, 25, 50, 100]} {...props} />;

    const ChangeNodeButton = ({ record, ...props }) => (
        <Button
            id='btnNodo'
            style={{ backgroundColor: "#30b165", color : "white", padding: "3px 4px"}}
            component={Link}
            title = { props.title }
            onClick={ () => { selectNode(record) } }>
                <CallSplitIcon /> 
        </Button>
    );

    const ChangeUserButton = ({ record,...props  }) => (
        <Button
            id='btnUser'
            style={{ backgroundColor: "#30b165", color : "white", padding: "3px 4px"}}
            component={Link}
            title = { props.title }
            onClick={ () => { selectUser(record) } }>
                <SupervisorAccountIcon /> 
        </Button>
    );

    const IndexarDocumentButton = ({ record,...props  }) => (
        <Button
            // id='btnIndexar'
            style={{ backgroundColor: "#30b165", color : "white", padding: "3px 4px"}}
            // component={Link}
            title = { props.title }
            onClick={ () => { handleOpenDocument(record) } }
            disabled={cargando}>
                <EditIcon/> 
        </Button>
    );

    const handleOpenDocument = (record) =>{
        setOpenIndexar(true);
        setCargando(true)
        dataProvider.getOne('documentos',{id:record.id})
        .then(({data})=>{
            setDataDocumento(data.data)
            dataProvider.getList('indices', { IdNode: record.IdNode ? record.IdNode : "/", Prediction: true, IdDocumentTmp: record.id })
                .then(({ data }) => {
                    setDataIndices(data);
                    setCargando(false)
                })
                .catch(error => {
                    setCargando(false)
                    console.log(error);
            });  
        }).catch((error)=>{
            setCargando(false)
            setOpenIndexar(false)
            swal({
                icon: 'error',
                text: error.message.message
            })
        })
    }

    const handleSubmit = async values =>{
        setCargando(true)
        dataProvider.update('documentos',{data:values, previousData:{data:dataDocumento}})
        .then((data)=>{
            setOpenIndexar(false)
            setDataDocumento(null)
            setDataDocument(null)
            setCargando(false)
            swal({
                icon:'success',
                text: translate('traducciones.notificaciones.indexadoOk')
            })
            refresh();
        }).catch((error)=>{
            swal({
                icon:'error',
                text: error.message ? error.message : 'Ocurrió un error al guardar el documento'
            })
            setCargando(false)
        })
    }

    return (
    <div className='listaMovil'>
        <div id='container' style={{minHeight:'61vh'}}>
        <List
            {...props}
            title={translate('traducciones.titulos.gestionIndexar')}
            sort={{ field: 'IdDocumentTmp', order: 'ASC' }}
            registro={ props.record} 
            bulkActionButtons={false}            
            actions = {null}
            pagination={<ListPagination />} 
            filters={<PostFilter/>}
            >
                { width != "xs" ? (  
                <Datagrid isRowSelectable={record => record.id < 1} id='tabla'>
                    <TextField source="IdDocumentTmp" label= "traducciones.campos.numeroTemporal" />
                    <TextField source="AssignedUser" label= "traducciones.campos.usuarioDigitalizacion" sortable={false} id='userAsig' />
                    <TextField source="DateTime" label= "traducciones.campos.fechaDigitalizacion" id='fechaDoc'/>
                    <TextField source="Description" label= "traducciones.campos.descripcion" id='descDoc'/>
                    <TextField source="DocumentType" label= "traducciones.campos.tipoDocumento" sortable = { false } id='tipoDoc'/>
                    { checkPermiso('Indexar') &&<IndexarDocumentButton className="actionButton" title= { translate("traducciones.titulos.indexarDocumento") } />}
                    {/* { checkPermiso('Indexar') &&<EditButton id='btnIndexar' label="" className="actionButton" title= { translate("traducciones.titulos.indexarDocumento") } /> } */}
                    { checkPermiso('Indexar') &&<ChangeNodeButton title= { translate("traducciones.titulos.reasignarDocumento") } />     }
                    { checkPermiso('Indexar') &&<ChangeUserButton title= { translate("traducciones.titulos.reasignarUsuario") } /> }
                    { checkPermiso('EliminarDocumentosPendientesdeIndexar') &&<DeleteButton id='btnDelete' title={translate('traducciones.modal.eliminarDocumento')} label="" className="actionButton" undoable={false} confirmTitle={translate('traducciones.modal.eliminarDocumento')} confirmContent={translate('traducciones.modal.eliminarDocumentoConfirm')}></DeleteButton> }
                </Datagrid>
                ) : (
                    <MobileTempDocumentList selectNode = {selectNode} selectUser = { selectUser } checkPermiso = { checkPermiso } />
                )
            }
        </List>
        </div>    

        <Dialog open={openResult}>
            <DialogTitle>{ translate('traducciones.titulos.reasignarDocumento')}</DialogTitle>
            <DialogContent>
                <div style={{ padding: '10px', minWidth: "400px"}}>       
                    <Query type="getList" resource="nodos">
                        {({ data, loading, error }) => {
                            if (loading) { return <Loading />; }
                            if (error) { return <Error />; }
                            return (
                                <div  className="row">
                                    <div  className="col-md-12">
                                    {   data.map(
                                        (item) => (
                                            <div>
                                                <div className= { nodoActual == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > 
                                                    <FolderIcon style={{ marginRight: '5px', color : '#5c9d66'}} /> 
                                                    { item.TextoNodo } 
                                                    <Button className= { nodoActual == item.RutaNodo ? classes.oculto : "" } title={ translate('traducciones.titulos.reasignar')}  onClick={() => viewNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                                        <LocationSearchingIcon style={{ fontSize: '16px'}}/>
                                                    </Button>
                                                    { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                                                    <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                                        <ExpandLessIcon />
                                                    </Button>
                                                    }
                                                    { item.colapsado && item.hijos && item.hijos.length > 0 &&
                                                    <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                                        <ExpandMoreIcon />
                                                    </Button>
                                                    }
                                                </div>
                                                { !item.colapsado &&
                                                <div style={{ marginLeft: '-20px'}}>
                                                    { dibujaNodosHijos(item,classes,viewNode,translate,nodoActual,colapsar) }
                                                </div>
                                                }
                                            </div>
                                        ))
                                    }
                                    </div>                                        
                                </div>
                            );
                        }}
                    </Query>
                </div> 
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseClick} >
                    { translate('ra.action.close')}
                </Button>
            </DialogActions>
        </Dialog> 

        <Dialog open={openModalUser}>
            <DialogTitle>{ translate('traducciones.titulos.reasignarUsuario')}</DialogTitle>
            <DialogContent>
                <SimpleForm save={handleAsignarUsuario} toolbar = { <SaveButton label ="Re Asignar" />}>
                    <ReferenceInput label="traducciones.menu.usuarios" source="user" reference="usuarios" perPage={1000}>
                        <AutocompleteInput optionText="Nombre" optionValue="IdUsuario" />
                    </ReferenceInput> 
                </SimpleForm>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseClick} >
                    { translate('ra.action.close')}
                </Button>
            </DialogActions>
        </Dialog>
        <Dialog open={openIndexar} fullWidth>
            <DialogTitle>{translate('traducciones.titulos.indexarDocumento')}</DialogTitle>
            <DialogContent>
                {cargando ? <Loading/> : (
                    <>
                    <SimpleForm save={handleSubmit} fullWidth style={{width:'100%'}} toolbar={
                        <Toolbar>
                            <SaveButton disabled={cargando}></SaveButton>
                        </Toolbar>
                    }>
                        <div className='row' style={{width:'100%'}}>
                            <div className='col-md-6'>
                                <DocumentPreview document={dataDocumento}/>
                            </div>
                            <div className='col-md-6'>
                            <div style={{
                                    textAlign: 'center',  padding: '5px',
                                    backgroundColor: '#849e88',
                                    color: 'white',
                                    fontSize: '16px'
                                }}>
                                <ChangeNodeButton record={ dataDocumento } title= { translate("traducciones.titulos.reasignarDocumento") } />    
                                <b>{translate('traducciones.titulos.ubicacion')} : </b> {dataDocumento != null ? dataDocumento.IdNodePath : ''}  
                            </div>
                            { dataIndices ? (
                                    <div className="row" style={{alignItems:'flex-end'}}>
                                        {   dataIndices.map(
                                            (item) => {
                                                return (
                                                <div className="col-md-6">
                                                    { item.Multiple ? (
                                                        <div>
                                                            <ArrayInput className="valor_iterator" label={ "Campos " + item.NombreIndice }  source={"multiple-" + item.IdIndice} style={{ width: "100%" }} defaultValue={['']}>
                                                                <SimpleFormIterator>
                                                                { item.Tipo == 1 ? (<TextInput label={ "Campo " + item.NombreIndice }  source={"valor-" + item.IdIndice} /> ) : null }
                                                                { item.Tipo == 7 && item.ValoresTabla ? (
                                                                    <SelectInput label={ "Campo " + item.NombreIndice } resettable={ true } validate={ item.Requerido ? [required()] : null} source={"valor-" + item.IdIndice}  choices={ item.ValoresTabla.map(value => ({id: value, name: value})) } defaultValue='' />
                                                                ) : null }                                                            
                                                                { item.Tipo == 2 ? (<NumberInput  resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={"valor-" + item.IdIndice} />) : null }
                                                                { item.Tipo == 3 ? (<TextInput resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={"valor-" + item.IdIndice} />) : null }
                                                                { item.Tipo == 4 ? (<DateInput resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={"valor-" + item.IdIndice}  />) : null }
                                                                { item.Tipo == 5 ? (<TextInput type="time" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={"valor-" + item.IdIndice}  />) : null }
                                                                { item.Tipo == 6 ? (<TextInput type="email" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={"valor-" + item.IdIndice}  />) : null }
                                                                </SimpleFormIterator>
                                                            </ArrayInput>   
                                                        </div>        
                                                    ) : item.Enmascarar ? (
                                                        <MyFocusInput item={item} />
                                                    ) : (
                                                    <div>       

                                                    { item.Tipo == 1 ? (<TextInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } type="text" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice  }  />) : null }

                                                    { item.Tipo == 7 && item.ValoresTabla ? (
                                                        <div>
                                                            <SelectInput style={{ width: "100%" }} name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  choices={ item.ValoresTabla.map(value => ({id: value, name: value})) } defaultValue=''/>
                                                        </div>
                                                    ) : null }

                                                    { item.Tipo == 2 ? (<NumberInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  />) : null }
                                                    { item.Tipo == 3 ? (<TextInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } type="text" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  />) : null }
                                                    { item.Tipo == 4 ? (<DateInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } name={ "campo-" + item.IdIndice + "]" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  />) : null }
                                                    { item.Tipo == 5 ? (<TextInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } type="time" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  />) : null }
                                                    { item.Tipo == 6 ? (<TextInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } type="email" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  />) : null }
                                                    </div>
                                                    )}
                                                </div>
                                            )})
                                        }    
                                    </div>
                                    ) : <Loading/>
                                }
                            </div>
                        </div>
                    </SimpleForm>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>{setOpenIndexar(false); refresh()}} >
                    { translate('ra.action.close')}
                </Button>
            </DialogActions>
        </Dialog>  

        <MyFooter/>
    </div> 
)};

export default withWidth()(TempDocumentList);
