import React, { useEffect } from 'react';
import { bearer_Token, getConek } from '../helpers/helpers'

export function Checkout({ onSubmit , checkout }) {
useEffect(async() => {
    window.ConektaCheckoutComponents.Card({
      targetIFrame: "#conektaIframeContainer",
      allowTokenization: true,
      checkoutRequestId: `${checkout}`,
      publicKey: await getConek(),
      locale: 'es',
      options: {
        styles: {
          inputType: 'rounded',
          buttonType: 'rounded',
          states: {
            empty: {
              borderColor: '#FFAA00'
            },
            invalid: {
              borderColor: '#FF00E0'
            },
            valid: {
              borderColor: '#0079c1'
            }
          }
        },
        button: {
          colorText: '#ffffff',
          backgroundColor: '#301007'
        },
        iframe: {
          colorText: '#65A39B',
          backgroundColor: '#FFFFFF'
        }
      },
      onCreateTokenSucceeded: function(token) {
        onSubmit(token);
      },
      onCreateTokenError: function(error) {
        console.log(error);
      }
    });
}, []);

  return (
    <div id="conektaIframeContainer" style={{ height: '450px' }}></div>
  );
}

export default Checkout;
