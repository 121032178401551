import * as React from 'react';
import {
    Datagrid,
    List,
    TextField,
    BooleanField,
    TopToolbar,
    CreateButton,
    DeleteButton,    
    useRefresh,
    useRedirect,
    EditButton,
    useNotify,
    useTranslate,
    Pagination,
    useDataProvider
} from 'react-admin';
import { Route } from 'react-router';
import { Drawer } from '@material-ui/core';
import GrupoCreate from './GrupoCreate';
import GrupoEdit from './GrupoEdit';
import withWidth from '@material-ui/core/withWidth';
import MobileGrupoList from './Responsive/MobileGrupoList';
import { useState,useEffect } from 'react';
import swal from 'sweetalert';
import PostFilter from '../Components/PostFilter';
import '../Libs/ExternalViewer/Resources/Styles/predefinidasStyle.css'
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import MyFooter from '../Components/MyFooter';

const GruposActions = ({ 
    basePath,
    checkPermiso
    }) => {        
    return (
    <TopToolbar>
        { checkPermiso('AgregarGrupos') && <CreateButton id='createGrupo' basePath={basePath} label="traducciones.titulos.nuevoGrupo"  /> }
    </TopToolbar>
)};

const GruposList = ({ width,...props }) => {

    const permisos = props.permissions ? props.permissions.Grupos : null;    
    const checkPermiso = (permiso) => {
        if(permisos){
            return permisos[permiso];
        }
        return false;
    }
    const inTour = JSON.parse(localStorage.getItem('activeTour'))

    useEffect(()=>{
        dataProvider.getList('usuarios', { filter:{} })
        .then(({ data }) => {
            setDataUsers(data)
        })
    },[])

    const refresh = useRefresh();
    const redirect = useRedirect();
    const notify = useNotify();
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const [dataUsers,setDataUsers] = useState([])

    const handleClose = () => {
        props.history.push("/grupos");
    };

    let introHelp;
    introHelp = introJs('#root');
    introHelp.setOptions({
        disableInteraction: false,
        doneLabel: translate('traducciones.introBotones.doneLabel'),
        nextLabel: translate('traducciones.introBotones.nextLabel'),
        prevLabel: translate('traducciones.introBotones.prevLabel'),
        tooltipPosition:'top',
        steps: [
                {
                intro: translate('traducciones.introHelp.intro1'),
                title: translate('traducciones.introHelp.title1'),
                element: '#help'
                },
        ]                
    });

    const showHelp = () =>{
        const offPermisos = {
            querys: false,
            carga: false,
            admin: false,
            estructura: false,
        }
        if(document.cookie == 'introjs-dontShowAgain=true'){
            document.cookie = 'introjs-dontShowAgain =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
                setTimeout(()=>{
                    introHelp.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).oncomplete(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).onexit(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).start();                         
                },500)
        }
    }

    function crearCookie(nombre, valor, diasParaExpirar) {
    var fechaExpiracion = new Date();
    fechaExpiracion.setDate(fechaExpiracion.getDate() + diasParaExpirar);
    var cookie = nombre + '=' + valor + '; expires=' + fechaExpiracion.toUTCString() + '; path=/';
    document.cookie = cookie;
    }

    const stepsToIntro = {
        conGrupos:
        [
            {
                intro: translate('traducciones.introGrupoList.cintro1'),
                title: translate('traducciones.introGrupoList.ctitle1'),
                element: '.listaMovil',
                scrollTo: 'off'
            },
            {
                intro: translate('traducciones.introGrupoList.cintro2'),
                title: translate('traducciones.introGrupoList.ctitle2'),
                element: ".MuiTableRow-hover",
                scrollTo: 'off',
                position: 'bottom'
            },
            // {
            //     intro: 'En esta sección, encontrarás el nombre del grupo. 👥',
            //     title: 'Nombre de grupo',
            //     element: '#nombreGrupo',
            //     scrollTo: 'off',
            //     position: 'bottom'
            // },
            // {
            //     intro: 'Aqui veras si el grupo tiene permiso de anotaciones activo o desactivado',
            //     title: 'Anotaciones',
            //     element: '#anotaciones',
            //     scrollTo: 'off',
            //     position: 'bottom'
            // },
            // {
            //     intro: 'En esta sección, encontrarás el nombre del grupo. 👥',
            //     title: 'Imprimir',
            //     element: '#imprimir',
            //     scrollTo: 'off',
            //     position: 'bottom'
            // },
            // {
            //     intro: 'Aqui veras si el grupo tiene permiso de anotaciones activo o desactivado',
            //     title: 'Exportar',
            //     element: '#exportar',
            //     scrollTo: 'off',
            //     position: 'bottom'
            // },
            // {
            //     intro: 'En esta sección, encontrarás el nombre del grupo. 👥',
            //     title: 'Importar',
            //     element: '#importar',
            //     scrollTo: 'off',
            //     position: 'bottom'
            // },
            // {
            //     intro: 'En esta sección, encontrarás el nombre del grupo. 👥',
            //     title: 'Digitalizar',
            //     element: '#digitalizar',
            //     scrollTo: 'off',
            //     position: 'bottom'
            // },
            // {
            //     intro: 'Aqui veras si el grupo tiene permiso de anotaciones activo o desactivado',
            //     title: 'Enviar',
            //     element: '#enviar',
            //     scrollTo: 'off',
            //     position: 'bottom'
            // },
            // {
            //     intro: 'En esta sección, encontrarás el nombre del grupo. 👥',
            //     title: 'Marca de agua',
            //     element: '#marca_agua',
            //     scrollTo: 'off',
            //     position: 'bottom'
            // },
            // {
            //     intro: 'Aqui veras si el grupo tiene permiso de anotaciones activo o desactivado',
            //     title: 'General',
            //     element: '#general',
            //     scrollTo: 'off',
            //     position: 'bottom'
            // },
            // {
            //     intro: 'En esta sección, encontrarás el nombre del grupo. 👥',
            //     title: 'Navegar',
            //     element: '#navegar',
            //     scrollTo: 'off',
            //     position: 'bottom'
            // },  
            // {
            //     intro: 'Aqui veras si el grupo tiene permiso de anotaciones activo o desactivado',
            //     title: 'Por campos',
            //     element: '#campos',
            //     scrollTo: 'off',
            //     position: 'bottom'
            // },
            // {
            //     intro: 'En esta sección, encontrarás el nombre del grupo. 👥',
            //     title: 'Por contenido',
            //     element: '#contenido',
            //     scrollTo: 'off',
            //     position: 'bottom'
            // },  
            {
                intro: translate('traducciones.introGrupoList.cintro3'),
                title: translate('traducciones.introGrupoList.ctitle3'),
                element: "#btnEdit",
                scrollTo: 'off',
                position: 'bottom'
            },
            {
                intro: translate('traducciones.introGrupoList.cintro4'),
                title: translate('traducciones.introGrupoList.ctitle4'),
                element: '#btnDelete',
                scrollTo: 'off',
                position: 'bottom'
            },
            {
                intro: translate('traducciones.introGrupoList.cintro5'),
                title: translate('traducciones.introGrupoList.ctitle5'),
                element: '#createGrupo',
                scrollTo: 'off',
                position: 'bottom',
                disableInteraction: false
            }            
          ],
          sinGrupos:[
            {
                intro: translate('traducciones.introGrupoList.sintro1'),
                title: translate('traducciones.introGrupoList.stitle1'),
                element: '#content',
                scrollTo: 'off',
            },
            {
                intro: translate('traducciones.introGrupoList.sintro2'),
                title: translate('traducciones.introGrupoList.stitle2'),
                element: '#steps',
                scrollTo: 'off',
            },
            {
                intro: translate('traducciones.introGrupoList.sintro3'),
                title: translate('traducciones.introGrupoList.stitle3'),
                element: "#createFirst",
                scrollTo: 'off',
                position: 'bottom',
                disableInteraction: false
            },
          ]       
    }

    let introDocuments = introJs();
    useEffect(() => {
        if(inTour.admin){
            setTimeout(()=>{
                introDocuments = introJs();
                const noGroups = document.querySelectorAll('#nombreGrupo')
                introDocuments.setOptions({
                    disableInteraction: true,
                    doneLabel: translate('traducciones.introBotones.doneLabel'),
                    nextLabel: translate('traducciones.introBotones.nextLabel'),
                    prevLabel: translate('traducciones.introBotones.prevLabel'),
                    tooltipPosition:'top',
                    steps: noGroups.length > 0 ? stepsToIntro.conGrupos : stepsToIntro.sinGrupos           
                  });
        
                introDocuments.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).oncomplete(()=>{
                    showHelp()
                }).onexit(()=>{
                    showHelp()
                }).start();
            },800)
        }
      }, []);

    const onSuccess = ({ data }) => {
        redirect('/grupos');
        // notify('traducciones.notificaciones.addGrupoOk')
        swal({
            icon:'success',
            text: translate('traducciones.notificaciones.addGrupoOk')
        })
        refresh();
    };

    const onSuccessEdit = ({ data }) => {
        redirect('/grupos');
        // notify('traducciones.notificaciones.editGrupoOk')
        swal({
            icon:'success',
            text: translate('traducciones.notificaciones.editGrupoOk')
        })
        refresh();
    };

    const Empty = () => (
        <div id='content' style={{ textAlign: 'center', margin: '15px', backgroundColor: 'white', paddingBottom: '30px'}}>
            <p></p>
            <img src={"grupo.png"} style={{width: '15vw'}} />
            <p>            
            { translate('traducciones.generales.gruposTexto1') }
            </p>
            <p>
            { translate('traducciones.generales.gruposTexto2') }
            </p>
            <div id='steps'>
                <p>
                <b>{ translate('traducciones.generales.step1') }</b> { translate('traducciones.generales.grupostep1') }
                </p>
                <p>
                <b>{ translate('traducciones.generales.step2') }</b> { translate('traducciones.generales.grupostep2') }
                </p>
                <p>
                <b>{ translate('traducciones.generales.step3') }</b> { translate('traducciones.generales.grupostep3') }   
                </p>
            </div>
            <p></p>
            { checkPermiso('AgregarGrupos') && <CreateButton id='createFirst' label='traducciones.titulos.nuevoGrupo'></CreateButton> }
        </div>
    );

    const ListPagination = props => <Pagination rowsPerPageOptions={[5,10, 25, 50, 100]} {...props} />;

    // const PostFilter = (props) => (
    //     <Filter {...props}>
    //       <SearchInput source="q" alwaysOn style={{marginLeft:'2px',marginBottom:'10px'}}/>
    //     </Filter>
    // );

    return (
        <div className='listaMovil'>
            <div style={{minHeight:'60vh'}}>
                <List
                    {...props}
                    title={translate('traducciones.titulos.gestionDeGrupos')}
                    actions = { <GruposActions checkPermiso= { checkPermiso } /> }
                    sort={{ field: 'GrupoNombre', order: 'ASC' }}
                    registro={ props.record} 
                    empty= { <Empty /> }
                    bulkActionButtons={false}
                    pagination={<ListPagination />}
                    filters={<PostFilter/>}
                    >
                { width != "xs" ? (   
                <Datagrid isRowSelectable={record => record.id < 1}>
                        <TextField id='nombreGrupo' source="GrupoNombre" label= "traducciones.campos.nombreDeGrupo" />
                        <BooleanField  id='anotaciones' source="Permissions.Anotaciones" label= "traducciones.permisos.anotaciones" sortable={false}/>
                        <BooleanField  id='imprimir' source="Permissions.Imprimir" label= "traducciones.permisos.imprimir" sortable={false}/>
                        <BooleanField  id='exportar' source="Permissions.Exportar" label= "traducciones.permisos.exportar" sortable={false}/>
                        <BooleanField  id='importar' source="Permissions.Importar" label= "traducciones.permisos.importar" sortable={false}/>
                        <BooleanField  id='digitalizar' source="Permissions.Digitalizar" label= "traducciones.permisos.digitalizar" sortable={false}/>
                        <BooleanField  id='enviar' source="Permissions.Enviar" label= "traducciones.permisos.enviar" sortable={false}/>
                        <BooleanField  id='marca_agua' source="Permissions.Sin_marca_de_agua" label= "traducciones.permisos.sinmarcaagua" sortable={false}/>

                        <BooleanField  id='general' source="Permissions.Consulta_general" label= "traducciones.titulos.general" sortable={false}/>
                        <BooleanField  id='navegar' source="Permissions.Consulta_navegar" label= "traducciones.titulos.navegar" sortable={false}/>
                        <BooleanField  id='campos' source="Permissions.Consulta_por_campos" label= "traducciones.titulos.porCampos" sortable={false}/>
                        <BooleanField  id='contenido' source="Permissions.Consulta_por_contenido" label= "traducciones.titulos.porContenido" sortable={false}/>

                        { checkPermiso('EditarGrupos') && <EditButton id='btnEdit' label="" className="actionButton"/> }
                        { checkPermiso('EliminarGrupos') && <DeleteButton id='btnDelete' label="" undoable={false} confirmTitle={ translate('traducciones.modal.eliminarGrupo')} confirmContent={ translate('traducciones.modal.eliminarGrupoConfirm')}></DeleteButton> }
                    </Datagrid>
                ) : (
                    <MobileGrupoList checkPermiso= { checkPermiso } />
                )
                }
                </List>
            </div>    
            <Route
            path="/grupos/create"
            action={introDocuments.exit()}
            render={() => (
                <Drawer anchor="right" open onClose={handleClose}>
                    <GrupoCreate {...props} onCancel={handleClose} onSuccessOk= { onSuccess } dataUsers={ dataUsers } />
                </Drawer>
            )}
            />
            <Route path="/grupos/:id">
            {({ match }) => {
                const isMatch = match && match.params && match.params.id !== "create";
                return (
                <Drawer open={isMatch} anchor="right" onClose={handleClose}>
                    {isMatch ? (
                    <GrupoEdit
                        id={match.params.id}
                        record={match.params}
                        onCancel={handleClose}
                        onSuccessOk= { onSuccessEdit }
                        dataUsers={dataUsers}
                        {...props}
                    />
                    ) : null}
                </Drawer>
                );
            }}
        </Route>

         <MyFooter/>  
    </div>
)};

export default withWidth()(GruposList);
