import React from "react";
import { getConektaKey } from "../DataProviders/GlobalDataProvider";

export async function getConektaHeaders() {
  try {
      const conektaKey = await getConektaKey();
      return {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + conektaKey,
          'Accept': 'application/vnd.conekta-v2.1.0+json'
      };
  } catch (error) {
      // Manejar el error adecuadamente
      console.error("Error al obtener la clave de Conekta: ", error);
      // Puede que quieras lanzar el error nuevamente o manejarlo de alguna otra manera
      throw error;
  }
}

export async function getConek (){
  try {
    const conKey = await getConektaKey();
    return conKey
  } catch (error) {
    console.log(error);
  }
}

export const api = {
    URL : 'https://api.conekta.io/'
}

export const api_pay = {
    URL : 'https://pay.conekta.com/api/'
}

export const apimaxi = {
    URL : window.$parametros_generales.REACT_APP_BASE_URL_ENROLLMENT,
    Token : process.env.REACT_APP_KEY_ENROLLMENT
}

export let clientData = {
  CustomerID: '',
  PlatformCustomerId : '',
  TemplateWasUsed: false
}

export let totalCompra = 0.0 ;

export const formatCurrency = (value) => {
	const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: value >= 1000 ? 0 : 2
	});
    totalCompra = value.toFixed(2)
		return formatter.format(value);
};
export const formatText = (plan) => {
    const lines = plan.split('\r\n'); 
    const cascadingString = lines.map((line, index) => (
      <React.Fragment key={index}>
        {index > 0 && <br />} ● {line}
      </React.Fragment>
    ));
    return cascadingString
}
export const options = [
    { value: '1', label: 'Tecnología de la Información' },
    { value: '2', label: 'Salud y Medicina' },
    { value: '3', label: 'Finanzas y Banca' },
    { value: '4', label: 'Manufactura' },
    { value: '5', label: 'Educación' },
    { value: '6', label: 'Energía y Recursos Naturales' },
    { value: '7', label: 'Telecomunicaciones' },
    { value: '8', label: 'Retail' },
    { value: '9', label: 'Automoción' },
    { value: '10', label: 'Alimentación y Bebidas' },
    { value: '11', label: 'Construcción' },
    { value: '12', label: 'Turismo y Hospitalidad' },
    { value: '13', label: 'Medios de Comunicación' },
    { value: '14', label: 'Agricultura' },
    { value: '15', label: 'Servicios Profesionales'}
  ];

export const options_ = [
    { value: '1', label: 'Finanzas' },
    { value: '2', label: 'Ventas' },
    { value: '3', label: 'Marketing' },
    { value: '4', label: 'Operaciones' },
    { value: '5', label: 'Recursos humanos' },
    { value: '6', label: 'Logistica' },
  ];

  export const appVersion = 'Maximage Data Systems V 1.6.4'