import * as React from 'react';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useEffect , useState } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { clientData, formatText } from '../helpers/helpers'
import { useDataProvider, useTranslate } from 'react-admin';
import swal from 'sweetalert';
import { Skeleton } from '@mui/material';
import { extractDataNode } from '../DataProviders/GlobalDataProvider';

const defaultTheme = createTheme();

export function Review({ data, resetDialogs }) {
  const dataProvider = useDataProvider();

  const carpetas = ['Signature','Face','Ocr']
  const [isLoading, setIsLoading] = useState(false);
  const translate = useTranslate()

  useEffect(() => {
    if (data.globalData.order) {
      if (data.globalData.order.id) {
        // console.log('Pago exitoso resumen->',data);
        if(data.itemType == 1){
          getPeticiones()
        }else{
          // console.log('Registrar concepto');
          if(!localStorage.getItem('token')){
            setTimeout(()=>{
              swal({
                icon: 'success',
                text: translate('traducciones.tienda.successRenovacion')
              })
            },1000)
          }else{
            swal({
              icon: 'success',
              text: translate('traducciones.tienda.compraOk')
            })
          }
        }
      }
    }else if(data.paymentDetails.customer_info.customer_id == 'TrialMonth'){
      // console.log('Hacer registro de concepto->',data);
      if(data.itemType == 1){
        getNode();
      }else{
        // console.log('Usuario con mes de prueba');
      }
    }
  }, []);

  const getNode = async() =>{
    data.globalData.node = await extractDataNode();
    getPeticiones();
  }

  const getPeticiones = () =>{
    setIsLoading(true)
      //Se genera carpeta para crear plantilla
      dataProvider.create('nodos', { data: {
            IdParentNode: data.globalData.node[0],
            NodeName: data.paymentDetails.metadata.template.IndustryName,
            Fulltext: false
          }}
      ).then((json) => {
        if(json.data.information){
          const nodoPlantilla = json.data.information.find((node) => node.TextoNodo == data.paymentDetails.metadata.template.IndustryName)
          //Se genera la plantilla dentro del nodo creado especialmente para la plantilla
          dataProvider.create('plantilla',{data: {
            IndustryId: data.paymentDetails.metadata.template.IndustryId,
            ParentNodeId: nodoPlantilla.RutaNodo, 
            CustomerId: clientData.CustomerID
          }}).then((data)=>{
            swal({
              icon: 'success',
              text: data.data.information ? data.data.information : translate('traducciones.tienda.plantillaOk')
            })
            setIsLoading(false)
          }).catch((error)=>{
            swal({
              icon: 'error',
              text: error.message ? error.message : translate('traducciones.tienda.plantillaError')
            })
            // setIsLoading(false)
          })
        }
      }).catch((error) => {
        swal({
          icon: "error",
          text: 'Ocurrió un error al crear el nodo'
        });
      })
    }

  const formatPriceFinal = (price) => {
    let numeroString = price.toString();
    let longitud = numeroString.length;
    let nuevaCadena = numeroString.slice(0, longitud - 2) + '.' + numeroString.slice(longitud - 2);
    return parseFloat(nuevaCadena);
  }


  if(isLoading){
    return(
      <div>
        <Typography variant="h6" gutterBottom>
          <Skeleton width={300} height={20} />
        </Typography>
        <div className='row' style={{ alignItems: 'center' }}>
          <div className='col-md-3'>
            <Skeleton variant="rectangular" width={80} height={80} sx={{ borderRadius: '5px' }} />
          </div>
          <div className='col-md-9'>
            {[1].map((index) => (
              <ListItem key={index} sx={{ marginBottom: '10px' }}>
                <ListItemText
                  primary={<Skeleton width={200} height={20} />}
                  secondary={<Skeleton width={150} height={15} />}
                />
                <Typography variant="body2" style={{marginLeft:'5px', marginTop:'-1em'}}>
                  <Skeleton width={40} height={15} />
                </Typography>
              </ListItem>
            ))}
          </div>
        </div>
      </div>
    )
  }
  
  return (
     <>
     <div>
       <Typography variant="h6" gutterBottom>
        {translate('traducciones.tienda.review')}
       </Typography>
       <div className='row' style={{alignItems:'center'}}>
         <div className='col-md-3'>
           <img src={data.paymentDetails.metadata.template.src} style={{maxWidth:'8em', borderRadius:'5px'}}/>
         </div>
         <div className='col-md-9'>
           {data.paymentDetails.line_items.map((payment) => (
           <ListItem key={payment.name} sx={{ py: 1, px: 0 }}>
             <ListItemText
               primary={translate('traducciones.tienda.producto').concat(payment.name)}
               secondary={(formatText(payment.description).slice(0,6))}/>
               <Typography variant="body2">${formatPriceFinal(payment.unit_price)}</Typography>
           </ListItem>
           ))}
         </div>
       </div>
     </div>
     </>
  );
}