import React, { useState } from 'react'
import { useDataProvider, useNotify, useTranslate } from 'react-admin';
import Button from '@material-ui/core/Button';
import { MenuItem } from '@mui/material';
import Menu from '@material-ui/core/Menu';
import { triggerBase64Download } from 'react-base64-downloader';
import '../index.css'
import swal from 'sweetalert';
import jsonExport from 'jsonexport/dist';

const DownloadButton = (props) => {
    const [anchorEl, setAnchorEl] = useState(false);
    const translate = useTranslate();
    const isCheck = props.isCheck;
    const dataSearch = props.dataSearch;
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const allDocuments = props.allDocuments;
    const [loadinga, setLoadinga] = useState(false);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function b64toBlob(b64Data, contentType) {
        contentType = contentType || '';
        var sliceSize = 512;
        b64Data = b64Data.replace(/^[^,]+,/, '');
        b64Data = b64Data.replace(/\s/g, '');
        var byteCharacters = window.atob(b64Data);
        var byteArrays = [];
    
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);
    
            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
    
            var byteArray = new Uint8Array(byteNumbers);
    
            byteArrays.push(byteArray);
        }
    
        var blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }

    const exportar = () => {
        if (dataSearch && dataSearch.data) {
          var datatoupdate = dataSearch;
          var hayseleccionados = false;
          datatoupdate.data.forEach(element => {
            if (isCheck.includes("" + element.IdDocument)) {
              hayseleccionados = true;
              setLoadinga(true);
              dataProvider.getOne('documento', { IdDocument: element.IdDocument })
                .then(({ data }) => {
                  const campo = element.DatosDocumento[0].ValorLlave !== '' ? element.DatosDocumento[0].ValorLlave : 'Sin ' + element.DatosDocumento[0].NombreIndice;
                  let ext = data.data.TipoDocumento.toLowerCase();
      
                  if (ext === 'pdf' || ext === 'xls' || ext === 'xlsx' || ext === 'doc' || ext === 'docx' || ext === 'txt') {
                    let mimeType = 'application/' + data.data.TipoDocumento.toLowerCase();
                    if (ext === 'txt') {
                      mimeType = 'text/plain'; // Cambiar el mimeType para archivos .txt
                    }
      
                    if (window.saveAs) {
                      window.saveAs(b64toBlob(data.data.Base64file, mimeType), (element.IdNodePath + campo + '_' + element.IdDocument).replace(/[.,\s]/g, ''));
                    } else {
                      navigator.saveBlob(b64toBlob(data.data.Base64file, mimeType), (element.IdNodePath + campo + '_' + element.IdDocument).replace(/[.,\s]/g, ''));
                    }
                  } else if (ext === 'zip') {
                    // Agregar soporte para archivos ZIP
                    if (window.saveAs) {
                      window.saveAs(b64toBlob(data.data.Base64file, 'application/zip'), (element.IdNodePath + campo + '_' + element.IdDocument).replace(/[.,\s]/g, '') + '.zip');
                    } else {
                      navigator.saveBlob(b64toBlob(data.data.Base64file, 'application/zip'), (element.IdNodePath + campo + '_' + element.IdDocument).replace(/[.,\s]/g, '') + '.zip');
                    }
                  } else {
                    triggerBase64Download('data:image/' + data.data.TipoDocumento.toLowerCase() + ';base64,' + data.data.Base64file, (element.IdNodePath + campo + '_' + element.IdDocument).replace(/[.,\s]/g, ''));
                  }
                  setLoadinga(false);
                })
            }
          });
      
          if (!hayseleccionados) {
            swal({
                text:translate('traducciones.error.noHaySeleccion'),
                icon:'error'
            })
          }
        }
    }

    const exportarCsv = () => {
        setLoadinga(true);
        var datatoExport = [];
        if(dataSearch && dataSearch.data){
            var hayseleccionados = false;
            dataSearch.data.forEach(d => {
                let document = []
                if(isCheck.includes("" + d.IdDocument)){
                    document.push({
                       ['Ubicación']: d.IdNodePath
                   }); 
                    hayseleccionados = true;
                    d.DatosDocumento.forEach(e => {
                        if(e.Multiple == true){
                            document.push({
                                [e.NombreIndice] : e.ValoresLlave.join(',')
                            })
                        } else if(e.NombreIndice != 'UBICACIÓN'){
                        document.push({
                            [e.NombreIndice] : e.ValorLlave
                        });    
                    }     
                    });
                    datatoExport.push(document);
                }
            });
            if(hayseleccionados){
                jsonExport(datatoExport,{rowDelimiter: ',',textDelimiter:'"'}, function(err, csv){
                    setLoadinga(false);
                    if(err) return console.log(err);
                    const blob = new Blob(["\ufeff",csv], { type: "text/csv;charset=utf-8" });
                    if (window.saveAs) { window.saveAs(blob, 'csv_info.csv'); }
                    else { navigator.saveBlob(blob, 'csv_info.csv'); }           
                });
            }
            if(!hayseleccionados){
                setLoadinga(false);
                notify(translate("traducciones.error.noHaySeleccion"), "error");
            } 
        }else{
            setLoadinga(false);
        }
    }

    const exportarCsvAll = () => {
        setLoadinga(true);
        var datatoExport = [];
        if(allDocuments != null){
            allDocuments.data.forEach(d => {
                let document = []
                 document.push({
                    ['Ubicación']: d.IdNodePath
                }); 
                d.DatosDocumento.forEach(e => {
                     if(e.Multiple == true){
                            document.push({
                                [e.NombreIndice] : e.ValoresLlave.join(',')
                            })
                        } else if(e.NombreIndice != 'UBICACIÓN'){
                        document.push({
                            [e.NombreIndice]: e.ValorLlave
                        });    
                    }               
                });
                datatoExport.push(document);
            });
            jsonExport(datatoExport,{rowDelimiter: ','}, function(err, csv){
                setLoadinga(false);
                if(err) return console.log(err);
                const blob = new Blob(["\ufeff",csv], { type: "text/csv;charset=utf-8" });
                if (window.saveAs) { window.saveAs(blob, 'csv_info.csv'); }
                else { navigator.saveBlob(blob, 'csv_info.csv'); }           
            });
        }else{
            swal({
                text:'Cargando datos...\nEspere un momento y vuelva a intentar',
                icon:'info'
            })
            setLoadinga(false);
        }
    }

  return (
    <div>
        <Button
            id="basic-button"
            style={{ backgroundColor: "#30b165", color : "white", padding: "3px 10px", marginRight: '20px'}}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
        >
            {translate('traducciones.botones.opcionesDescarga')}
        </Button>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
        >
            <MenuItem onClick={ () => { handleClose(); exportar(); } }>{translate('traducciones.botones.descargarSeleccionados')}</MenuItem>
            {/* <MenuItem onClick={ () => { handleClose(); exportarAll(); } }>{translate('traducciones.botones.descargarSeleccionadosAll')}</MenuItem> */}
            <MenuItem onClick={ () => { handleClose(); exportarCsv(); } }>{translate('traducciones.botones.descargarCsv')}</MenuItem>
            <MenuItem onClick={ () => { handleClose(); exportarCsvAll(); } }>{translate('traducciones.botones.descargarCsvAll')}</MenuItem>
        </Menu>
    </div>
    
  )
}

export default DownloadButton