import React from 'react'
import '../Libs/ExternalViewer/Resources/Styles/Cards.css'
import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Plantillas from '../Views/Plantillas';
import imgItem from '../images/shopping-store.png'
import CardItem from './CardItem';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { ComponentPAY } from './ComponentPay';
import jwt_decode from "jwt-decode";
import NoAccess from './NoAccess'
import almacenamiento from '../Libs/ExternalViewer/Resources/Images/almacenamiento1.png'
import bytesAlm from '../Libs/ExternalViewer/Resources/Images/almacenamiento byte.png'

import consultas from '../Libs/ExternalViewer/Resources/Images/consultas pre.png'
import consultas5 from '../Libs/ExternalViewer/Resources/Images/consultas pre 5.png'
import consultas20 from '../Libs/ExternalViewer/Resources/Images/consulta pre 20.png'
import consultas50 from '../Libs/ExternalViewer/Resources/Images/consultas pre 50.png'

import perfiles from '../Libs/ExternalViewer/Resources/Images/perfiles.png'
import admin from '../Libs/ExternalViewer/Resources/Images/perfil administasdor.png'
import consulta from '../Libs/ExternalViewer/Resources/Images/perfil de consultaa.png'
import seguridad from '../Libs/ExternalViewer/Resources/Images/perfil seguridad.png'

import extraccionesfull from '../Libs/ExternalViewer/Resources/Images/full tex.png'
import extracciones from '../Libs/ExternalViewer/Resources/Images/exraccion full1 copia.png'

import meses from '../Libs/ExternalViewer/Resources/Images/suscripcion.png'
import basico from '../Libs/ExternalViewer/Resources/Images/laptop.png'
import basicoplus from '../Libs/ExternalViewer/Resources/Images/monitor.png'
import pro from '../Libs/ExternalViewer/Resources/Images/servidor.png'

import c1 from '../Libs/ExternalViewer/Resources/Images/c1.png'
import c2 from '../Libs/ExternalViewer/Resources/Images/c2.png'
import c3 from '../Libs/ExternalViewer/Resources/Images/c3.png'

import mas from '../Libs/ExternalViewer/Resources/Images/mas.png'
import cecoban from '../Libs/ExternalViewer/Resources/Images/cecoban copia.png'
import docusign from '../Libs/ExternalViewer/Resources/Images/docusing.png'
import idrecognition from '../Libs/ExternalViewer/Resources/Images/idRecog.png'
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { useEffect } from 'react';
import { getHistoryUser } from '../DataProviders/GlobalDataProvider';
import { apimaxi, clientData } from '../helpers/helpers';
import swal from 'sweetalert';
import { useTranslate } from 'react-admin';
import MyFooter from './MyFooter';
import Loader from './Loader';

const Store = ({resetDialogs, extra}) => {

    const [openItem, setOpenItem] = useState(false)
    const [itemSel, setItemSel] = useState({}) 
    const [openDetails, setOpenDetails] = useState(false)
    const [details, setDetails] = useState({})
    const [openPay, setOpenPay] = useState(false)
    const [idPaquete, setIdPaquete] = useState(0)
    const [loading, setLoading] = useState(false);
    const translate = useTranslate()
    let itemsbyUser 

    useEffect(async()=>{
        //Se consulta el historial de compras del usuario
        const customerHistory = await getHistoryUser(clientData.CustomerID);

        //Se valida que haya sido exitosa la consulta de los datos
        if(customerHistory.Success){
            //Del historial se obtiene el paquete actual del usuario
            itemsbyUser = customerHistory.Information.filter(item => item.ItemName.toLowerCase().includes('paquete'))
            setIdPaquete(itemsbyUser[0].ItemId)
        }
    },[3])

    let items = [
        {
            name: translate('traducciones.tienda.perfiles'),
            src: perfiles,
            items:[
                {
                    "ItemId": 1,
                    "ItemName": translate('traducciones.tienda.ItemName1'),
                    "src": admin,
                    "Value": 1,
                    "Price":  29.99,
                    "Desc": translate('traducciones.tienda.DescGral1'),
                    "Description": [
                        {
                            desc: translate('traducciones.tienda.desc1_1')
                        },
                        {
                            desc: translate('traducciones.tienda.desc1_2')
                        },
                    ]
                },
                {
                    "ItemId": 2,
                    "ItemName": translate('traducciones.tienda.ItemName2'),
                    "src": seguridad,
                    "Value": 1,
                    "Price":  19.99,
                    "Desc": translate('traducciones.tienda.DescGral2'),
                    "Description": [
                        {
                            desc: translate('traducciones.tienda.desc2_1')
                        },
                        {
                            desc: translate('traducciones.tienda.desc2_2')
                        },
                        {
                            desc: translate('traducciones.tienda.desc2_3')
                        },
                    ]
                },
                {
                    "ItemId": 3,
                    "ItemName": translate('traducciones.tienda.ItemName3'),
                    "src": consulta,
                    "Value": 1,
                    "Price":  14.99,
                    "Desc": translate('traducciones.tienda.DescGral3'),
                    "Description": [
                        {
                            desc: translate('traducciones.tienda.desc3_1')
                        },
                        {
                            desc: translate('traducciones.tienda.desc3_2')
                        },
                        {
                            desc: translate('traducciones.tienda.desc3_3')
                        },
                    ]
                }
            ]
        },
        {
            name: translate('traducciones.tienda.almacenamiento'),
            src: almacenamiento,
            items: [
                {
                    "ItemId": 4,
                    "ItemName": translate('traducciones.tienda.ItemName4'),
                    "src": bytesAlm,
                    "Value": 10000,
                    "Price": 4.99,
                    "Desc": translate('traducciones.tienda.DescGral4'),
                    "Description": [
                        {
                            desc: translate('traducciones.tienda.desc4_1')
                        },
                    ]
                },
            ]
        },
        {
            name: translate('traducciones.tienda.meses'),
            src: meses,
            items: [
                {
                    "ItemId": 9,
                    "ItemName": translate('traducciones.tienda.ItemName5'),
                    "src": basico,
                    "Value": 1,
                    "Price":  299.99,
                    "Desc": translate('traducciones.tienda.DescGral5'),
                    "Description": [
                        {
                            desc: translate('traducciones.tienda.desc5_1')
                        },
                        {
                            desc: translate('traducciones.tienda.desc5_2')
                        },
                        {
                            desc: translate('traducciones.tienda.desc5_3')
                        },
                        {
                            desc: translate('traducciones.tienda.desc5_4')
                        },
                        {
                            desc: translate('traducciones.tienda.desc5_5')
                        },
                    ]
                },
                {
                    "ItemId": 10,
                    "ItemName": translate('traducciones.tienda.ItemName6'),
                    "src": basicoplus,
                    "Value": 1,
                    "Price": 541.58,
                    "Desc": translate('traducciones.tienda.DescGral6'),
                    "Description": [
                        {
                            desc: translate('traducciones.tienda.desc6_1')
                        },
                        {
                            desc: translate('traducciones.tienda.desc6_2')
                        },
                        {
                            desc: translate('traducciones.tienda.desc6_3')
                        },
                        {
                            desc: translate('traducciones.tienda.desc6_4')
                        },
                        {
                            desc: translate('traducciones.tienda.desc6_5')
                        },
                    ]
                },
                {
                    "ItemId": 11,
                    "ItemName": translate('traducciones.tienda.ItemName7'),
                    "src": pro,
                    "Value": 1,
                    "Price":  900.00,
                    "Desc": translate('traducciones.tienda.DescGral7'),
                    "Description": [
                        {
                            desc: translate('traducciones.tienda.desc7_1')
                        },
                        {
                            desc: translate('traducciones.tienda.desc7_2')
                        },
                        {
                            desc: translate('traducciones.tienda.desc7_3')
                        },
                        {
                            desc: translate('traducciones.tienda.desc7_4')
                        },
                        {
                            desc: translate('traducciones.tienda.desc7_5')
                        },
                    ]
                }
            ]
        },
        {
            name: translate('traducciones.tienda.extracciones'),
            src: extraccionesfull,
            items: [
                {
                    "ItemId": 6,
                    "src": extracciones,
                    "ItemName": translate('traducciones.tienda.ItemName8'),
                    "Value": 1,
                    "Price": 480.00,
                    "Desc": translate('traducciones.tienda.DescGral8'),
                    "Description": [
                        {
                            desc: translate('traducciones.tienda.desc8_1')
                        },
                    ]
                }
            ]
        },
        {
            name: translate('traducciones.tienda.consultas'),
            src: consultas,
            items: [
                {
                    "ItemId": 7,
                    "ItemName": translate('traducciones.tienda.ItemName9'),
                    "src": consultas5,
                    "Value": 5,
                    "Price": 10.23,
                    "Desc": translate('traducciones.tienda.DescGral9'),
                    "Description": [
                        {
                            desc: translate('traducciones.tienda.desc9_1')
                        },
                    ]
                },
                {
                    "ItemId": 7,
                    "ItemName": translate('traducciones.tienda.ItemName10'),
                    "src": consultas20,
                    "Value": 20,
                    "Price": 25.58,
                    "Desc": translate('traducciones.tienda.DescGral10'),
                    "Description": [
                        {
                            desc: translate('traducciones.tienda.desc10_1')
                        },
                    ]
                },
                {
                    "ItemId": 7,
                    "ItemName": translate('traducciones.tienda.ItemName11'),
                    "src": consultas50,
                    "Value": 50,
                    "Price": 61.40,
                    "Desc": translate('traducciones.tienda.DescGral11'),
                    "Description": [
                        {
                            desc: translate('traducciones.tienda.desc11_1')
                        },
                    ]
                },
            ]
        },
        {
            name: translate('traducciones.tienda.plantillas')
        },
        // {
        //     name: translate('traducciones.tienda.mas'),
        //     src: mas,
        //     items: [
        //         {
        //             "ItemId": 99,
        //             "ItemName": "Constancias de conservación (Cecoban) ",
        //             "src": cecoban,
        //             "Value": 1,
        //             "Price": 100.00,
        //             "Desc": 'Descripción de constancias de conservación',
        //             "Description": [
        //                 {
        //                     desc: 'Descripción 1'
        //                 },
        //             ]
        //         },
        //         {
        //             "ItemId": 99,
        //             "ItemName": "Firmas de Docusign",
        //             "src": docusign,
        //             "Value": 1,
        //             "Price": 100.00,
        //             "Desc": 'Descripción de firmas de docusign',
        //             "Description": [
        //                 {
        //                     desc: 'Descripción 1'
        //                 },
        //             ]
        //         },
        //         {
        //             "ItemId": 99,
        //             "ItemName": "MAXIMAGE ID RECOGNITION",
        //             "src": idrecognition,
        //             "Value": 1,
        //             "Price": 100.00,
        //             "Desc": 'Descripción de ID RECOGNITION',
        //             "Description": [
        //                 {
        //                     desc: 'Descripción 1'
        //                 },
        //             ]
        //         },
        //     ]
        // },
        {
            name: 'MAXIMAGE ID RECOGNITION',
            src: idrecognition,
            items: [
                {
                    "ItemId": 0,
                    "ItemName": translate('traducciones.tienda.ItemName12') +' 1',
                    "src": c1,
                    "Value": 1,
                    "Price": 100.00,
                    "Desc": translate('traducciones.tienda.DescGral12'),
                    "Description": [
                        {
                            desc: translate('traducciones.tienda.id')
                        },
                        {
                            desc: translate('traducciones.tienda.rostro')
                        },
                        {
                            desc: translate('traducciones.tienda.firma')
                        },
                    ]
                },
                {
                    "ItemId": 0,
                    "ItemName": translate('traducciones.tienda.ItemName12') +' 2',
                    "src": c2,
                    "Value": 1,
                    "Price": 100.00,
                    "Desc": translate('traducciones.tienda.DescGral12'),
                    "Description": [
                        {
                            desc: translate('traducciones.tienda.id')
                        },
                        {
                            desc: translate('traducciones.tienda.rostro')
                        },
                        {
                            desc: translate('traducciones.tienda.sofiia1')
                        },
                        {
                            desc: translate('traducciones.tienda.firma')
                        },
                    ]
                },
                {
                    "ItemId": 0,
                    "ItemName": translate('traducciones.tienda.ItemName12') + ' 3',
                    "src": c3,
                    "Value": 1,
                    "Price": 100.00,
                    "Desc": translate('traducciones.tienda.DescGral12'),
                    "Description": [
                        {
                            desc: translate('traducciones.tienda.id')
                        },
                        {
                            desc: translate('traducciones.tienda.rostro')
                        },
                        {
                            desc: translate('traducciones.tienda.sofiia2')
                        },
                        {
                            desc: translate('traducciones.tienda.firma')
                        },
                    ]
                },
                {
                    "ItemId": 0,
                    "ItemName": translate('traducciones.tienda.ItemName12') + ' 4',
                    "src": c3,
                    "Value": 1,
                    "Price": 100.00,
                    "Desc": translate('traducciones.tienda.DescGral12'),
                    "Description": [
                        {
                            desc: translate('traducciones.tienda.id')
                        },
                        {
                            desc: translate('traducciones.tienda.rostro')
                        },
                        {
                            desc: translate('traducciones.tienda.sofiia3')
                        },
                        {
                            desc: translate('traducciones.tienda.firma')
                        },
                    ]
                },
            ]
        },
    ]

    let token = {}
    let dataToken = {}
    let isMulticliente; 

    if(localStorage.getItem('token')){
        token = localStorage.getItem('token')
        dataToken = jwt_decode(token);
        if(token){
            if((dataToken.MultiClientSession == 'True' || dataToken.MultiClientSession == 'true' || dataToken.MultiClientSession == true) && dataToken.role == 2 || dataToken.role == '2'){
                isMulticliente = true; 
            }else{
                isMulticliente = false;
            }
        }
    }else{
        isMulticliente = true
    }

    const handleOpenItem = (e,item) => {
        // console.log('Se abre el item->',item);
        setItemSel(item)
        setOpenItem(true)
    }

    const handleOpenDetails = (e,item) => {
        item.name = item.ItemName
        // console.log('Detalles del item',item);
        setDetails(item)
        setOpenDetails(true)
    }

    const handleOpenPay = (itemSeleccionado) => { 
        // console.log('Item seleccionado para compra ->',itemSeleccionado);
        if(itemSeleccionado.ItemId == idPaquete){
            swal({
                icon: 'info',
                text: translate('traducciones.tienda.pay1') + itemSeleccionado.ItemName + translate('traducciones.tienda.pay2'),
                buttons:{
                    cancel: {
                      text: translate('traducciones.campos.cancelar'),
                      value: false,
                      visible: true,
                      className: "bg-danger text-white",
                      closeModal: true,
                    },
                    confirm: {
                      text: translate('traducciones.campos.aceptar'),
                      value: true,
                      visible: true,
                      className: "bg-success text-white",
                      closeModal: true
                    }
                }
            }).then((res)=>{
                if(res){
                    itemSeleccionado.ItemId = 5
                    setDetails(itemSeleccionado)
                    setOpenPay(true)
                }
            })
        }else if(itemSeleccionado.ItemId >= 9){
            if(itemSeleccionado.ItemId < idPaquete){
                swal({
                    icon: 'info',
                    text: translate('traducciones.tienda.avisoDown')
                })
            }else{
                swal({
                    icon: 'info',
                    text: translate('traducciones.tienda.avisoUp1') + itemSeleccionado.ItemName + translate('traducciones.tienda.avisoUp2') + itemSeleccionado.ItemName + translate('traducciones.tienda.avisoUp3'),
                    buttons:{
                        cancel: {
                          text: translate('traducciones.campos.cancelar'),
                          value: false,
                          visible: true,
                          className: "bg-danger text-white",
                          closeModal: true,
                        },
                        confirm: {
                          text: translate('traducciones.campos.aceptar'),
                          value: true,
                          visible: true,
                          className: "bg-success text-white",
                          closeModal: true
                        }
                    }
                }).then((res)=>{
                    if(res){
                        setOpenPay(true)
                    }
                })
            }
        }else{
            setOpenPay(true)
        }
    }

const sendEmailToSale = async(item) =>{
    // Crear HTML dinámico
    const htmlContent = `
        <div class="bodyEmail" style="font-family: Arial, sans-serif; margin: 0; padding: 20px; background-color: #f7f7f7;">
            <div class="containerEmail" style="max-width: 600px; margin: 0 auto; background-color: #f0f0f0; padding: 20px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); border-radius: 20px; text-align: center;">
                <div class="tituloEmail" style="background-color: #30b165; border-radius: 10px; padding: 1px; color: white; text-align: center;">
                    <h1>Solicitud de contratación Id Recognition</h1>
                </div>
                <div class="subtituloEmail" style="color: #30b165;">
                    <h2>Detalles de la solicitud</h2>
                </div>
                <div class="detailEmail" style="margin: 20px 0;">
                    <span class="labelEmail" style="font-weight: bold; color: #30b165; font-size: 17px;">Combo seleccionado</span><br />
                    <span class="valueEmail" id="purchase-id" style="margin-left: 10px; color: #333; font-size: 16px;">${item.ItemName}</span>
                </div>
                <div class="detailEmail" style="margin: 20px 0;">
                    <span class="labelEmail" style="font-weight: bold; color: #30b165; font-size: 17px;">Servicios a contratar:</span><br />
                    <span class="valueEmail" id="customer-id" style="margin-left: 10px; color: #333; font-size: 16px;">${item.Description.map(item => item.desc).join(", ")}</span>
                </div>
                <div class="detailEmail" style="margin: 20px 0;">
                    <span class="labelEmail" style="font-weight: bold; color: #30b165; font-size: 17px;">Email de cliente</span><br />
                    <span class="valueEmail" id="customer-email" style="margin-left: 10px; color: #333; font-size: 16px;">${localStorage.getItem('userName')}</span>
                </div>
                <div class="detailEmail" style="margin: 20px 0;">
                    <span class="labelEmail" style="font-weight: bold; color: #30b165; font-size: 17px;">Customer ID del cliente</span><br />
                    <span class="valueEmail" id="product-name" style="margin-left: 10px; color: #333; font-size: 16px;">${clientData.CustomerID}</span>
                </div>
                <div class="detailEmail" style="margin: 20px 0;">
                    <span class="labelEmail" style="font-weight: bold; color: #30b165; font-size: 17px;">Fecha de solicitud</span><br />
                    <span class="valueEmail" id="purchase-time" style="margin-left: 10px; color: #333; font-size: 16px;">${new Date().toLocaleString('es-ES')}</span>
                </div>
            </div>
        </div>
    `;
    
    // Convertir el HTML a base64
    const base64Html = btoa(unescape(encodeURIComponent(htmlContent)));

    const dataToSale = {
        "To": [
          "cdiaz@maximage-ds.com"
        ],
        "CC": [
          // "avelazquez@maximage-ds.com"
        ],
        "Subject": "Solicitud de contratación ID Recognition",
        "Base64HtmlBody": base64Html,
        "Attachments": [
          // {
          //   "FileName": "Documento de prueba",
          //   "Base64File": ""
          // }
        ]
      }
      
      // Opciones de la solicitud fetch para facturar
      const requestSale = {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+ apimaxi.Token
          },
          body: JSON.stringify(dataToSale)
      };
      
      setLoading(true)
      const sendDetails = await fetch('https://maxisky.com/ApiMaxImage/Api/Email/sendEmail', requestSale);
      if(sendDetails.ok){
        swal({
            icon: 'success',
            text: 'Correo enviado correctamente. Nuestro equipo de ventas se pondrá en contacto con usted pronto para continuar con su solicitud. Gracias por confiar en nosotros.'
        });        
      }
      setLoading(false)
}

return (
    <div style={{padding:'5px'}}>
        {isMulticliente ? (
            <>
                <div style={{paddingInline:'25px'}}>
                        <h3 className='homeTitle'>{translate('traducciones.menu.tienda')}</h3>
                        <p className='homeSub'>{translate('traducciones.tienda.subtitle')}</p>
                </div>
                {/* Se valida si tiene sesión iniciada para mostrar todo el catalogo o solo los planes para renovar */}
                {localStorage.getItem('token') ? (
                    <CardItem dataItems={items} handleOpenItem={handleOpenItem}/>
                ):(
                    <CardItem dataItems={items.filter((item)=> item.name == translate('traducciones.tienda.meses'))} handleOpenItem={handleOpenItem}/>
                )}
            </>
        ):(
            <NoAccess/>
        )}

        <Dialog open={openItem}>
        <DialogTitle>{itemSel.name}</DialogTitle>
        <DialogContent style={{minWidth:'70rem'}}>
            {itemSel.name == translate('traducciones.tienda.plantillas') ? 
            (
                <div style={{padding:'5px'}}>
                    <Plantillas/>    
                </div>
            ):(
                <div style={{padding:'10px'}}>
                    <div style={{paddingInline:'5px'}}>
                        <h3 className='homeTitle'>{itemSel.name}</h3>
                        <p className='homeSub'>
                            {itemSel.name == translate('traducciones.tienda.perfiles') ? translate('traducciones.tienda.textPerfiles') : 
                             itemSel.name == translate('traducciones.tienda.almacenamiento') ? translate('traducciones.tienda.textAlmacenamiento') :
                             itemSel.name == translate('traducciones.tienda.meses') ? translate('traducciones.tienda.textMeses') :
                             itemSel.name == translate('traducciones.tienda.extracciones') ? translate('traducciones.tienda.textExtracciones') :
                             itemSel.name == translate('traducciones.tienda.consultas') ? translate('traducciones.tienda.textConsultas') :
                             ''}
                        </p>
                    </div>
                    <section class="articles">
                        {itemSel.items && itemSel.items.map((item)=>{
                            return(
                            <article style={item.ItemId == idPaquete ? {backgroundColor: 'rgb(49 155 66 / 61%)', color: 'white'} : {}}>
                                <div class="article-wrapper">
                                    <figure>
                                        <img src={item.src ? item.src : imgItem} alt="" />
                                        {/* <StorefrontIcon/> */}
                                    </figure>
                                    <div class="article-body">
                                        <h4>
                                            {item.ItemName}
                                        </h4>
                                            <a className="read-more" onClick={(e) => handleOpenDetails(e,item)}>
                                                {item.ItemId == idPaquete ? 'Adquirido' : translate('traducciones.tienda.vermas')}<span class="sr-only">about this is some title</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 2 20 20" fill="rgb(92, 157, 102)">
                                                    <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                                                </svg>
                                            </a>
                                    </div>
                                </div>
                            </article>
                            )
                        })}
                    </section>
                </div>
            )}
        </DialogContent>
        <DialogActions style={{justifyContent:'center'}}>
            <Button onClick={() => setOpenItem(false)} className=''>{translate('traducciones.visor.cerrar')}</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog para mostrar detalles del item */}
      <Dialog open={openDetails}>
        <DialogContent>
            <>
            {loading ? (
                <div>
                    <Loader/>
                </div>
            ):(
            <div className='row' style={{minWidth:'50rem',maxWidth:'1000px', alignItems:'center'}}>
                <div className='col-md-5'>
                    <CardItem dataItems={[details]} handleOpenDetails={handleOpenDetails}/>
                </div>
                <div className='col-md-4 list' style={{fontSize:'17px', marginLeft:'50px'}}>
                    <h2>{translate('traducciones.tienda.detalles')} </h2>
                    <ul>
                        {details.Description && details.Description.map((d)=>{
                            return(
                                <li><span>{d.desc}</span></li>
                            )
                        })
                        }
                    </ul>
                </div>
            </div>
            )}
            </>
            </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpenDetails(false)} className='bg-danger'>{translate('traducciones.visor.cerrar')}</Button>
            {details.ItemId == 0 ? (
                <Button onClick={() => sendEmailToSale(details)} style={{backgroundColor:'rgb(92, 157, 102) !important'}} disabled={loading}>{translate('traducciones.tienda.contactar')}<ContactMailIcon sx={{marginLeft:'10px'}}/></Button>
            ):(
                <Button onClick={() => {handleOpenPay(details)}} style={{backgroundColor:'rgb(92, 157, 102) !important'}}>{translate('traducciones.tienda.comprar')}<AddShoppingCartIcon/></Button>
            )}
        </DialogActions>
      </Dialog>

      {/* Dialog de pago */}
      <Dialog open={openPay}>
        <DialogTitle>{translate('traducciones.tienda.proceso')}</DialogTitle>
        <DialogContent>
            <ComponentPAY item={details} type={2} resetDialogs={resetDialogs} extra={extra}/>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpenPay(false)} className='bg-danger'>{translate('traducciones.visor.cerrar')}</Button>
        </DialogActions>
      </Dialog>
      <MyFooter/>
    </div>
)
}

export default Store