import React from 'react';
import { useState, useEffect } from 'react';
import { 
    Chart as ChartJS, 
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement, 
    Tooltip, 
    Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  
    Loading, 
    useDataProvider,
    Error,
    useTranslate,
    SimpleForm
} from 'react-admin';

import { 
    faFileWord, 
    faFilePdf, 
    faFileImage, 
    faUser,
    faFile,
    faFileExcel,
    faFileAlt,
    faFileCode
} from '@fortawesome/free-solid-svg-icons'
import '../Libs/ExternalViewer/Resources/Styles/predefinidasStyle.css'
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import jwt_decode from "jwt-decode";
import TableroStorage from '../Components/TableroStorage';
import { BooleanInput } from 'react-admin';
import MyFooter from '../Components/MyFooter';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement, 
    Tooltip, 
    Legend);

const DashBoard  = () => {

  function getRandomRgb() {
    const red = Math.floor((1 + Math.random()) * 256/2);
    const green = Math.floor((1 + Math.random()) * 256/2);
    const blue = Math.floor((1 + Math.random()) * 256/2);
    return "rgba(" + red + ", " + green + ", " + blue + ", 0.8)";
  }
  const colorsToAiidara = [
    'rgba(0,81,48,0.9)',
    'rgba(48,177,101,0.9)',
    'rgba(8,255,0,0.4)',
    // 'rgba(223 223 223,0.1)'
    // 'rgba(2,8,119,0.7)',
    // 'rgba(255,255,255,0.7)',
  ]

  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);
  const [tiposDocumentos, setTiposDocumentos] = useState([]);
  const [fechaDigitalizacion, setFechaDigitalizacion] = useState([]);
  const [usuarios, setUsuarios] = useState(null);
  const [datosDocumentosPendientesTipo, setDatosDocumentosPendientesTipo] = useState([]);
  const [capturedDocuments, setCapturedDocuments] = useState(null);
  const [totalPagesCapturedDocuments, setTotalPagesCapturedDocuments] = useState(null); 
  const [documentsPendingCapture, setDocumentsPendingCapture] = useState(null);
  const [espacioUtilizado, setespacioUtilizado] = useState(null);
  const [error, setError] = useState();
  const inTour = JSON.parse(localStorage.getItem('activeTour'))
  const [tablero, setTablero] = useState(false)
  const [dataClients, setDataClients] = useState([])
  const [clienteStorage, setClientStorage] = useState('');
  const [storageGral, setStoreGral] = useState({})

  const translate = useTranslate();
  let introNodos;

  let introHelp;
    introHelp = introJs('#root');
    introHelp.setOptions({
        disableInteraction: false,
        doneLabel: translate('traducciones.introBotones.doneLabel'),
        nextLabel: translate('traducciones.introBotones.nextLabel'),
        prevLabel: translate('traducciones.introBotones.prevLabel'),
        tooltipPosition:'top',
        steps: [
                {
                intro: translate('traducciones.introHelp.intro1'),
                title: translate('traducciones.introHelp.title1'),
                element: '#help'
                },
        ]                
    });

    const showHelp = () =>{
        const offPermisos = {
            querys: false,
            carga: false,
            admin: false,
            estructura: false,
        }
        if(document.cookie == 'introjs-dontShowAgain=true'){
            document.cookie = 'introjs-dontShowAgain =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
                setTimeout(()=>{
                    introHelp.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).oncomplete(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).onexit(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).start();                         
                },500)
        }
    }

    function crearCookie(nombre, valor, diasParaExpirar) {
    var fechaExpiracion = new Date();
    fechaExpiracion.setDate(fechaExpiracion.getDate() + diasParaExpirar);
    var cookie = nombre + '=' + valor + '; expires=' + fechaExpiracion.toUTCString() + '; path=/';
    document.cookie = cookie;
    }

    var token = localStorage.getItem('token');
    var decoded = {};      
    let idRol 
    let isMulticliente = false;

    if(token){
        decoded = jwt_decode(token); 
        idRol = decoded.role
        if(decoded.MultiClientSession == 'True' || decoded.MultiClientSession == 'true' || decoded.MultiClientSession == true){
            isMulticliente = true; 
        }else{
            isMulticliente = false;
        }
    }

  useEffect(() => {
    dataProvider.getOne('dashboard', { type: 'getCapturedDocuments' })
            .then(({ data }) => {
                /*
                Description: "Documentos Capturados"
                Total: 169
                */
                setCapturedDocuments(data.data);
                dataProvider.getOne('dashboard', { type: 'getCapturedDocumentsTypes' })
                    .then(({ data }) => {
                        /*
                        Array de 
                        Description: "DOCX"
                        Total: 5
                        */
                        if(data.data.length == 0){
                            setTiposDocumentos([
                                {
                                    Description: "",
                                    Total: 0
                                },
                            ])
                        }else{
                            setTiposDocumentos(data.data);
                        }

                        if(data.data != null && data.data != undefined && data.data.length >= 0) {

                        dataProvider.getOne('dashboard', { type: 'getCapturedDocumentsByDate' })
                            .then(({ data }) => {
                                /*
                                Array de 
                                HorizontalAxisValue: "2022/02/01"
                                VerticalAxisValue: "12"
                                */
                               var labelsDias = [];
                               var datosFechas = [];
                               data.data.forEach(function (record) { 
                                    if(record != null){
                                        labelsDias.push(record.HorizontalAxisValue);
                                        datosFechas.push(record.VerticalAxisValue)
                                    }
                                });

                               setFechaDigitalizacion({
                                    labels: labelsDias,
                                    datasets: [
                                        {
                                            label: translate('traducciones.dash.indexadosXdia'),
                                            data: datosFechas,
                                            backgroundColor: '#30b1657a',
                                        },
                                    ],
                                });
                                dataProvider.getOne('dashboard', { type: 'getTotalPagesCapturedDocuments' })
                                    .then(({ data }) => {
                                        /*
                                        Description: "Páginas Capturadas"
                                        Total: 885
                                        */
                                       if(data.data.length == 0){
                                           setTotalPagesCapturedDocuments({
                                            "Description": "Páginas Capturadas",
                                            "Total": 0
                                          })
                                       }else{
                                           setTotalPagesCapturedDocuments(data.data);
                                       }
                                        dataProvider.getOne('dashboard', { type: 'getDocumentsPendingCapture' })
                                        .then(({ data }) => {
                                            /*
                                            Description: "Documentos Pendientes Capturar"
                                            Total: 174
                                            */
                                            setDocumentsPendingCapture(data.data);
                                            dataProvider.getOne('dashboard', { type: 'getDocumentTypesPendingCapture' })
                                                .then(({ data }) => {
                                                    /*
                                                    Array de
                                                    Item: "DOCX"
                                                    Percent: "13"
                                                    */
                                                    var labelsd = [];
                                                    var datosd = [];
                                                    var fondos = [];
                                                    data.data.forEach(function (record) { 
                                                            if(record != null){
                                                                labelsd.push(record.Item);
                                                                datosd.push(record.Percent);
                                                                fondos.push(getRandomRgb());
                                                            }
                                                        });    
                                                    setDatosDocumentosPendientesTipo({
                                                        labels: labelsd,
                                                        datasets: [
                                                        {
                                                            label: '%',
                                                            data: datosd,
                                                            borderWidth: 1,
                                                            backgroundColor: colorsToAiidara
                                                        }
                                                        ],
                                                    });
                                                    
                                                    dataProvider.getOne('dashboard', { type: 'getActiveUsers' })
                                                        .then(({ data }) => {
                                                            /*
                                                            Description: "Usuarios Activos"
                                                            Total: 9
                                                            */
                                                            if(data.data.length == 0){
                                                                setUsuarios({
                                                                    "Description": "Usuarios Activos",
                                                                    "Total": 1
                                                                  })
                                                            }else{
                                                                setUsuarios(data.data);
                                                            }
                                                            dataProvider.getOne('dashboard', { type: 'getUsedSpace' })
                                                            .then(({ data }) => {
                                                                /*
                                                                Maximum: "1000"
                                                                ProgressPercentage: "65"
                                                                */ 
                                                                dataProvider.getOne('dashboard', { type: 'getUsedCustomerSpace' })
                                                                .then(({ data }) => {
                                                                    if(data.data){
                                                                        setStoreGral({
                                                                            customersAiidara: data.data.customersAiidara,
                                                                            totalCustomersStorage: data.data.totalCustomersStorage, 
                                                                            totalUsedPercentage: data.data.totalUsedPercentage,
                                                                            unitsOfMeasurement: data.data.unitsOfMeasurement

                                                                        })
                                                                        setDataClients(data.data.listDashboardProgressBarEntity)
                                                                    }
                                                                })         
                                                                setespacioUtilizado({
                                                                    labels : [''],
                                                                    datasets: [
                                                                        {
                                                                            label: 'Espacio Utilizado ' + data.data.ProgressPercentage + '% de ' + data.data.Maximum + ' GB',
                                                                            data: [data.data.ProgressPercentage],
                                                                            backgroundColor: '#30b1657a',
                                                                            stacked : "0"
                                                                            
                                                                        },
                                                                        {
                                                                            label: 'Espacio Disponible ' + ((data.data.Maximum * (100 - data.data.ProgressPercentage)) / 100) + " GB",
                                                                            data: [100 - data.data.ProgressPercentage],
                                                                            backgroundColor: 'rgb(223 223 223)',
                                                                            stacked : "0"
                                                                            
                                                                        }
                                                                    ],
                                                                })                                                      
                                                                setLoading(false);
                                                                if(inTour.estructura){
                                                                    setTimeout(()=>{
                                                                        introNodos = introJs('#root');
                                                                        introNodos.setOptions({
                                                                            disableInteraction: false,
                                                                            doneLabel: translate('traducciones.introBotones.doneLabel'),
                                                                            nextLabel: translate('traducciones.introBotones.nextLabel'),
                                                                            prevLabel: translate('traducciones.introBotones.prevLabel'),
                                                                            tooltipPosition:'top',
                                                                            steps: [
                                                                                {
                                                                                    intro: translate('traducciones.introDashboard.intro1'),
                                                                                    title: translate('traducciones.introDashboard.title1')
                                                                                },
                                                                                {
                                                                                    intro: translate('traducciones.introDashboard.intro2'),
                                                                                    title: translate('traducciones.introDashboard.title2'),
                                                                                    element: "#main-content",
                                                                                    scrollTo: "off"
                                                                                },
                                                                                {
                                                                                    intro: translate('traducciones.introDashboard.intro3'),
                                                                                    title: translate('traducciones.introDashboard.title3'),
                                                                                    element: ".MuiDrawer-root",
                                                                                    scrollTo: "off",
                                                                                    position: "right"
                                                                                },
                                                                                {
                                                                                    intro: translate('traducciones.introDashboard.intro4'),
                                                                                    title: translate('traducciones.introDashboard.title4'),
                                                                                    element: "#querys",
                                                                                    scrollTo: "off",
                                                                                    position: "right"
                                                                                },
                                                                                {
                                                                                    intro: translate('traducciones.introDashboard.intro5'),
                                                                                    title: translate('traducciones.introDashboard.title5'),
                                                                                    element: "#digitalizacion",
                                                                                    scrollTo: "#querys",
                                                                                    position: "right"
                                                                                },
                                                                                {
                                                                                    intro: translate('traducciones.introDashboard.intro6'),
                                                                                    title: translate('traducciones.introDashboard.title6'),
                                                                                    element: "#administracion",
                                                                                    scrollTo: "off",
                                                                                    position: "right"
                                                                                },
                                                                                {
                                                                                    intro: translate('traducciones.introDashboard.intro7'),
                                                                                    title: translate('traducciones.introDashboard.title7'),
                                                                                    element: "#estructura",
                                                                                    scrollTo: "off",
                                                                                    position: "right"
                                                                                },
                                                                                {
                                                                                    intro: translate('traducciones.introDashboard.intro8'),
                                                                                    title: translate('traducciones.introDashboard.title8'),
                                                                                    element: "#userMenu",
                                                                                    scrollTo: "off",
                                                                                    position: "right"
                                                                                }
                                                                            ]
                                                                        });
                                                                
                                                                        introNodos.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).onexit(()=>{showHelp()}).oncomplete(()=>{showHelp()}).start();
                                                                    },800)
                                                                }
                                                                return true;
                                                            })
                                                            .catch(error => {
                                                                setError(error);
                                                                setLoading(false);
                                                            })
                                                        })
                                                        .catch(error => {
                                                            setError(error);
                                                            setLoading(false);
                                                        })
                                                })
                                                .catch(error => {
                                                    //setError(error);
                                                    setLoading(false);
                                                })
                                        })
                                        .catch(error => {
                                            //setError(error);
                                            setLoading(false);
                                        })
                                    })
                                    .catch(error => {
                                        //setError(error);
                                        setLoading(false);
                                    })
                            })
                            .catch(error => {
                                //setError(error);
                                setLoading(false);
                            })

                        }else{
                            setLoading(false);
                        }
                        /////
                    })
                    .catch(error => {
                        //setError(error);
                        setLoading(false);
                    })
            })
            .catch(error => {
                //setError(error);
                setLoading(false);
            })
    }, []);

    if(loading){
        return <Loading />
    }

    if (error) return <Error error={ error } />;

    const getIcon = (type) => {
        switch (type) {
            case "PDF":
                return (<FontAwesomeIcon icon= { faFilePdf } />);    
            case "XLSX":
                return (<FontAwesomeIcon icon= { faFileExcel } />); 
            case "DOCX":
                return (<FontAwesomeIcon icon= { faFileWord } />);  
            case "XLS":
                return (<FontAwesomeIcon icon= { faFileExcel } />); 
            case "DOC":
                return (<FontAwesomeIcon icon= { faFileWord } />); 
            case "JPEG":
                return (<FontAwesomeIcon icon= { faFileImage } />);  
            case "JPG":
                return (<FontAwesomeIcon icon= { faFileImage } />);  
            case "PNG":
                return (<FontAwesomeIcon icon= { faFileImage } />);
            case "XML":
                return (<FontAwesomeIcon icon= { faFileCode } />);
            default:
                return (<FontAwesomeIcon icon= { faFileAlt } />); 
        }
    }

    const handleChangueClient = (e) =>{
        setClientStorage(e.target.value)
    }
  
  return (
    <div>
    <div className='listaMovil' style={{minHeight:'62vh'}}>
        {idRol == 0 && !isMulticliente && dataClients && storageGral ? (
            <div style={{display:'flex', justifyContent:'space-between',alignItems:'baseline', marginTop:'-1em', marginBottom:'-3em'}}>
                <div style={{width:'40%'}}>
                    <input 
                        type='text' 
                        className='form-control' 
                        hidden={!tablero} 
                        style={{marginLeft:'1rem'}} 
                        placeholder='Ingrese nombre del cliente'
                        onChange={handleChangueClient}
                    />
                </div>
                <div>
                    <SimpleForm toolbar={null}>
                        <BooleanInput 
                        label="Mostrar tablero de clientes" 
                        source="commentable" 
                        onChange={(e)=>{setTablero(e)}}
                    />
                    </SimpleForm>
                </div>
            </div>
        ): null}
        {tablero ? 
        (
            <TableroStorage 
                dataClients={clienteStorage == '' ? dataClients : dataClients.filter((cliente)=> cliente.CustomerName.toLowerCase().includes(clienteStorage.toLowerCase()))}
                storageGral={storageGral}/>
        ):(
            <>
            { tiposDocumentos.length >= 0 ? (

            <div style= {{ padding:'15px'}}>
                <div class="row" style={{ marginBottom: '1em', color: 'rgb(118 125 133)'}}>
                    { tiposDocumentos.length > 0 && tiposDocumentos.map(
                        (item) => {
                            return (
                            <div class="col-md-3" style={{ paddingLeft:'3px', paddingRight: '3px',fontFamily: 'Source Code Variable', fontWeight:'400', letterSpacing:'-0.5px', fontSize:'13px' }}>
                                <div class="small-box">
                                    <div class="inner">
                                        <h3>{ item.Total }</h3>
                                        <p>{ translate('traducciones.dash.documentos') } { item.Description } { translate('traducciones.dash.digitalizados')}</p>
                                    </div>                    
                                    <div class="icon">
                                        { getIcon(item.Description) }           
                                    </div>
                                </div>		
                            </div>
                        )})
                    }     
                        <div class="col-md-3" style={{ paddingLeft:'3px', paddingRight: '3px',fontFamily: 'Source Code Variable', fontWeight:'400', letterSpacing:'-0.5px', fontSize:'13px' }}>
                            <div class="small-box">
                                <div class="inner">
                                    <h3>{ capturedDocuments.Total }</h3>
                                    <p>{ capturedDocuments.Description }</p>
                                </div>                    
                                <div class="icon">
                                    { getIcon(capturedDocuments.Description) }           
                                </div>
                            </div>		
                        </div> 

                        <div class="col-md-3" style={{ paddingLeft:'3px', paddingRight: '3px', fontFamily: 'Source Code Variable', fontWeight:'400', letterSpacing:'-0.5px', fontSize:'13px' }}>
                            <div class="small-box">
                                <div class="inner">
                                    <h3>{ totalPagesCapturedDocuments.Total }</h3>
                                    <p>{ totalPagesCapturedDocuments.Description }</p>
                                </div>                    
                                <div class="icon">
                                    { getIcon(totalPagesCapturedDocuments.Description) }           
                                </div>
                            </div>		
                        </div>
                    <div className="col-md-3">
                        <div class="small-box" style={{paddingLeft:'3px', paddingRight: '3px', fontFamily: 'Source Code Variable', fontWeight:'400', letterSpacing:'-0.5px', fontSize:'13px',letterSpacing:'-0.5px'}}>
                            <div class="inner">
                                <h3>{ usuarios ? usuarios.Total : "--" }</h3>
                                <p>{ usuarios ? usuarios.Description : "--" }</p>
                            </div>                    
                            <div class="icon">
                                <FontAwesomeIcon icon= { faUser } />                     
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div class="small-box" style={{paddingLeft:'3px', paddingRight: '3px', fontFamily: 'Source Code Variable', fontWeight:'400', letterSpacing:'-0.5px', fontSize:'13px',letterSpacing:'-0.5px'}}>
                            <div class="inner">
                                <h3>{ documentsPendingCapture.Total }</h3>
                                <p>{ documentsPendingCapture.Description}</p>
                            </div>                    
                            <div class="icon">
                                <FontAwesomeIcon icon= { faFile } />                     
                            </div>
                        </div>
                    </div>
                </div>
                <hr className='hr-text' data-content={''}/>
                <div className='row' style={{justifyContent:'space-between'}}>    
                    <div className="col-md-3">
                            <Bar data={fechaDigitalizacion}  />
                    </div>
                    {datosDocumentosPendientesTipo.labels.length > 0 ? (
                        <div className="col-md-3" style={{ padding: '3%', paddingTop: '0'}}>
                            <Doughnut data={datosDocumentosPendientesTipo} />
                        </div>
                    ): null}
                    <div className='col-md-3' style={{fontFamily: 'Source Code Variable !important', fontWeight:'400', fontSize:'13px'}}>
                        <Bar data={espacioUtilizado} options= { { 
                            indexAxis: 'y', 
                            interaction: {
                                mode: 'index',
                                intersect: false,
                            },
                            scales: {
                                x: {
                                    stacked: true,
                                },
                                y: {
                                    stacked: true,
                                },
                            },
                        }} />
                    </div>
                </div>
            </div>
            ) : (

                <div style= {{ textAlign: 'center',   marginTop: '15vh', color: '#979797'}}>
                    <h3>MAXIMAGE AIIDARA, { translate('traducciones.generales.dashtitle') }</h3>
                    <p>
                    <b>{ translate('traducciones.generales.step1') }</b> { translate('traducciones.generales.dashstep1') }
                    </p>
                    <p>
                    <b>{ translate('traducciones.generales.step2') }</b> { translate('traducciones.generales.dashstep2') }
                    </p>
                    <p>
                    <b>{ translate('traducciones.generales.step3') }</b> { translate('traducciones.generales.dashstep3') }  
                    </p>
                </div>
            ) 
            }
            </>
        )}
        </div>
        <MyFooter/>
        </div>
)
  };

export default DashBoard;

