import React from 'react'
import '../login.css'
import { useState, useEffect } from 'react';
import { useTranslate } from 'react-admin';
import fbIcon from '../images/facebook.png'
import igIcon from '../images/instagram.png'
import linkeIcon from '../images/linkedin.png'
import yotubeIcon from '../images/youtube.png'
import whatsAppIcon from '../images/whatsapp.png'
import logoMaxi from '../images/hc.png'
import certificate27001 from '../images/27001c.png'
import certificate27701 from '../images/27701c.png'
import { appVersion } from '../helpers/helpers';

function MyFooter() {
    const [isMobile, setIsMobile] = useState(false);
    const translate = useTranslate();

    useEffect(() => {
        const checkDevice = () => {
        setIsMobile(window.innerWidth <= 768);
        };

        // Check on initial render
        checkDevice();

        // Add event listener for window resize
        window.addEventListener('resize', checkDevice);

        // Clean up event listener on component unmount
        return () => {
        window.removeEventListener('resize', checkDevice);
        };
    }, []);

  return (
    <footer className='pieLogin2'>
        <hr className='hr-text' data-content={''}/>
        <div className='row' style={{display:'flex', alignItems: 'center'}}>
            <div className='col-md-4' id='martop2'>
                <span style={{fontWeight:'500'}}>{translate('traducciones.login.siguenos')}</span>
                <a href="https://www.facebook.com/maximage.data.systems/" target="_blank" rel="noopener noreferrer"><img src={fbIcon} className='redesIcono2'/></a>
                <a href="https://www.instagram.com/maximage_data_systems/" target="_blank" rel="noopener noreferrer"><img src={igIcon} className='redesIcono2' /></a>
                <a href="https://mx.linkedin.com/company/maximage-ds" target="_blank" rel="noopener noreferrer"><img src={linkeIcon} className='redesIcono2' /></a>
                <a href="https://wa.me/5611601051" target="_blank" rel="noopener noreferrer"><img src={whatsAppIcon} className='redesIcono2' /></a>
                <a href="https://www.youtube.com/channel/UCwc2SbDsiSF9tTHq-UbdmGA/featured" target="_blank" rel="noopener noreferrer"><img src={yotubeIcon} className='redesIcono2'/></a>
                <br/>
                <a href='#' style={{cursor:'pointer', textDecoration: 'none', color:'black', fontWeight:'500'}} target="_blank" rel="noopener noreferrer">{translate('traducciones.login.aviso')} </a> 
                <a href='https://www.maximage-ds.com/copia-de-contacto' style={{cursor:'pointer', textDecoration: 'none', color:'black',fontWeight:'500'}} target="_blank" rel="noopener noreferrer">{translate('traducciones.login.contactenos')} </a> 
                <a href='#' style={{cursor:'pointer', textDecoration: 'none', color:'black',fontWeight:'500'}} target="_blank" rel="noopener noreferrer">{translate('traducciones.login.ayuda')} </a> 
            </div>
            <div className='col-md-4'>
                <img src={certificate27001} className='iconCertificate2'/>
                <img src={logoMaxi} className='iconMaxi2'/>
                <img src={certificate27701} className='iconCertificate2'/>
            </div>
            <div className='col-md-4' id='martop2'>
                <span> ©️ {(new Date().getFullYear())} {appVersion}</span>
            </div>
        </div>
    </footer>
  )
}

export default MyFooter