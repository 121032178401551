import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    FormWithRedirect,
    TextInput,
    useDelete,
    useTranslate,
    SimpleForm,
    Loading,
    NumberInput,
    SaveButton,
    DateInput,
    useDataProvider,
    Pagination,
    useNotify,
    SelectInput,
    useEditController,
    Edit,
    BooleanInput
} from 'react-admin';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import SearchIcon from '@material-ui/icons/Search';
import WarningIcon from '@material-ui/icons/Warning';
import withWidth from '@material-ui/core/withWidth';
import CardResultado from './Responsive/CardResultado';
import ExternalViewer from '../Libs/ExternalViewer/ExternalViewer';
import TableResultado from '../Components/TableResultado';
import MyLoading from '../Components/MyLoading';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import '../Libs/ExternalViewer/Resources/Styles/digitalizarStyles.css'
import DownloadButton from '../Components/DownloadButton';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import swal from 'sweetalert';
import Loader from '../Components/Loader';
import MyFooter from '../Components/MyFooter';

/*
Componente dedicado a la busqueda predefinida 
*/

const PredefinidaCampos = ({ width, ...props }) => {

    const { record } = useEditController(props);
    // const [valorFijo, setValorFijo] = useState(null)
    const [ flag , setFlag] = useState(false)
    const [valores,setValores] = useState({})
    const [isCheck, setIsCheck] = useState([]);
    const [todosSeleccionados, setTodosSeleccionados] = useState(false);
    const inTour = JSON.parse(localStorage.getItem('activeTour'))
    const [allReady, setAllReady] = useState(1);
    const [openEspera, setOpenEspera] = useState(false);
    const [hidePorcentage, setHidePorcentage] = useState(false);
    let valoresQuery;
    const [fieldWithLike, setFieldWithLike] = useState([])

    useEffect(()=>{
        const regex = /%LIKE%/;
        if(regex.test(record.CadenaLogica)){
            const listFields = obtenerIdsConLike(record.CadenaLogica)
            setFieldWithLike(listFields)
            // console.log(listFields);
        }
    },[])

    let introPredefinidas;
    useEffect(() => {
        if(inTour.querys){
            setTimeout(()=>{
                introPredefinidas = introJs();
                introPredefinidas.setOptions({
                disableInteraction: false,
                doneLabel: translate('traducciones.introBotones.doneLabel'),
                nextLabel: translate('traducciones.introBotones.nextLabel'),
                prevLabel: translate('traducciones.introBotones.prevLabel'),
                tooltipPosition:'top',
                steps: [
                    {
                        intro: translate('traducciones.introPreCampos.intro1'),
                        title: translate('traducciones.introPreCampos.title1'),
                        element: '#NameQuery'
                    },
                    {
                        intro: translate('traducciones.introPreCampos.intro2'),
                        title: translate('traducciones.introPreCampos.title2'),
                        element: '#camposQuery',
                        disableInteraction: false
                    },
                    {
                        intro: translate('traducciones.introPreCampos.intro3'),
                        title: translate('traducciones.introPreCampos.title3'),
                        element: '#btnQuery'
                    },
                    {
                        intro: translate('traducciones.introPreCampos.intro4'),
                        title: translate('traducciones.introPreCampos.title4'),
                        element: '.button-38'
                    },
                ]
                });        
    
                introPredefinidas.setOption("dontShowAgain", true)
                .setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).start();
            },1000)
        }
      }, []);

    const onToggleItem = (e) => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }
    }

    const handleSelectAll = () => {
        setTodosSeleccionados(!todosSeleccionados);
        if(dataSearch && dataSearch.data){
            let marcas = dataSearch.data.map(li => li.IdDocument + "");
            setIsCheck(marcas);
        }

        if (todosSeleccionados) {
          setIsCheck([]);
        }
    };
    
    useEffect(()=>{
        crearObjeto(record.CadenaLogica)
        // validateValue();
        separateFields();
        setTimeout(() => {
            setFlag(true)
        }, 500);
    }, [record])
    
    const separateFields = () => {
        const fields = []
        const newFields = []
        if(record.FieldList.length>0){
            record.FieldList.map((d)=>{
                if(!fields.includes(d.IdIndice)){
                    fields.push(d.IdIndice)
                    newFields.push(d)
                }else{
                    d.repetido=true;
                    d.IdIndice= d.IdIndice + '_1'
                    newFields.push(d)
                }
            })
            record.FieldList= newFields;
        }
    }

    // const validateValue = () =>{
    //     // Expresión regular para identificar el valor después del ID
    //     let regex = /\/\d+\/\d+\/=,\d+(?:,(\w+))?/g;
    //     let match = regex.exec(record.CadenaLogica);
    //     let valor = null;

    //     if (match != null) {
    //         valor = match[1];
    //         setValorFijo(valor)
    //     } else {
    //         setValorFijo(null)
    //     }
    // }

    function crearObjeto(cadena) {
        var objeto = {};
        var elementos = cadena.split("|");
      
        for (var i = 0; i < elementos.length; i++) {
          var partes = elementos[i].split(",");
          if (partes.length === 3) {
            var idValor =  partes[1].includes('Date') ? partes[1].replace('Date', '') : partes[1] ;
            var nombre = partes[2];
            if (objeto.hasOwnProperty(idValor)) {
                // Agregar sufijo "_1" al valor existente
                idValor = idValor + '_1';
            }
            objeto[idValor] = nombre;
          }
        }
        
       setValores(objeto)
      }

    /*
    Props. permisos es un arreglo de permisos que devuelve React Admin, en cada vista (funcion dentro del Auth Provider)
    */
    const permisos = props.permissions ? props.permissions.ConsultadeDocumentos : null;
    const checkPermiso = (permiso) => {
        if (permisos) {
            return permisos[permiso];
        }
        return false;
    }

    const [loadinga, setLoadinga] = useState(false);
    const [openNoResult, setOpenNoResult] = useState(false);
    const [openResult, setOpenResult] = useState(false);
    const [openDocument, setopenDocument] = useState(false);
    const [document, setDocument] = useState(null);
    const [deleteOne] = useDelete();
    const translate = useTranslate();
    const [dataSearch, setDataSearch] = useState([]);
    const [metas, setMetas] = useState(null);
    const [visor, setVisor] = useState(null);
    const notify = useNotify();
    const [openDeleteDocumento, setOpenDeleteDocumento] = useState(false);
    const [cargando,setCargando] = useState (false);
    const [allDocs,setAllDocs] = useState(null);

    const dataProvider = useDataProvider();

    //Paginación///////////////////////////////

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [order, setOrder] = useState("");
    const [sort, setSort] = useState(true);

    const changePage = (nPage) => {
        const startIndex = (nPage - 1) * perPage;
        const endIndex = startIndex + perPage;
        if(allReady != 2){
        setDataSearch([])
        setPage(nPage);
        reloadPages(nPage, perPage);
        }else{
            setOpenEspera(true)
        }
    }
    const ChangePerPage = (rows) => {
        // setPage(1);
        setPerPage(rows);
        reloadPages(page, rows);
    }

    const cambiaOrden = (column) => {
        setOrder(column);
        reloadPages(null, null, !sort, column);
        setSort(!sort);
    }

    const reloadPages = (npage, nperpage, nsort, norder) => {
        const startIndex = (npage - 1) * nperpage;
        const endIndex = startIndex + nperpage;
        if(endIndex > allDocs.data.length){
            setDataSearch([])
            setCargando(true)
            dataProvider.getList('queryPredefinida', {
                idQuery: record.IdQuery,
                PageNumber: 1,
                DocumentsNumber: endIndex + 100,
                OrderField: norder ? norder : order,
                AscendingOrder: nsort != null ? nsort : sort,
                Metadata: metas,
                DoNotShowMissingDocuments: hidePorcentage
            })
                .then((data) => {
                    if (data != null && data.data.length == 0) {
                        setCargando(false)
                        setOpenNoResult(true);
                    } else {
                        if (data != null && data.data.length > 0) {
                            const dataSlice = {
                                data: data.data.slice(startIndex, endIndex),
                                porcentaje: data.porcentaje,
                                total: data.total
                            }
                            setAllDocs(data)
                            setDataSearch(dataSlice);
                            setCargando(false)
                            setOpenResult(true);
                        }
                    }
                })
                .catch(error => {
                });
        }else{
            const dataSlice = {
                data: allDocs.data.slice(startIndex, endIndex),
                porcentaje: allDocs.porcentaje,
                total: allDocs.total
            }
            setDataSearch(dataSlice)
        }
    }

    const handleCloseClick = () => {
        setOpenNoResult(false);
        setOpenResult(false);
    }

    const handleCloseClickTabla = () => {
        if(allReady != 2){
            setOpenNoResult(false);
            setOpenResult(false);
        }else{
            setOpenEspera(true)
        }
    }

    if (loadinga || !flag) {
        return <Loading />
    }

    function obtenerIdsConLike(cadena) {
        // Expresión regular para buscar los IDs que siguen a %LIKE%
        const regex = /(\d+)%LIKE%/g;
        let idsConLike = [];
        let match;
    
        // Iterar sobre todas las coincidencias encontradas
        while ((match = regex.exec(cadena)) !== null) {
            // Agregar el ID encontrado al arreglo
            idsConLike.push(parseInt(match[1]));
        }
    
        return idsConLike
    }

    // function configLike(cadena) {
    //     let valor = ''
    //     if (cadena.includes('%') || cadena.includes('_') || cadena.includes('[')){
    //         valor = "%" + agregarSignoExclamacion(cadena) + "%"
    //     }else{
    //         valor = "%" + cadena + "%"
    //     }
    //     return valor
    // }

    // function agregarSignoExclamacion(cadena) {
    //     // Reemplazar cada ocurrencia de '%' o '_' o '[' con '!%' o '!_' o '![' respectivamente
    //     return cadena.replace(/[%_\[]/g, '!$&');
    // }

    const handleSubmitSearch = async values => {
        setAllDocs(null)
        setPage(1)
        setPerPage(10)
        setTodosSeleccionados(false)
        setIsCheck([])
        setCargando(true)
        var campos = Object.entries(values);
        valoresQuery = Object.entries(values);
        var meta = [];
        campos.forEach(function (rec, index) {
            if (rec[1] != undefined && rec[1] != null && rec[1] != "" && rec[0].includes("campo-")) {
                const idField = parseInt(rec[0].replace('campo-', ''),10)
                const detailsField = values.FieldList.find(objeto => objeto.IdIndice === idField)
                if(rec[0].includes('_1')){
                    const aux=rec[0].slice(0, -2)
                    rec[0]=aux;
                }
                if(typeof rec[1] == 'string'){
                    if(rec[1].trim() != ''){
                        meta.push(
                            {
                                "IdDocumento": "",
                                "IdIndice": rec[0].replace('campo-', ''),
                                "ValorLlave": rec[1].trim(),
                                "NombreIndice": detailsField.NombreIndice,
                                "IdNodo": detailsField.IdNodo
                            }
                        );
                    } 
                }else{
                    meta.push(
                        {
                            "IdDocumento": "",
                            "IdIndice": rec[0].replace('campo-', ''),
                            "ValorLlave": rec[1],
                            "NombreIndice": detailsField.NombreIndice,
                            "IdNodo": detailsField.IdNodo
                        }
                    );
                }
            }
        });
        setMetas(meta);
        dataProvider.getList('queryPredefinida', {
            idQuery: record.IdQuery,
            PageNumber: 1,
            DocumentsNumber: 100,
            OrderField: "",
            AscendingOrder: true,
            Metadata: meta,
            DoNotShowMissingDocuments: values.DoNotShowMissingDocuments ? values.DoNotShowMissingDocuments : false
        })
            .then((data) => {
                setCargando(false)
                if (data != null && data.data.length == 0) {
                    
                    setOpenNoResult(true);
                } else {
                    if (data != null && data.data.length > 0) {
                        setAllDocs(data)
                        const dataSlice = {
                            data: data.data.slice(0,10),
                            porcentaje: data.porcentaje,
                            total: data.total
                        }
                        setDataSearch(dataSlice);
                        setOpenResult(true);
                    }
                }
            })
            .catch(error => {
                setCargando(false)
            });
    }

    const getAllDocuments = (total) => {
        setAllReady(2)
        dataProvider.getList('queryPredefinida', {
            idQuery: record.IdQuery,
            PageNumber: 1,
            DocumentsNumber: total,
            OrderField: "",
            AscendingOrder: true,
            Metadata: metas,
        })
            .then((data) => {                
                if (data != null && data.data.length > 0) {
                    setAllDocs(data)
                    setAllReady(3)
                    setOpenEspera(false)
                    setOpenResult(true);
                }
            })

            .catch(error => {
                setCargando(false)
            });
    }

    const resetAllReady = () =>{
        setAllReady(1)
    }

    function addHyphensToDateString(dateString) {
        const year = dateString.slice(0, 4);
        const month = dateString.slice(4, 6);
        const day = dateString.slice(6, 8);
        return `${year}-${month}-${day}`;
      }

    const consultaDocumento = (elem) => {
        //Obtenemos la url completa y sustituimos el pathName por el pathname del visor con el parametro del id del documento
        const urlFull = window.location.href;
        const pathName = props.location.pathname;
        let urlHost = urlFull.replace(pathName,`/visor?id_document=${elem.IdDocument}`)
        
        /*
        La funcion consulta documento se cambio  por esta linea para abrir una nueva ventana  y mostrar el visor, se obtine el nombre del host y se pasa 
        el id del documento como parametro en la URL para su posterior consulta.
         */
        window.open(urlHost, '_blank', 'fullscreen=yes');
    }

    /*
    Esta funcion sera la encargada de obtener las anotaciones desde el visor y guardarlas mediante las apis de MAxImage
    */
    const saveAnotations = () => {
        /*
        Ejecutaremos la funcion getAnnotations(), funcion desarrollada para obtener las antoaciones del visor en 
        formato XML, para poder fuardarlas en la api. Esta funcion se encuentra en public/Main/Main.js en la linea 2266
        */
        var anotations = visor.getAnnotations();
        if (anotations.length > 0) {
            dataProvider.update('anotaciones', { DocumentId: document.IdDocument, Annotations: anotations })
                .then(({ data }) => {
                    swal({
                        icon:'success',
                        text: translate('traducciones.notificaciones.actualizaAnotacionesOK')
                    })
                })
                .catch(error => {
                });
        }
    }

    const closeExternal = () => {
        setOpenResult(true);
        setopenDocument(false);
    }


    const deleteDocument = (doc) => {
        setDocument(doc);
        setOpenDeleteDocumento(true);
    }


    const handleSubmitDeleteDocument = async values => {
        deleteOne('documento', document.IdDocument, document, {
            onSuccess: ({ data }) => {
                changePage(page);
                setOpenDeleteDocumento(false);
                setLoadinga(false);
                swal({
                    icon:'success',
                    text: translate('traducciones.notificaciones.docEliminado')
                })
            },
            onFailure: ({ error }) => {
                setLoadinga(false);
            }
        });
    };

    const handleCloseClick2 = () => {
        setOpenDeleteDocumento(false);
    }

    var count = 0;

    const handleClose = () => {
        props.history.push("/predefinida");
    };

    let startIndex = (page - 1) * perPage;

    const handleChangeHide = (e) =>{
        setHidePorcentage(e);
    }

    if (flag){
            return (
            <Edit {...props} title={translate('traducciones.titulos.busquedaPredefinida')} >
                <SimpleForm {...props} toolbar={null} id='container'>
                    <div id='back'>
                    <button className="button-38"  onClick={handleClose}><ArrowBackIcon style={{marginRight:'5px'}}/><span>{translate('traducciones.botones.volver')}</span></button>
                    </div><br/>
                    <div id='NameQuery'>
                        <h3>{ translate('traducciones.titulos.busquedaPredefinida')} : { record.NombreBusqueda }</h3>
                    </div>
                    {/* 
                    External Viewer es el componente desarrollado para poder respresentar el html del visor de LeadTools en React.
                    Este componente contiene el html codificado para react, obtenido desde el archivo Libs/ExternalViewer/index.html
                    en este caso enviaremos al componente dos funciones locales:
                    closeExternal, que cerrara el visor
                    saveAnotations, que guardara las anotaciones cuando el usuario lo indique.
                    */}
    
                    {openDocument ? (
                        <ExternalViewer closeExternal={closeExternal} saveAnotations={saveAnotations} />
                    ) : (
                        <div fullWidth>
                            <div style={{ padding: '10px' }}>                            
                                <SimpleForm save={handleSubmitSearch} toolbar={<SaveButton label={translate('traducciones.botones.consultar')} icon={<SearchIcon />} disabled={cargando} id='btnQuery'/>} id='camposQuery'>
                                    <div className="row" fullWidth>
                                        {record.FieldList.map(
                                            (item) => (
                                                <div className="col-md-6">
                                                    {item.Tipo == 1 && !item.ValoresTabla ? (<TextInput style={{ width: "100%" }} defaultValue={ valores[item.IdIndice] != undefined ? valores[item.IdIndice] : '' } disabled={valores[item.IdIndice] != undefined} type="text" name={"campo-" + item.IdIndice + ""} label={"Campo " + item.NombreIndice} source={item.IdIndice} />) : null}
    
                                                    {item.Tipo == 7 && item.ValoresTabla && !fieldWithLike.includes(item.IdIndice) ? (
                                                        <div>
                                                            <SelectInput style={{ width: "100%" }} name={"campo-" + item.IdIndice + ""} resettable={true} label={"Campo " + item.NombreIndice} source={item.IdIndice} choices={item.ValoresTabla.map(value => ({ id: value, name: value }))} />
                                                        </div>
                                                    ) : item.Tipo == 7 && item.ValoresTabla && fieldWithLike.includes(item.IdIndice) ? (<TextInput style={{ width: "100%" }} defaultValue={ valores[item.IdIndice] != undefined ? valores[item.IdIndice] : '' } disabled={valores[item.IdIndice] != undefined} type="text" name={"campo-" + item.IdIndice + ""} label={"Campo " + item.NombreIndice} source={item.IdIndice} />) : null }
    
                                                    {item.Tipo == 2 ? (<NumberInput style={{ width: "100%" }} name={"campo-" + item.IdIndice + ""} resettable={true} label={"Campo " + item.NombreIndice} source={item.IdIndice} value="" defaultValue={ valores[item.IdIndice] != undefined ? valores[item.IdIndice] : '' } disabled={valores[item.IdIndice] != undefined} />) : null}
                                                    {item.Tipo == 3 ? (<TextInput style={{ width: "100%" }} type="text" name={"campo-" + item.IdIndice + ""} resettable={true} label={"Campo " + item.NombreIndice} source={item.IdIndice} value="" defaultValue="" />) : null}
                                                    {item.Tipo == 4 ? (<DateInput style={{ width: "100%" }} name={"campo-" + item.IdIndice + "]"} resettable={true} label={"Campo " + item.NombreIndice} source={item.IdIndice} value="" defaultValue={ valores[item.IdIndice] != undefined ? addHyphensToDateString(valores[item.IdIndice]) : '' } disabled={valores[item.IdIndice] != undefined}/>) : null}
                                                    {item.Tipo == 5 ? (<TextInput style={{ width: "100%" }} type="time" name={"campo-" + item.IdIndice + ""} resettable={true} label={"Campo " + item.NombreIndice} source={item.IdIndice} value="" defaultValue="" />) : null}
                                                    {item.Tipo == 6 ? (<TextInput style={{ width: "100%" }} type="email" name={"campo-" + item.IdIndice + ""} resettable={true} label={"Campo " + item.NombreIndice} source={item.IdIndice} value="" defaultValue="" />) : null}
                                                </div>
                                            ))
                                        }
                                        <BooleanInput source='DoNotShowMissingDocuments' defaultValue={true} onChange={handleChangeHide} label={translate('traducciones.botones.documentosFaltantes')}/>
                                    </div>
                                </SimpleForm>     
                            </div>
                        </div>
    
                    )}
    
    
                    <Dialog
                        fullWidth
                        open={openNoResult}>
                        <DialogTitle>{translate('traducciones.titulos.resultadoBusqueda')}</DialogTitle>
                        <DialogContent>
                            <WarningIcon style={{ color: '#c18a2d', fontSize: '60px' }} /> {translate('traducciones.modal.noResults')}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseClick} >
                                {translate('ra.action.close')}
                            </Button>
                        </DialogActions>
                    </Dialog>
    
                    <Dialog
                        fullWidth
                        open={openDeleteDocumento}
                    >
                        <DialogTitle>{translate('traducciones.modal.eliminarDocumento')}</DialogTitle>
                        <FormWithRedirect
                            resource="nodos"
                            save={handleSubmitDeleteDocument}
                            render={({
                                handleSubmitWithRedirect,
                                pristine,
                                saving
                            }) => (
                                <>
                                    <DialogContent>
                                        {translate('traducciones.modal.eliminarDocumentoConfirm')}
                                    </DialogContent>
                                    <DialogActions>
                                        <SaveButton
                                            label="ra.action.confirm"
                                            handleSubmitWithRedirect={
                                                handleSubmitWithRedirect
                                            }
                                            pristine={pristine}
                                            saving={saving}
                                            icon={<DeleteIcon />}
                                        />
                                        <Button onClick={handleCloseClick2} >
                                            {translate('ra.action.cancel')}
                                        </Button>
                                    </DialogActions>
                                </>
                            )}
                        />
                    </Dialog>
    
    
                    <Dialog
                        fullWidth
                        open={openResult}>
                        <DialogTitle>{translate('traducciones.titulos.resultadoBusqueda')}</DialogTitle>
                        <DialogContent>
                        {cargando ? <MyLoading style={{marginTop:'-20px'}}/> : width != "xs" ? 
                        (
                        <TableResultado 
                          dataSearch= { dataSearch } 
                          consultaDocumento = { consultaDocumento } 
                          deleteDocument = { deleteDocument} 
                          cambiaOrden = { cambiaOrden } 
                          order = {order}
                          sort = { sort }
                          checkPermiso = { checkPermiso }
                          perPage= {perPage} 
                          setPage= {changePage} 
                          setPerPage = {ChangePerPage} 
                          page={page}
                          allDocuments={allDocs}
                          getAllDocuments={getAllDocuments}
                          allReady={allReady}
                          resetAllReady = {resetAllReady}
                          hidePorcentage = {hidePorcentage}
                        />  
                        ) : ( 
                            <div>
                            {
                            dataSearch && dataSearch.data && dataSearch.data.map((elem,ind) => {  
                                count++;
                                startIndex++;
                                    return (  
                                        <CardResultado dataSearch= {dataSearch} i = {startIndex} cambiaOrden= {cambiaOrden} order= {order} sort={sort} elem = {elem} onToggleItem={onToggleItem} todosSeleccionados={todosSeleccionados} isCheck={isCheck}/>
                                    )                
                                })
                             }
                        <input type='checkbox'
                            style={{padding: "0px", marginBottom:'10px'}}
                            id='checkall'
                            name='checkall'
                                checked={todosSeleccionados}
                                onClick={handleSelectAll}
                        />
                        <span style={{marginLeft:'10px', color: '#30b165'}}><b>{translate('traducciones.campos.checkMarcar')}</b></span>
                        <DownloadButton isCheck={isCheck} dataSearch={dataSearch} allDocuments={allDocs} todosSeleccionados={todosSeleccionados}/>
                            <Pagination perPage= {perPage} setPage= {changePage} setPerPage = {ChangePerPage} page={page} total= {dataSearch.total} />
                            </div>)}
                            {/* {width != "xs" ? (
                                <TableResultado
                                    dataSearch={dataSearch}
                                    consultaDocumento={consultaDocumento}
                                    deleteDocument={deleteDocument}
                                    cambiaOrden={cambiaOrden}
                                    order={order}
                                    sort={sort}
                                    esBastanteo={true}
                                    checkPermiso={checkPermiso}
                                    perPage={perPage}
                                    setPage={changePage}
                                    setPerPage={ChangePerPage}
                                    page={page}
                                />
                            ) : (
                                <div>
                                    {
                                        dataSearch && dataSearch.data && dataSearch.data.map(elem => {
                                            count++;
                                            return (
                                                <CardResultado dataSearch={dataSearch} i={count} cambiaOrden={cambiaOrden} order={order} sort={sort} elem={elem} />
                                            )
                                        })
                                    }
                                    <Pagination perPage={perPage} setPage={changePage} setPerPage={ChangePerPage} page={page} total={dataSearch.total} />
                                </div>)} */}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseClickTabla} >
                                {translate('ra.action.close')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                        <Dialog open={openEspera}>
                        <DialogContent>
                            <div>
                            <Loader/>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={()=>setOpenEspera(false)}>
                                { translate('ra.action.close')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                <MyFooter/>
                </SimpleForm>
            </Edit>
        )
    }
};

export default withWidth()(PredefinidaCampos);
