import * as React from 'react';
import { useState,useEffect } from 'react';
import { 
    useLogin, 
    useNotify, 
    Notification, 
    defaultTheme, 
    useTranslate,
    SimpleForm,
    SaveButton,
    Button,
    TextInput,
    required,
    useDataProvider,
    Toolbar,
    Loading,
    PasswordInput 
} from 'react-admin';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import EmailIcon from '@material-ui/icons/Email';
import "../login.css"
import swal from 'sweetalert';
import Store from '../Components/Store';
import { conektaFetch } from '../DataProviders/GlobalDataProvider';
import { appVersion, clientData } from '../helpers/helpers';
import CheckIcon from '@mui/icons-material/Check';
import aiidaraIcon from '../images/newaiidara.png'
import fbIcon from '../images/facebook.png'
import igIcon from '../images/instagram.png'
import linkeIcon from '../images/linkedin.png'
import yotubeIcon from '../images/youtube.png'
import whatsAppIcon from '../images/whatsapp.png'
import logoMaxi from '../images/hb.png'
import certificate27001 from '../images/27001c.png'
import certificate27701 from '../images/27701c.png'

export const LoginPage = ({ theme }) => {
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [openRecupera, setOpenRecupera] = useState(false);
    const [openCambia, setOpenCambia] = useState(false);
    const login = useLogin();
    const notify = useNotify();
    const [openPay, setOpenPay] = useState(false)
    const [openRegCustomer, setOpenRegCustomer] = useState(false) 
    const [disabledButton, setDisabledButton] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkDevice = () => {
        setIsMobile(window.innerWidth <= 768);
        };

        // Check on initial render
        checkDevice();

        // Add event listener for window resize
        window.addEventListener('resize', checkDevice);

        // Clean up event listener on component unmount
        return () => {
        window.removeEventListener('resize', checkDevice);
        };
    }, []);

    const resetDialogs = () =>{
        setOpenPay(false)
        setOpenRegCustomer(false)
    }
    
    const submit = e => {
        e.preventDefault();
        setLoading(true);
        const logoEmp = localStorage.getItem('logoEmpresa')
        localStorage.clear();
        localStorage.setItem('logoEmpresa',logoEmp)
        login({ user, password }).then((resp) => {
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            if (error.message == 'Suscripción Vencida, para tener acceso debe renovar la suscripción.') {
                handleOpenPay()
            } else {
                swal({
                    icon: "warning",
                    text: error.message
                  });
                error.message == 'Password temporal, renueve su password' || error.message== 'Usuario Nuevo, debe cambiar su password' || error.message == 'Password caducado, renueve su password' ? setOpenCambia(true) : setOpenCambia(false)
            }
        }
        );
    };

    const dataProvider = useDataProvider();

    const apiUrl = window.$parametros_generales.REACT_APP_BASE_URL;

    const getLogo = () => {
        fetch(apiUrl+'Login/getCustomerLogo',{
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json'}),
        }).then(( response ) => response.json())
        .then((data)=>{
            if (data.Information != null){
                const logo = 'data:image/svg+xml;base64,'+data.Information.Base64Logo
                const b64toSvg = atob(logo.split(",")[1]);

                let width = 125; // Ancho deseado en píxeles
                let height = 125; // Alto deseado en píxeles

                let modifiedSvg = b64toSvg.replace(/<svg[^>]*>/, function (match) {
                return match
                    .replace(/width="[^"]*"/, 'width="' + width + 'px"')
                    .replace(/height="[^"]*"/, 'height="' + height + 'px"');
                });

                //Se vuelve a pasar a formato b64
                let encodedSvg = btoa(modifiedSvg);
                localStorage.setItem('logoEmpresa',encodedSvg)
            }else{
                localStorage.setItem('logoEmpresa',null)
            }
        })
        .catch(error => {
            localStorage.setItem('logoEmpresa',null)
        });
    }

    useEffect(()=>{
        getLogo();
    },[])

    const handleRecupera = async values => {
        setDisabledButton(true)
        dataProvider.password('restore', { Email: values.emailRecupera})
        .then((json) => {
            setDisabledButton(false)
            notify(json.data.Message);
            setOpenRecupera(false);
            return;
        })
        .catch(error => {
            setDisabledButton(false)
        }); 
    }

    const handleCambiaPass = async values => {
        if(values.NewPassword !== values.pass2){
            swal({
                text:'Las contraseñas no coinciden', 
                icon: "error"
            });
            return;
        }
        dataProvider.password('change', { values })
        .then((json) => {
            if(json.data.Success){
                swal({
                    icon: 'success',
                    text: json.data.Message || '¡Password actualizado!'
                })
                setOpenCambia(false);
            }else{
                swal({
                    text: json.data.Message,
                    icon:'error'
                })
                return;
            }
        })
        .catch(error => {
            console.log(error);
            return;
        }); 
    };

    const translate = useTranslate();

    if(loading){
        return <div className='loading' style={{ marginTop: '10%'}} ><Loading loadingPrimary={translate('traducciones.titulos.ingresando')} /></div>
    }

    const viewLogo = () =>{
        if(localStorage.getItem('logoEmpresa')==null || localStorage.getItem('logoEmpresa')=='null'){
           
        }else{
            return(
                <div style={{display:'contents'}}>
                    <hr style={{width:'5%',rotate:'90deg', border:'1px solid',display:'inline-flex', marginBottom:'3px'}}/>
                    <img src={"data:image/svg+xml;base64,"+localStorage.getItem('logoEmpresa')} width='130vh' style={{marginLeft:'-5px'}} alt=''/>
                </div>
            )
        }
    }

    const handleOpenPay = () =>{
        swal({
            icon: "info",
            text: 'Suscripción Vencida, para tener acceso debe renovar la suscripción.',
            buttons:{
                cancel: {
                  text: translate('traducciones.campos.cancelar'),
                  value: false,
                  visible: true,
                  className: "bg-danger text-white",
                  closeModal: true,
                },
                confirm: {
                  text: 'Renovar suscripción',
                  value: true,
                  visible: true,
                  className: "bg-success text-white",
                  closeModal: true
                }
              }
          }).then((value) => {
                if (value) {
                    setOpenRegCustomer(true)
                    // setOpenPay(true) 
                }
            });
        }

        const helpers = [
            {
                "CustomerId": "CI-000000082",
                "PlatformCustomerId": "TrialMonth",
                "email":'cdiaz+1@maximage-ds.com'
            },
            {
                "CustomerId": "CI-000000084",
                "PlatformCustomerId": "cus_2vJagidG927zQvu7a",
                "email":'cdiaz+99@maximage-ds.com'
            },
            {
                "CustomerId": "CI-000000087",
                "PlatformCustomerId": "cus_2vJbJvdq4CzTzQdgz",
                "email":'cdiaz+10@maximage-ds.com'
            },
            {
                "CustomerId": "CI-000000086",
                "PlatformCustomerId": "TrialMonth",
                "email":'cdiaz+30@maximage-ds.com'
            }
        ]

        function findHelperByEmail(email) {
            return helpers.find(helper => helper.email === email);
        }
        
        const handleRegCustomer = async(values) =>{
            // Consultamos el listado de customers para validar si cuenta con un CustomerId
            const customers = await conektaFetch('getCustomers', "customers?limit=250")
            
            // Se valida si cuenta con registros para la validación
            if (customers.data && customers.data.length > 0) {
                // console.log('Customers registrados->', customers);
                // console.log('Validar correo ->', values.Email);
                
                //Se verifica si la cuenta no esta registrada
                const listCustomers = customers.data.filter((customer) => customer.email == values.Email);
                if(listCustomers.length > 0){
                    // console.log('El correo '+values.Email+' ya cuenta con un CustomerId',listCustomers[0]);
                    // Se asignan los datos
                    const idCus = values.Email
                    // clientData.CustomerID = idCus.CustomerId
                    clientData.PlatformCustomerId = listCustomers[0].id
                    setOpenRegCustomer(false)
                    setOpenPay(true) 
                    
                }else{
                    // console.log('El correo '+values.Email+' aun no cuenta con un CustomerId, debe registrarse');
                    // Se registra el cliente en Conekta
                    const newCustomer = await conektaFetch('regCustomer','customers',{ corporate: false, email: values.Email , name: 'NameUser'})
                    if(newCustomer){
                        // Se asignan los datos
                        const idCus = values.Email
                        // clientData.CustomerID = idCus.CustomerId
                        clientData.PlatformCustomerId = newCustomer.id
                        setOpenRegCustomer(false)
                        setOpenPay(true) 
                    }
                }
            }else{
                console.log('Sin datos para validar');
            }
    }

    if(localStorage.getItem('token')){
        window.location.href='#/dashboard'
    }

    return (
        <ThemeProvider theme={createTheme(defaultTheme)}>
            <div className='containerLogin' style={{background:'url(fondoNuevo.png)', backgroundSize: '100% 100%', height:'100vh'}}>
                <div className='row'>
                    <div className='col-md-6' style={{opacity:0}}>
                    </div>
                    <div className='col-md-6 col-sm-12'>
                        <div className="content_login">
                            <div className='content_img'>
                                <img src={aiidaraIcon} className='logoAiidara'/>
                            </div>
                            <SimpleForm onSubmit={submit} className="formInicio" toolbar={null}>
                                <TextInput
                                    label={translate('traducciones.campos.usuario')}
                                    name="text"
                                    value={user}
                                    style={{width: '100%'}}
                                    onChange={e => setUser(e.target.value)}
                                    inputProps={{ style: { fontFamily:'Source Code Variable', fontWeight:'600' } }}
                                    InputLabelProps={{style:{ fontFamily:'Source Code Variable', fontWeight:'600' }}}
                                />
                                <PasswordInput
                                    name="password"
                                    label={translate('traducciones.campos.contrasena')}
                                    value={password}
                                    style={{width: '100%'}}
                                    onChange={e => setPassword(e.target.value)}
                                    inputProps={{ style: { textTransform:'none', fontWeight:'600'} }}
                                    InputLabelProps={{style:{ textTransform:'none', fontWeight:'600'}}}
                                />
                                <div style={{textAlign: 'center'}}>
                                    <div className="password_recovery">
                                        <a onClick={e => setOpenRecupera(true)}>{translate('traducciones.login.olvidepass')}</a>
                                    </div>
                                    <input type="submit" value={translate('traducciones.botones.login')} />
                                </div>
                            </SimpleForm>
                        </div>
                    </div>
                </div>
                <footer className='pieLogin'>
                    <div className='row' style={{display:'flex', alignItems: 'center'}}>
                        <div className='col-md-4' id='martop'>
                            <span style={{fontWeight:'400'}}>{translate('traducciones.login.siguenos')}</span>
                            <a href="https://www.facebook.com/maximage.data.systems/" target="_blank" rel="noopener noreferrer"><img src={fbIcon} className='redesIcono'/></a>
                            <a href="https://www.instagram.com/maximage_data_systems/" target="_blank" rel="noopener noreferrer"><img src={igIcon} className='redesIcono' /></a>
                            <a href="https://mx.linkedin.com/company/maximage-ds" target="_blank" rel="noopener noreferrer"><img src={linkeIcon} className='redesIcono' /></a>
                            <a href="https://wa.me/5611601051" target="_blank" rel="noopener noreferrer"><img src={whatsAppIcon} className='redesIcono' /></a>
                            <a href="https://www.youtube.com/channel/UCwc2SbDsiSF9tTHq-UbdmGA/featured" target="_blank" rel="noopener noreferrer"><img src={yotubeIcon} className='redesIcono'/></a>
                            <br/>
                            <a href='#' style={{cursor:'pointer', textDecoration: 'none', color:'white'}} target="_blank" rel="noopener noreferrer">{translate('traducciones.login.aviso')} </a> 
                            <a href='https://www.maximage-ds.com/copia-de-contacto' style={{cursor:'pointer', textDecoration: 'none', color:'white'}} target="_blank" rel="noopener noreferrer">{translate('traducciones.login.contactenos')} </a> 
                            <a href='#' style={{cursor:'pointer', textDecoration: 'none', color:'white'}} target="_blank" rel="noopener noreferrer">{translate('traducciones.login.ayuda')} </a>
                        </div>
                        <div className='col-md-4'>
                            <img src={certificate27001} className='iconCertificate'/>
                            <img src={logoMaxi} className='iconMaxi'/>
                            <img src={certificate27701} className='iconCertificate'/>
                        </div>
                        <div className='col-md-4' id='martop'>
                            <span> ©️ {(new Date().getFullYear())} {appVersion}</span>
                        </div>
                    </div>
                </footer>
            </div>
            <Dialog open={openRecupera}>
                <DialogTitle>{ translate('traducciones.titulos.restablecerContraseña')}</DialogTitle>
                <DialogContent>
                    <SimpleForm save={handleRecupera} noValidate toolbar = { 
                        <Toolbar style={{ justifyContent: "space-between" }}>
                            <SaveButton  label ="traducciones.login.restablecer" icon={<EmailIcon />} disabled={disabledButton}/> 
                            <Button label ="ra.action.cancel"  onClick={e => setOpenRecupera(false)} disabled={disabledButton}/>
                        </Toolbar> } >
                        <TextInput style={{ minWidth: "400px"}} source="emailRecupera" label="traducciones.campos.email" validate={required()} />
                    </SimpleForm>
                </DialogContent>
            </Dialog> 
            <Dialog open={openCambia}>
                <DialogTitle>{ translate('traducciones.titulos.cambioContraseña')}</DialogTitle>
                <DialogContent>
                    <SimpleForm save={handleCambiaPass} noValidate toolbar = { 
                        <Toolbar style={{ justifyContent: "space-between" }}>
                            <SaveButton  label ="traducciones.login.restablecer" icon={<EmailIcon />} /> 
                            <Button label ="ra.action.cancel"  onClick={e => setOpenCambia(false)} />
                        </Toolbar> } >
                        <TextInput style={{ minWidth: "400px"}} source="UserName" label="traducciones.campos.usuario" validate={required()} />
                        <PasswordInput  style={{ minWidth: "400px"}} source="CurrentPassword" label="traducciones.campos.passwordOld" validate={required()} inputProps={{ style: { textTransform:'none'} }} InputLabelProps={{style:{ textTransform:'none'}}} />
                        <PasswordInput  style={{ minWidth: "400px"}} source="NewPassword" label="traducciones.campos.passwordNew" validate={required()} inputProps={{ style: { textTransform:'none'} }} InputLabelProps={{style:{ textTransform:'none'}}}/>
                        <PasswordInput  style={{ minWidth: "400px"}} source="pass2" label="traducciones.campos.passwordNewRepeat" validate={required()} inputProps={{ style: { textTransform:'none'} }} InputLabelProps={{style:{ textTransform:'none'}}}/>
                    </SimpleForm>
                </DialogContent>
            </Dialog>

            {/* Dialog para renovar suscripción */}
            <Dialog open={openPay} disableEnforceFocus>
                <DialogTitle>Tienda</DialogTitle>
                <DialogContent>
                    <SimpleForm toolbar={
                        <Toolbar style={{ justifyContent: "center" , backgroundColor: 'white'}}>
                            <Button label ="Cerrar"  onClick={e => setOpenPay(false)} style={{fontWeight:'700',fontSize:'0.85rem'}}/>
                        </Toolbar>
                    }>
                        <Store resetDialogs={resetDialogs} extra={clientData.TemplateWasUsed}/>
                    </SimpleForm>
                </DialogContent>
            </Dialog>

            {/* Dialogo para registrar el Customer en Conekta y poder realizar pago */}
            <Dialog open={openRegCustomer}>
                <DialogTitle>Confirmar correo</DialogTitle>
                <DialogContent>
                    <SimpleForm save={handleRegCustomer} className="formInicio" toolbar={
                        <Toolbar style={{ justifyContent: "space-between" , backgroundColor: 'white'}}>
                            <Button label ="ra.action.cancel"  onClick={e => setOpenRegCustomer(false)} style={{fontWeight:'700',fontSize:'0.85rem'}}/>
                            <SaveButton label ="Confirmar" icon={<CheckIcon/>}/> 
                        </Toolbar>
                    } style={{minWidth:'40vh'}}>
                        <TextInput style={{ width: "100%"}} source="Email" label="traducciones.campos.email" defaultValue={user} validate={required()} />
                    </SimpleForm>
                </DialogContent>
            </Dialog> 
        </ThemeProvider>
    );
};